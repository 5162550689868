import React, { useEffect, useState } from "react";
import {
  NumberFormat,
  errorMsg,
  handleScrollError,
  images,
} from "../../actions/customFn";
import { FormHelperText, TextField } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/fontawesome-free-solid";
import validator from "validator";
import Radio from "@mui/material/Radio";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { asyncAddDonate } from "../../actions/donatepageAction";
import StripeCheckoutForm from "../Checkout/StripeCheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { getCurrencyList } from "../../actions/addCampaign";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const DonatenowPopup = (props) => {
  const dataId = props.Donate_data;
  const campId = useParams();

  console.log("dataId", dataId);
  console.log("campId", campId);

  const [getCurrency, setGetCurrency] = useState("");
  const { settingData } = useSelector((state) => state.userAuth);
  const PUBLISHABLE_KEY = settingData.stripe_publish_key;
  const stripePromise = loadStripe(PUBLISHABLE_KEY);

  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    amount: Math.round(1),
    payment_method: "stripe",
    comment: "",
    currency: "",
  });

  const [checkterm, setCheckTerm] = useState(false);
  const [formErrors, setFormError] = useState({});
  const errors = {};

  const handleCheckTerm = (e) => {
    if (e.target.checked == true) {
      setCheckTerm(true);
    } else {
      setCheckTerm(false);
    }
  };

  useEffect(() => {
    getCurrencyList(setGetCurrency);
  }, []);

  const handleChange = (e) => {
    if (e.target != undefined) {
      const { name, value } = e.target;
      if (name == "amount") {
        if (value.length > 0) {
          setFormData((formData) => ({
            ...formData,
            [name]: Math.round(value),
          }));
        } else {
          setFormData((formData) => ({
            ...formData,
            [name]: Math.round(1),
          }));
        }
      } else {
        setFormData((formData) => ({ ...formData, [name]: value }));
      }
    }

    if (e.target.value == "RS") {
      errorMsg("Rupees are not working");
      setFormData((formData) => ({ ...formData, ["currency"]: "" }));
    }
  };

  const validateFormData = async () => {
    try {
      if (formData.firstname === "") {
        errors.firstname = "Enter First name properly ";
      }
      if (formData.lastname === "") {
        errors.lastname = "Enter Last name properly";
      }
      if (formData.email === "") {
        errors.email = "Enter email id";
      } else if (!validator.isEmail(formData.email)) {
        errors.email = "Enter valid email id";
      }
      if (formData.comment === "") {
        errors.comment = "Enter comment";
      }

      if (formData.accountholder === "") {
        errors.accountholder = "Enter Accountholder name properly";
      }

      if (formData.expirydate === "") {
        errors.expirydate = "Enter Expiry Date";
      }
      if (formData.cvv === "") {
        errors.cvv = "Enter cvv";
      }
      if (formData.currency === "") {
        errors.amount = "Please select currency";
      }
      if (formData.amount === "") {
        errors.amount = "Enter Amount";
      }
      if (checkterm == false) {
        errors.checkterm = "Read and Check term and conditions";
      }

      setFormError(errors);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange2 = (e) => {
    if (e.target != undefined) {
      const { name, value } = e.target;
      if (value == "paypal" || value == "creditcard") {
        validateFormData();
        if (Object.keys(errors).length === 0) {
          setFormData((formData) => ({ ...formData, [name]: value }));
        } else {
          handleScrollError(errors);
        }
      } else {
        setFormData((formData) => ({ ...formData, [name]: value }));
      }
    }
  };

  const handleAddDonate = async (
    e,
    { stripe, elements, CardCvcElement, CardExpiryElement, CardNumberElement }
  ) => {
    try {
      e.preventDefault();
      validateFormData();
      if (Object.keys(errors).length === 0) {
        const { token } = await stripe.createToken(
          elements.getElement(
            CardCvcElement,
            CardExpiryElement,
            CardNumberElement
          )
        );
        if (token) {
          asyncAddDonate(
            formData,
            // dataId == "" || dataId == undefined ? campId.id : dataId,
            dataId,
            token.id,
            props.setShow
          );
        }
      } else {
        handleScrollError(errors);
      }
    } catch (error) {
      // errorMsg(error.response.data.message);
    }
  };

  const initialOptions = {
    "client-id": settingData.paypal_client_id,
    intent: "capture",
  };

  return (
    <>
      <Modal
        centered
        show={props.show}
        size="lg"
        className="main_popup_inner donate_popup_main donate-form-modal"
        onHide={props.close}
      >
        <FontAwesomeIcon onClick={props.close} icon={faTimes} />

        <Modal.Header>
          <Modal.Title className="popup_title_name">Donate Form</Modal.Title>
        </Modal.Header>
        <h1>Personal Info</h1>

        <div className="row main_popup_inner1 new-all-inputs">
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-3">
            <FormHelperText sx={{ color: "#fff", marginBottom: "5px" }}>
              First Name
            </FormHelperText>
            <TextField
              sx={{
                width: "100%",
              }}
              error={formErrors.firstname}
              name="firstname"
              className="input_username new-edit-main-all"
              id="outlined-basic"
              placeholder="Enter your first name"
              onChange={handleChange}
              helperText={formErrors.firstname}
            />
          </div>

          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12  mb-3">
            <FormHelperText sx={{ color: "#fff", marginBottom: "5px" }}>
              Last Name
            </FormHelperText>
            <TextField
              sx={{
                width: "100%",
              }}
              error={formErrors.lastname}
              name="lastname"
              className="input_username new-edit-main-all"
              id="outlined-basic"
              placeholder="Enter your last name"
              onChange={handleChange}
              helperText={formErrors.lastname}
            />
          </div>
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-3">
            <FormHelperText sx={{ color: "#fff", marginBottom: "5px" }}>
              Email Address
            </FormHelperText>
            <TextField
              sx={{
                width: "100%",
              }}
              error={formErrors.email}
              name="email"
              className="input_username new-edit-main-all"
              id="outlined-basic"
              placeholder="Enter your email address"
              onChange={handleChange}
              helperText={formErrors.email}
            />
          </div>
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-3">
            <FormHelperText sx={{ color: "#fff", marginBottom: "5px" }}>
              comment
            </FormHelperText>
            <TextField
              sx={{
                width: "100%",
              }}
              error={formErrors.comment}
              name="comment"
              className="input_username new-edit-main-all"
              id="outlined-basic"
              placeholder="comment"
              onChange={handleChange}
              helperText={formErrors.comment}
            />
          </div>
        </div>

        <div className="add_amout_main new_amount_pay">
          <h3>Add Amount</h3>
          <div className="row ">
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="prd_Sort_by  add_amout_main_btn  new-all-inputs">
                <div className="sort_select new-selecct-donate">
                  <select>
                    {getCurrency != undefined && getCurrency.length > 0 ? (
                      getCurrency.map((value, index) => {
                        return (
                          <option value={value.currency_code} key={index}>
                            {value.currency_symbol}
                          </option>
                        );
                      })
                    ) : (
                      <option>Currency Not Found</option>
                    )}
                  </select>
                  {/* <Select
                    className="input_username new-edit-main-all"
                    id="demo-simple-select-helper-label"
                    name="currency"
                    onChange={handleChange}
                    error={formErrors.currency}
                    value={formData.currency}
                    helperText={formErrors.currency}
                  >
                    <MenuItem value="">Please Select Currency</MenuItem>
                    {getCurrency != undefined && getCurrency.length > 0 ? (
                      getCurrency.map((value, index) => {
                        return (
                          <MenuItem value={value.currency_code} key={index}>
                            {value.currency_symbol}
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem>Currency Not Found</MenuItem>
                    )}
                  </Select> */}
                </div>

                <input
                  type="number"
                  error={formErrors.amount}
                  name="amount"
                  className="input_username new-edit-main-all numberinput_arrow"
                  placeholder="amount"
                  onChange={handleChange}
                  helperText={formErrors.amount}
                />
              </div>
              {formErrors.amount && (
                <div className="form_error_new">{formErrors.amount}</div>
              )}
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 amout_main_btn_total">
              <div className="amout_main_btn_total">
                <h1>Total : </h1>
                <h2>{NumberFormat(formData.amount)}</h2>
              </div>
            </div>
          </div>
        </div>

        <div className="donate_payment_Metod mt-3">
          <h1>Select Payment Metod</h1>
          <div className="payment_method_btn">
            <button
              className="credit_card_payment_btn"
              htmlFor="payment_method"
            // onClick={() => {
            //   validateFormData();
            //   if (Object.keys(errors).length === 0) {
            //     setFormData({ ...formData , payment_method: "paypal" });
            //   }
            // }}
            >
              <Radio
                checked={formData.payment_method === "stripe"}
                onChange={(e) => handleChange2(e)}
                value="stripe"
                className="redio_payment_btn"
                name="payment_method"
                inputProps={{ "aria-label": "A" }}
              />
              Credit Card
            </button>
            <button
              className="paypal_payment_btn"
              htmlFor="payment_method"
              onClick={() => {
                validateFormData();
                if (Object.keys(errors).length === 0) {
                  setFormData({ ...formData, payment_method: "paypal" });
                }
              }}
            >
              <Radio
                checked={formData.payment_method === "paypal"}
                value="paypal"
                onChange={handleChange2}
                name="payment_method"
                inputProps={{ "aria-label": "B" }}
              />
              <img src={"/images/paypal-img.png"} alt="" />
            </button>
          </div>
        </div>
        {formData.payment_method == "stripe" ? (
          <div className="main_popup_inner1 new-all-inputs">
            <Elements
              stripe={stripePromise}
              options={{
                mode: "payment",
                amount: formData.amount,
                currency: formData.currency
                  ? formData.currency.toLowerCase()
                  : "usd",
              }}
            >
              <StripeCheckoutForm
                button_text={"Donate"}
                handleCheckOut={handleAddDonate}
                formErrors={formErrors}
                handleCheckTerm={handleCheckTerm}
              />
            </Elements>
          </div>
        ) : (
          <>
            <hr className="popup_link_btn_hr" />
            <PayPalScriptProvider
              options={{
                ...initialOptions,
                currency: formData.currency
                  ? formData.currency.toUpperCase()
                  : "USD",
              }}
            >
              <PayPalButtons
                title={"Donate Now"}
                className="submit_promocode"
                style={{ layout: "horizontal" }}
                createOrder={(data, actions) => {
                  validateFormData();
                  if (Object.keys(errors).length === 0) {
                    return actions.order.create({
                      purchase_units: [
                        {
                          amount: {
                            value: formData.amount,
                          },
                        },
                      ],
                    });
                  } else {
                    handleScrollError(errors);
                  }
                }}
                onApprove={(data, actions) => {
                  return actions.order.capture().then((details) => {
                    if (details.status === "COMPLETED") {
                      asyncAddDonate(
                        { ...formData, paypal_transation: details },
                        dataId
                      );
                    } else {
                      errorMsg("payment was failed please try again");
                    }
                  });
                }}
                onError={(err) => {
                  // errorMsg("Something went wrong!");
                }}
              />
            </PayPalScriptProvider>
          </>
        )}
      </Modal>
    </>
  );
};

export default DonatenowPopup;
