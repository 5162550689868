import React, { useEffect, useRef, useState } from "react";
import axios from "../axios";
import { errorMsg } from "../actions/customFn";
import { reactIcons } from "../utils/icons";
import OwlCarousel from "react-owl-carousel";
import { Button } from "react-bootstrap";
import Loader from "../hooks/Loader";
import WaveSurfer from "wavesurfer.js";
import { asyncPostPlay } from "../actions/uploadBlogs";

const allMusic = {
  loop: false,
  margin: 0,
  nav: true,
  dots: false,
  autoplay: false,
  autoplaySpeed: 3000,
  // navText: ["<div dangerouslysetinnerhtml=" + __html + " ></div>"],
  responsive: {
    0: {
      items: 1,
    },

    400: {
      items: 2,
    },
    600: {
      items: 3,
    },
    1000: {
      items: 4,
    },
    1200: {
      items: 5,
    },
  },
};

const WeekSongs = ({ navigate }) => {
  const [weekSongs, setWeekSongs] = useState([]);
  const [currentSong, setCurrentSong] = useState();
  const [isLoader, setIsLoader] = useState(true);
  const waveformRef = useRef(null);
  const [countUpdate, setCountUpdate] = useState(false);
  const [wavesurfer, setWavesurfer] = useState(null);
  const [playing, setPlaying] = useState(false);

  const getWeekSongs = async () => {
    const user_id = localStorage.getItem("star_uId");
    const user_password = localStorage.getItem("star_upass");
    try {
      const params = {
        customer_id: user_id,
        password: user_password,
      };
      const response = await axios.post("/get-most-view-music", params);
      if (response.data.status) {
        setWeekSongs(response.data.data);
        setCurrentSong(response.data?.data[0] || false);
        setIsLoader(false);
      } else {
        errorMsg(response.data.message);
        setIsLoader(false);
      }
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response.status !== undefined) {
          if (error.response.status === 401) {
            setIsLoader(false);
            //   errorMsg("Somthing went wrong...");
          } else {
            console.log(`${error.message}-${error.response.status}`);
            setIsLoader(false);
          }
        }
      }
    }
  };
  useEffect(() => {
    getWeekSongs();
  }, []);

  useEffect(() => {
    if (waveformRef.current && currentSong) {
      const ws = WaveSurfer.create({
        container: waveformRef.current,
        backgroundColor: "#000000",
        cursorColor: "#333",
        progressColor: "#555",
        interact: false,
        // mediaControls:true
      });

      ws.load(currentSong.audio);

      setWavesurfer(ws);

      return () => ws.destroy();
    }
  }, [waveformRef, currentSong]);

  const handleTogglePlay = () => {
    if (wavesurfer) {
      if (playing) {
        wavesurfer.pause();
      } else {
        if (!countUpdate) {
          asyncPostPlay(currentSong.id);
          setCountUpdate(true);
        }
        wavesurfer.play();
      }
      setPlaying(!playing);
    }
  };

  return (
    <div className="padding-y">
      <div className="">
        <div className="many-courses_heading">
          <h5>SONG OF THE WEEK </h5>
          <button onClick={() => navigate("/all-posts/music")}>View All</button>
        </div>
        {currentSong ? (
          <div
            className="song-of-background"
            // ref={waveformRef}
          >
            <div
              ref={waveformRef}
              className="background-img-section"
              src={"/images/music-img.png"}
              alt="song bg"
            />
            <img
              className="black-gradient"
              src={"images/black-gradient.png"}
              alt="song bg"
            />

            <div className="music-section">
              <div className="music-left">
                <div className="music-card-detail">
                  <img
                    className="music-song-img"
                    src={currentSong.thumbnail || "/images/music-song.png"}
                    alt=""
                  />
                </div>
                <div className="song-name">
                  <p>By {currentSong.author_name || "Anonymous"}</p>
                  <h3>{currentSong.title || "Anonymous"}</h3>
                </div>
              </div>
              <div className="music-right">
                <button className="back-music">
                  {/* <img src={"/images/play-music-btn.png"} alt="" /> */}
                </button>
                <button className="play-music" onClick={handleTogglePlay}>
                  <img
                    src={
                      playing
                        ? "/images/pause.png"
                        : "/images/play-music-btn.png"
                    }
                    alt=""
                  />
                </button>
                <button className="next-music">
                  {/* <img src={"images/next-music.png"} alt="" /> */}
                </button>
              </div>
            </div>
            <div className="song-timer">
              {/* <p>01:34</p>
              <div className="time-range">
                <div className="time-left"></div>
            </div> */}
              {/* <p>03:00</p> */}
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </div>
      {isLoader === true ? (
        <Loader />
      ) : (
        <div className="music-slider">
          <OwlCarousel
            // key={`carousel_tranding_courses_${Date.now()}`}
            {...allMusic}
            className="owl-theme"
            margin={32}
          >
            {weekSongs.map((value, index) => (
              <div key={index} className="music-slider-card trending-post">
                <div className="d-flex justify-content-between align-items-center gap-2 mb-2 tp-heading">
                  <div className="d-flex align-items-center gap-10">
                    <img
                      src={value.author_image}
                      className="rounded-circle border border-white"
                      width={40}
                      height={40}
                      alt=""
                    />
                    <h6 className="profile-name-heading flex-1 text-16 capitalize font-poppins font-medium truncate popins">
                      {value.author_name}
                    </h6>
                  </div>
                  <span className="text-14 text-gray-300 popins font-500">
                    1d
                  </span>
                </div>
                <div className="w-100">
                  <div className="">
                    <p>{value.title || "Anonymous"}</p>
                    <div className="audio-player-container">
                      <img
                        className="background-image"
                        src={value.thumbnail}
                        alt="play button"
                      />

                      <div
                        className="audio-controls"
                        // onClick={() => {
                        //   setCurrentSong(value);
                        //   setCountUpdate(false);
                        // }}
                        onClick={() => {
                          setCurrentSong(value);
                          setCountUpdate(false);
                          setPlaying(false);
                        }}
                      >
                        <button className="play-pause-btn">
                          <img src={"/images/play.png"} alt="pause button" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="d-flex align-items-center justify-content-between mt-2">
                  <div className="d-flex flex-1 align-items-center gap-10">
                    <div className="d-flex align-items-center gap-5">
                      <span className="text-gray-300 text-20">
                        {reactIcons.circleUp}
                      </span>
                      <span className="text-gray-300 font-poppins text-14 font-medium">
                        0
                      </span>
                    </div>
                    <div className="d-flex align-items-center gap-5">
                      <span className="text-gray-300 text-20">
                        {reactIcons.repeat}
                      </span>
                      <span className="text-gray-300 font-poppins text-14 font-medium">
                        0
                      </span>
                    </div>
                    <div className="d-flex align-items-center gap-5">
                      <span className="text-gray-300 text-20">
                        {reactIcons.message}
                      </span>
                      <span className="text-gray-300 font-poppins text-14 font-medium">
                        0
                      </span>
                    </div>
                  </div>
                  <Button variant="dark" className="tp-mint">
                    Mint 0
                  </Button>
                </div> */}
              </div>
            ))}
          </OwlCarousel>
        </div>
      )}
    </div>
  );
};

export default WeekSongs;
