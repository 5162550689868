import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { getAllPhotosList } from "../../actions/uploadPhoto";
import OwlCarousel from "react-owl-carousel";
import { questChest } from "../../utils/data";
const data = [
  { image: "/images/photo_pic1.png" },
  { image: "/images/photo_pic2.png" },
  { image: "/images/photo_pic3.png" },
  { image: "/images/photo_pic4.png" },
  { image: "/images/photo_pic5.png" },
  { image: "/images/photo_pic6.png" },

  { image: "/images/photo_pic1.png" },
  { image: "/images/photo_pic2.png" },
  { image: "/images/photo_pic3.png" },
  { image: "/images/photo_pic4.png" },
  { image: "/images/photo_pic5.png" },
  { image: "/images/photo_pic6.png" },
];

const QuestChest = () => {
  const dispatch = useDispatch();
  const { all_photos } = useSelector((state) => state.photosData);

  return (
    <>
      <div className="quest-page">
        <div className="quest-top">
          <h4>
            Quest Chest <span>( {all_photos.length} )</span>
          </h4>
          <button className="quest-btn" type="button">
            Go Live
          </button>
        </div>

        <div className="quest-data">
          <div className="quest-new many-courses">
            <OwlCarousel
              key={`carousel_top_post_${Date.now()}`}
              className="owl-theme"
              nav
              loop
              margin={10}
              items={1}
              dots={false}
            >
              {questChest.map((value, index) => {
                return (
                  <div className="certificate-post" key={index}>
                    <img src={value.img} className="certificate-img" />
                  </div>
                );
              })}
            </OwlCarousel>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuestChest;
