import React, { useCallback, useState } from "react";
import { FooterBig, FooterMobile, Header } from "../components";
import { NavLink, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import { errorMsg, icons, successMsg } from "../actions/customFn";
import { useEffect } from "react";
import Loader from "../hooks/Loader";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import OwlCarousel from "react-owl-carousel";
import nextId from "react-id-generator";
import { getAuthData } from "../utils/apiHandlers";
import { polygon, polygonAmoy } from "viem/chains";
import { contracts, network, zeroAddress } from "../utils/env";
import { getContract, parseEther, publicActions } from "viem";
import { AssetContractShared, ERC20, Exchange } from "../contracts";
import { useAccount, useClient, useWalletClient } from "wagmi";
import moment from "moment";
import ViewNftHolders from "../components/Modal/ViewNftHolders";
import CancelSell from "../components/CancelSell";
import BuyNow from "../components/BuyNow";
import MintBtn from "../components/MintBtn";
import { asyncPostPlay } from "../actions/uploadBlogs";
import ReactPlayer from "react-player";
import CustomAudioPlayer from "../components/AudioPlayerCard";
const NftProductDetails = () => {
  const { id } = useParams();
  const [nftDetails, setNftDetails] = useState(null);
  const { address } = useAccount();
  const [activity, setActivity] = useState([]);
  const [relatedNft, setRelatedNft] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [searchActivity, setSearchActivity] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShowHolder = () => setShow(true);
  useEffect(() => {
    if (!nftDetails) {
      setIsLoader(true);
    } else {
      setIsLoader(false);
    }
  }, [nftDetails]);
  const experience = {
    loop: true,
    margin: 20,
    nav: true,
    dots: false,
    autoplay: false,
    autoplaySpeed: 3000,
    responsive: {
      0: {
        items: 1,
      },

      400: {
        items: 2,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 4,
      },
      1200: {
        items: 5,
      },
    },
  };

  const getSingleNft = useCallback(async () => {
    try {
      const response = await getAuthData(`/tokens/${id}`);
      if (response?.status === 201 || response?.status === 200) {
        setNftDetails(response.data);
      }
    } catch (e) {
      console.error(e);
    }
  }, [id]);

  const getNftActivity = useCallback(async () => {
    try {
      const response = await getAuthData(
        `/tokens/${id}/activities?search=${searchActivity}`
      );
      if (response?.status === 201 || response?.status === 200) {
        setActivity(response.data.data);
      }
    } catch (e) {
      console.error(e);
    }
  }, [id, searchActivity]);

  const getNftRelated = useCallback(async () => {
    try {
      const response = await getAuthData(`/tokens?relatedTo=${id}`);
      if (response?.status === 201 || response?.status === 200) {
        setRelatedNft(response.data.data);
      }
    } catch (e) {
      console.error(e);
    }
  }, [id]);

  useEffect(() => {
    getSingleNft();
    getNftActivity();
    getNftRelated();
  }, [getSingleNft, getNftActivity, getNftRelated]);

  const getBuyerReplacementPattern = () => {
    return (
      window.web3.utils.padLeft("0x0", 8) +
      window.web3.utils.toTwosComplement("-1").slice(2) +
      window.web3.utils.toTwosComplement("0x0").slice(2) +
      window.web3.utils.toTwosComplement("0x0").slice(2) +
      window.web3.utils.toTwosComplement("0x0").slice(2) +
      window.web3.utils.toTwosComplement("0x0").slice(2) +
      window.web3.utils.toTwosComplement("0x0").slice(2) +
      window.web3.utils.toTwosComplement("0x0").slice(2) +
      window.web3.utils.toTwosComplement("0x0").slice(2)
    );
  };
  let { data: _walletClient } = useWalletClient({
    chainId: network === "mainnet" ? polygon.id : polygonAmoy.id,
    account: address,
  });
  const walletClient = _walletClient
    ? _walletClient.extend(publicActions)
    : null;
  const client = useClient({
    chainId: process.env.REACT_APP_NETWORK === "mainnet" ? polygon.id : 80002,
  });

  const matchOrder = async (sellerOrder, buyerOrder) => {
    const { request } = await walletClient.simulateContract({
      address: contracts.Exchange,
      abi: Exchange,
      functionName: "atomicMatch",
      value:
        sellerOrder.paymentToken === zeroAddress ? buyerOrder.basePrice : 0,
      args: [
        [
          buyerOrder.exchange,
          buyerOrder.maker,
          buyerOrder.taker,
          buyerOrder.feeRecipient,
          buyerOrder.target,
          buyerOrder.staticTarget,
          buyerOrder.paymentToken,
          sellerOrder.exchange,
          sellerOrder.maker,
          sellerOrder.taker,
          sellerOrder.feeRecipient,
          sellerOrder.target,
          sellerOrder.staticTarget,
          sellerOrder.paymentToken,
        ],
        [
          buyerOrder.makerRelayerFee,
          buyerOrder.takerRelayerFee,
          buyerOrder.makerProtocolFee,
          buyerOrder.takerProtocolFee,
          buyerOrder.basePrice,
          buyerOrder.extra,
          buyerOrder.maximumFill,
          buyerOrder.listingTime,
          buyerOrder.expirationTime,
          buyerOrder.salt,
          sellerOrder.makerRelayerFee,
          sellerOrder.takerRelayerFee,
          sellerOrder.makerProtocolFee,
          sellerOrder.takerProtocolFee,
          parseEther(sellerOrder.basePrice),
          sellerOrder.extra,
          sellerOrder.maximumFill,
          sellerOrder.listingTime,
          sellerOrder.expirationTime,
          sellerOrder.salt,
        ],
        [
          buyerOrder.feeMethod,
          buyerOrder.side,
          buyerOrder.saleKind,
          buyerOrder.howToCall,
          sellerOrder.feeMethod,
          sellerOrder.side,
          sellerOrder.saleKind,
          sellerOrder.howToCall,
        ],
        buyerOrder.calldata,
        sellerOrder.calldata,
        buyerOrder.replacementPattern,
        sellerOrder.replacementPattern,
        buyerOrder.staticExtradata,
        sellerOrder.staticExtraData,
        [sellerOrder.v, sellerOrder.v],
        [
          sellerOrder.r,
          sellerOrder.s,
          sellerOrder.r,
          sellerOrder.s,
          "0x0000000000000000000000000000000000000000000000000000000000000000",
        ],
      ],
    });

    await walletClient.switchChain({
      id: network === "mainnet" ? polygon.id : polygonAmoy.id,
    });
    const transaction = await walletClient.waitForTransactionReceipt({
      hash: await walletClient.writeContract(request),
    });
    console.log(transaction, "transaction");
    return transaction;
  };
  const getCalldata = (address, abi, method, ...params) => {
    const contract = new window.web3.eth.Contract(abi, address);
    const calldata = contract.methods[method](...params).encodeABI();
    return calldata;
  };
  const handleBuy = async (buyOrder) => {
    setIsLoader(true);
    try {
      if (!address) {
        setIsLoader(false);
        errorMsg("Please connect your wallet to make blockchain transaction");
        return;
      }
      const salt = window.web3.utils.hexToNumberString(
        window.web3.utils.randomHex(32)
      );

      const order = {
        exchange: buyOrder?.orders[0]?.exchange, // min_order.exchange
        maker: address,
        taker: buyOrder?.orders[0]?.maker, // Order taker address //
        makerRelayerFee: buyOrder?.orders[0]?.makerRelayerFee, // Maker relayer fee of the order, unused for taker order
        takerRelayerFee: buyOrder?.orders[0]?.takerRelayerFee, // Taker relayer fee of the order, or maximum taker fee for a taker order
        makerProtocolFee: buyOrder?.orders[0]?.makerProtocolFee, // Maker protocol fee of the order, unused for taker order
        takerProtocolFee: buyOrder?.orders[0]?.takerProtocolFee, // Taker protocol fee of the order, or maximum taker fee for a taker order
        feeRecipient: zeroAddress, // Order fee recipient or zero address for taker order
        feeMethod: buyOrder?.orders[0]?.feeMethod, // Fee method (protocol token or split fee)
        side: 0, // Side (buy/sell) // 0 bydefault
        saleKind: buyOrder?.orders[0]?.saleKind, // Kind of sale
        target: buyOrder?.orders[0]?.target, // Target
        howToCall: buyOrder?.orders[0]?.howToCall, // HowToCall
        calldata: getCalldata(
          contracts.AssetSharedContract,
          AssetContractShared,
          "safeTransferFrom",
          zeroAddress,
          address, // connectted wallet address
          buyOrder.onChainId, // need token onChainId
          1,
          buyOrder.uri
            ? window.ethers.toQuantity(window.ethers.toUtf8Bytes(buyOrder.uri))
            : []
        ), // Calldata
        maximumFill: 1,
        replacementPattern: getBuyerReplacementPattern(), // Calldata replacement pattern, or an empty byte array for no replacement
        staticTarget: buyOrder?.orders[0]?.staticTarget, // Static call target, zero-address for no static call
        staticExtradata: buyOrder?.orders[0]?.staticExtraData, // Static call extra data
        paymentToken: buyOrder?.orders[0]?.paymentToken, // Token used to pay for the order, or the zero-address as a sentinel value for Ether
        basePrice: parseEther(buyOrder?.orders[0]?.basePrice), // Base price of the order (in paymentTokens)
        extra: buyOrder?.orders[0]?.extra, // m bid increment for English auctions, starting/ending price difference
        listingTime: Math.round(Date.now() / 1000) - 19800, // Listing timestamp
        expirationTime: 0, // Expiration timestamp - 0 for no expiry
        salt: salt, // Order salt, used to prevent duplicate hashes
      };
      console.log(order, "order");
      const ethers = window.ethers;

      const { v, r, s } = ethers.Signature.from(buyOrder?.orders[0]?.signature);
      console.log(v, r, s, "splidata");

      if (order.paymentToken !== zeroAddress) {
        // const paymentAddress = order.paymentToken;
        const paymentToken = getContract({
          address: order.paymentToken,
          abi: ERC20,
          client,
        });
        const allowance = await paymentToken.read.allowance([
          address,
          order.exchange,
        ]);
        console.log(allowance, "allowance");
        // eslint-disable-next-line no-undef
        console.log(BigInt(order?.basePrice), "bigInt");
        // eslint-disable-next-line no-undef
        if (allowance < BigInt(order?.basePrice)) {
          const { request } = await walletClient.simulateContract({
            address: paymentToken.address,
            abi: paymentToken.abi,
            functionName: "approve",
            args: [order.exchange, order?.basePrice],
          });
          await walletClient.switchChain({
            id: network === "mainnet" ? polygon.id : polygonAmoy.id,
          });
          const transaction = await walletClient.waitForTransactionReceipt({
            hash: await walletClient.writeContract(request),
          });
          console.debug("Approval tx:", transaction);
        }
      }
      await matchOrder({ ...buyOrder?.orders[0], v, r, s }, order);
      successMsg("Sucessfully buy");
      setIsLoader(false);
    } catch (error) {
      console.log(error);
      setIsLoader(false);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0.2 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 1 }}
      transition={{ duration: 2 }}
    >
      <div className="all_page_bg product-details-page">
        <div className="container-fluid">
          <Header />

          <div className="page-all-data">
            {isLoader === true ? (
              <Loader />
            ) : (
              <div className="nft_product">
                <div className="bg-white p-3 rounded">
                  <div class="row">
                    <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12">
                      <div className="image_parent">
                        <div className="ip_top">
                          <div className="ip_top_left">
                            <img src={"/icons/matic.png"} alt="" />
                          </div>
                          {/* <div className="ip_top_right">
                            <img src={"/icons/share.png"} alt="" />
                            <span>0</span>
                            <img src={"/icons/favorite.png"} alt="" />
                          </div> */}
                        </div>
                        <div className="ip_bottom" style={{width:'100%'}}>
                        {nftDetails?.metadata?.type === 2 ? (
                                <ReactPlayer
                                  url={`https://gateway.pinata.cloud/ipfs/${nftDetails?.metadata?.image?.replace(
                                    "ipfs://",
                                    ""
                                  )}`}
                                  onEnded={()=>asyncPostPlay(nftDetails.id)}
                                  controls
                                  
                                  height="auto"
                                />
                              ) : nftDetails?.metadata?.type === 1 ? (
                                <CustomAudioPlayer
                                  id={nftDetails?.id}
                                  src={`https://gateway.pinata.cloud/ipfs/${nftDetails?.metadata?.image?.replace(
                                    "ipfs://",
                                    ""
                                  )}`}
                                  backgroundImage={'/images/course-bg1.png'}
                                />
                              ) : (
                                <img src={`https://gateway.pinata.cloud/ipfs/${nftDetails?.metadata?.image?.replace(
                                  "ipfs://",
                                  ""
                                )}`} alt="" />
                              )}
                        </div>
                      </div>
                      <Accordion
                        defaultExpanded={true}
                        TransitionProps={{ unmountOnExit: true }}
                        className="custom-accordion"
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="activity-content"
                          id="activity-header"
                          className="accordion-header"
                        >
                          <img src={"/icons/sort.png"} alt="" />
                          <p>Description</p>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="descriptiom-sec">
                            <div className="dc-list">
                              <h3>
                                <span>By</span>
                                {`${nftDetails?.creator.slice(
                                  0,
                                  3
                                )}...${nftDetails?.creator.slice(-4)}`}
                                <img src={"/icons/check.png"} alt="" />
                              </h3>
                              <p>
                                Reveal{" "}
                                {moment(nftDetails?.createdAt).format("LL")}
                              </p>
                            </div>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                    <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12">
                      <div className="right_product">
                        <p className="nft-name">{nftDetails?.metadata?.name}</p>
                        <h1 className="nft-desc">
                          {nftDetails?.metadata?.description}
                        </h1>
                        <p className="nft-owner mb-2">
                          Owned by{" "}
                          <button type="button" onClick={handleShowHolder}>
                            View
                          </button>
                        </p>
                        {/* <div className="nft-status">
                          <div>
                            <img src={"/icons/eye.png"} alt="" />
                            <span>21 views</span>
                          </div>
                          <div>
                            <img src={"/icons/pfp.png"} alt="" />
                            <span>PFPs</span>
                          </div>
                        </div> */}
                        <div className="buy_box">
                          <div className="bb-head">
                            <img src={"/icons/schedule.png"} alt="" />
                            <span>
                              {nftDetails?.orders[0]?.expirationTime === "0"
                                ? "Sale ends soon. Don`t miss out!"
                                : `Sale ends ${moment(
                                  nftDetails?.orders[0]?.expirationTime
                                ).format("MMMM D, YYYY, h:mm a")}`}
                            </span>
                          </div>
                          <div className="p-2">
                            <span className="current-price">Current price</span>
                            <div className="nft-price">
                              <h1>
                                {nftDetails?.orders[0]?.basePrice}{" "}
                                {nftDetails?.orders[0]?.paymentToken ===
                                  zeroAddress
                                  ? "MATIC"
                                  : "USDT"}
                              </h1>
                              {/* <span>$557.19</span> */}
                            </div>
                            <div className="nft-btn-group">
                              <button
                                className="buy-btn"
                                onClick={() => handleBuy(nftDetails)}
                              >
                                {isLoader ? "Wait.." : "Buy now"}
                                <span className="bb-icon">
                                  <img src={"/icons/cart.png"} alt="" />
                                </span>
                              </button>
                              {/* <button className="offer-btn">
                                <img src={"/icons/sell.png"]} alt="" />
                                Make offer
                              </button> */}
                            </div>
                            <div className="support_text">
                              <img src={"/icons/heart.png"} alt="" />
                              <h4>
                                Supports creator
                                <span>
                                  This listing is paying the collection creator
                                  their suggested creator earnings.
                                </span>
                              </h4>
                            </div>
                          </div>
                        </div>
                        <Accordion
                          defaultExpanded={true}
                          TransitionProps={{ unmountOnExit: true }}
                          className="custom-accordion"
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="purchase-history-content"
                            id="purchase-history-header"
                            className="accordion-header"
                          >
                            <img src={"/icons/sell.png"} alt="" />
                            <p>Listings</p>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div class="table-responsive">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th scope="col">Price</th>
                                    <th scope="col">Chain</th>
                                    <th scope="col">Created At</th>
                                    <th scope="col">Expiration</th>
                                    <th scope="col">Action</th>
                                  </tr>
                                </thead>
                                <tbody class="table-group-divider">
                                  {nftDetails?.orders?.length > 0 ? (
                                    nftDetails?.orders?.map((item, index) => (
                                      <tr key={index}>
                                        <td style={{ fontWeight: 600 }}>
                                          {item.basePrice}{" "}
                                          {item.paymentToken === zeroAddress
                                            ? "MATIC"
                                            : "USDT"}
                                        </td>
                                        <td>
                                          {item.chain.charAt(0).toUpperCase() +
                                            item.chain.slice(1)}
                                        </td>
                                        <td>
                                          {moment(item.createdAt).format("L")}
                                        </td>
                                        <td>
                                          {item.expirationTime === "0"
                                            ? "Sale ends soon."
                                            : `${moment
                                              .unix(item.expirationTime)
                                              .format("YYYY-MM-DD")}`}
                                        </td>
                                        <td>
                                          {item.expirationTime === "0" &&
                                            item.maker !==
                                            address?.toLocaleLowerCase() ? (
                                            <MintBtn
                                              data={item}
                                              uri={nftDetails?.uri}
                                              onChainId={nftDetails?.onChainId}
                                              refresh={getSingleNft}
                                              disabled={
                                                item.maker ===
                                                address?.toLocaleLowerCase() &&
                                                true
                                              }
                                            />
                                          ) : item.expirationTime !== "0" &&
                                            item.maker ===
                                            address?.toLocaleLowerCase() ? (
                                            <CancelSell
                                              data={item}
                                              uri={nftDetails?.uri}
                                              onChainId={nftDetails?.onChainId}
                                              refresh={getSingleNft}
                                            />
                                          ) : (
                                            <BuyNow
                                              data={item}
                                              uri={nftDetails?.uri}
                                              onChainId={nftDetails?.onChainId}
                                              disabled={
                                                item.maker ===
                                                address?.toLocaleLowerCase() &&
                                                true
                                              }
                                            />
                                          )}
                                        </td>
                                      </tr>
                                    ))
                                  ) : (
                                    <tr>
                                      <td colSpan={5} className="text-center">
                                        No Data
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </div>
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                      <Accordion
                        defaultExpanded={true}
                        TransitionProps={{ unmountOnExit: true }}
                        className="custom-accordion"
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="purchase-history-content"
                          id="purchase-history-header"
                          className="accordion-header"
                        >
                          <img src={"/icons/swap.png"} alt="" />
                          <p>Item Activity</p>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="text-right m-2">
                            <input
                              type="text"
                              className="form-control w-auto ml-auto"
                              placeholder="Search.."
                              value={searchActivity}
                              onChange={(e) =>
                                setSearchActivity(e.target.value)
                              }
                            />
                          </div>
                          <div class="table-responsive">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th scope="col">Event</th>
                                  <th scope="col">Price</th>
                                  <th scope="col">From</th>
                                  <th scope="col">To</th>
                                  <th scope="col">Quantity</th>
                                  <th scope="col">Created At</th>
                                </tr>
                              </thead>
                              <tbody class="table-group-divider">
                                {activity?.length > 0 ? (
                                  activity.map((item, index) => (
                                    <tr key={index}>
                                      <td style={{ fontWeight: 600 }}>
                                        {item.event}
                                      </td>
                                      <td>
                                        {item.price === null ? "0" : item.price}
                                      </td>
                                      <td>{`${item?.from?.slice(
                                        0,
                                        2
                                      )}...${item?.from?.slice(-4)}`}</td>
                                      <td>
                                        {item?.to === null
                                          ? "N/A"
                                          : `${item?.to?.slice(
                                            0,
                                            2
                                          )}...${item?.to?.slice(-4)}`}
                                      </td>
                                      <td>{item.quantity}</td>
                                      <td>
                                        {moment(item.createdAt).format("L")}
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td colSpan={6} className="text-center">
                                      No data
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                      {/* <Accordion
                        defaultExpanded={true}
                        TransitionProps={{ unmountOnExit: true }}
                        className="custom-accordion"
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="activity-content"
                          id="activity-header"
                          className="accordion-header"
                        >
                          <img src={"/icons/swap.png"} alt="" />
                          <p>Item Activity</p>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Select
                            className="mui-custom-select"
                            name="country"
                            value={"Filter"}
                          >
                            <MenuItem className="mui-custom-label">
                              Hello
                            </MenuItem>
                          </Select>
                          <div class="table-responsive">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th scope="col">Event</th>
                                  <th scope="col">Price</th>
                                  <th scope="col">From</th>
                                  <th scope="col">To</th>
                                  <th scope="col">Date</th>
                                </tr>
                              </thead>
                              <tbody class="table-group-divider">
                                {Array(10)
                                  .fill(null)
                                  .map((index) => (
                                    <tr key={index}>
                                      <td>Transfer</td>
                                      <td></td>
                                      <td>Mustafa1515</td>
                                      <td>B603DE</td>
                                      <td>6d ago</td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        </AccordionDetails>
                      </Accordion> */}
                    </div>
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                      <Accordion
                        defaultExpanded={true}
                        TransitionProps={{ unmountOnExit: true }}
                        className="custom-accordion"
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="activity-content"
                          id="activity-header"
                          className="accordion-header"
                        >
                          <img src={"/icons/collection.png"} alt="" />
                          <p>Related NFT</p>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="more-collection">
                            {relatedNft.length > 0 ? (
                              <OwlCarousel
                                // key={`carousel_tranding_courses_${Date.now()}`}
                                key={nextId()}
                                {...experience}
                                className="owl-theme"
                                nav
                                loop
                                margin={10}
                              >
                                {relatedNft?.map((value, index) => {
                                  return (
                                    <NavLink
                                      to={`/nft-details/${value.id}`}
                                      key={index}
                                      className="mc-card"
                                    >
                                      <div className="mc-img-sec">
                                        <div className="overlay-bg"></div>
                                        {value.metadata.type === 2 ? (
                                <ReactPlayer
                                  url={`https://gateway.pinata.cloud/ipfs/${value?.metadata?.image?.replace(
                                    "ipfs://",
                                    ""
                                  )}`}
                                  onEnded={()=>asyncPostPlay(value.id)}
                                  controls
                                  width="100%"
                                  height="auto"
                                />
                              ) : value.metadata.type === 1 ? (
                                <CustomAudioPlayer
                                  id={value.id}
                                  src={`https://gateway.pinata.cloud/ipfs/${value?.metadata?.image?.replace(
                                    "ipfs://",
                                    ""
                                  )}`}
                                  backgroundImage={'/images/music-img.png'}
                                />
                              ) : (
                                <img src={`https://gateway.pinata.cloud/ipfs/${value?.metadata?.image?.replace(
                                  "ipfs://",
                                  ""
                                )}`} alt="" />
                              )}
                                      </div>
                                      <div className="mc-text-sec">
                                        <h6 className="title">
                                          {value.metadata.name}
                                        </h6>
                                        <h5 className="price">
                                          {value?.orders[0]?.basePrice}
                                        </h5>
                                        <h4 className="last-sale">
                                          {value?.orders[0]?.expirationTime ===
                                            "0"
                                            ? "Sale ends soon."
                                            : `Last Sale ${moment(
                                              value?.orders[0]?.expirationTime
                                            ).format("LL")}`}
                                        </h4>
                                      </div>
                                    </NavLink>
                                  );
                                })}
                              </OwlCarousel>
                            ) : (
                              <p className="text-center">No Data Available</p>
                            )}
                          </div>
                        </AccordionDetails>
                        {relatedNft.length > 0 && (
                          <AccordionSummary className="accordion-footer">
                            <button className="view-collection-btn">
                              View collection
                            </button>
                          </AccordionSummary>
                        )}
                      </Accordion>
                    </div>
                  </div>
                </div>

                <FooterBig />
                <div className="mobile-footer-new">
                  <FooterMobile />
                </div>
              </div>
            )}

            <ViewNftHolders
              show={show}
              setShow={setShow}
              close={handleClose}
              data={nftDetails?.holders}
            />
          </div>
        </div>
      </div>
    </motion.div>
  );
};
export default NftProductDetails;
