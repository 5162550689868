import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faStarHalf } from "@fortawesome/fontawesome-free-solid";
import { Link, NavLink, useNavigate } from "react-router-dom";
import ReactStars from "react-rating-stars-component";
import InfiniteScroll from "react-infinite-scroll-component";

const CourseRelatedcourse = (props) => {
  const { courselist } = props;

  const reactRatings = {
    edit: false,
    activeColor: "#fc5301",
    isHalf: true,
    emptyIcon: <FontAwesomeIcon icon={faStar} />,
    halfIcon: <FontAwesomeIcon icon={faStarHalf} />,
    filledIcon: <FontAwesomeIcon icon={faStar} />,
  };

  const navigate = useNavigate();

  // Child component

  return (
    <div className="courserelated_course">
      {courselist.map((value, index) => {
        return (
          <>
            <div
              className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 courses_card"
              key={index}
            >
              <div className="inner_list_main">
                <div className="row">
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                    {value.image == "" || value.image == undefined ? (
                      <div
                        className="product_img "
                        style={{
                          backgroundImage: `url(${"/images/timepost8.png"})`,
                        }}
                      ></div>
                    ) : (
                      <div
                        className="product_img "
                        style={{
                          backgroundImage: `url(${value.image})`,
                        }}
                      ></div>
                    )}
                  </div>
                  <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-12 ">
                    <div className="wish_lsit_info">
                      <div className="wish_lsit_info_top_btn">
                        {value.category_title !== undefined && (
                          <NavLink to={`/courses-category/${value.category_id}`} >
                            <button>{value.category_title}</button>
                          </NavLink>
                        )}

                        <img src={"/images/heart1.png"} alt="" />
                      </div>
                      <h2>{value.title}</h2>
                      <h6
                        dangerouslySetInnerHTML={{
                          __html: value.description,
                        }}
                      ></h6>

                      <div className="prd_star_rating">
                        <ul>
                          <li className="star_point">
                            <ReactStars
                              {...reactRatings}
                              size={16}
                              value={Number(value.rating)}
                            />
                          </li>
                          <li className="star_point">{value.rating}</li>
                        </ul>
                      </div>

                      <div className="latestblogs_right_main_div">
                        <Link to={`/friend_user_profile/${value.author_id}`} state={{ val: 1 }}>
                          <div className="latestblogs_right_profile1">
                            <div className="right_profile_left_bg">
                              <img src={value.author_image} alt="" />
                            </div>
                            <span>{value.author_name}</span>
                          </div>
                        </Link>
                        <div className="coures_total_student">
                          <img src={"/images/coures_school_icon.png"} alt="" />
                          <div className="coures_total_student_text">
                            (105) <span> Students</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                    <div className="div_last_main">
                      <div className="product_price">
                        <div className="price_main_tex">
                          <p>
                            {" "}
                            <span>$</span>
                            {value.price}
                          </p>
                          <h3 className="per_person">13 Lectures</h3>
                        </div>
                      </div>

                      <div className="card_Buy_now new_btn_all">
                        <button
                          onClick={() => {
                            navigate(`/courses-detail/${value.id}`);
                          }}
                        >
                          Buy now
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div >
          </>
        );
      })}
    </div >
  );
};

export default CourseRelatedcourse;
