import React, { useRef, useState, useffect } from "react";
import { FooterBig, Header } from "../components";
// import { NavLink } from "react-bootstrap";
import {
  errorMsg,
  getArray,
  handleScrollError,
  icons,
  images,
} from "../actions/customFn";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormHelperText, TextField } from "@mui/material";
import "quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
// import Select from "@mui/material/Select";
// import MenuItem from "@mui/material/MenuItem";
import { faTrash } from "@fortawesome/fontawesome-free-solid";
// import { useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import { InputLabel, MenuItem, Select, FormControl } from "@mui/material";
import SmallLoader from "react-js-loader";
import {
  asyncAddProduct,
  getMyProductDetails,
  getMyProductSubCategory,
  getProductCategory,
  getShipingInputData,
  getTaxInputData,
} from "../actions/productAction";
// import { Input } from "@mui/material";
import { useEffect } from "react";
import Loader from "../hooks/Loader";
import { reactIcons } from "../utils/icons";

var modules = {
  toolbar: [
    [{ size: ["small", false, "large", "huge"] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
      { align: [] },
    ],
    [
      {
        color: [
          "#000000",
          "#e60000",
          "#ff9900",
          "#ffff00",
          "#008a00",
          "#0066cc",
          "#9933ff",
          "#ffffff",
          "#facccc",
          "#ffebcc",
          "#ffffcc",
          "#cce8cc",
          "#cce0f5",
          "#ebd6ff",
          "#bbbbbb",
          "#f06666",
          "#ffc266",
          "#ffff66",
          "#66b966",
          "#66a3e0",
          "#c285ff",
          "#888888",
          "#a10000",
          "#b26b00",
          "#b2b200",
          "#006100",
          "#0047b2",
          "#6b24b2",
          "#444444",
          "#5c0000",
          "#663d00",
          "#666600",
          "#003700",
          "#002966",
          "#3d1466",
          "custom-color",
        ],
      },
    ],
  ],
};

var formats = [
  "header",
  "height",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "color",
  "bullet",
  "indent",
  "link",
  "image",
  "align",
  "size",
];

const AddProductForm = () => {
  const inputRef = useRef(null);
  const [isLoader, setIsLoader] = useState(false);
  const navigate = useNavigate();
  const directpage = (path) => {
    navigate(`/${path}`);
  };

  // ---------------
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const [buttonloader, setButtonLoader] = useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  // ------------ add product api ------------------
  const [editorType, setEditorType] = useState("");
  const [formErrors, setFormError] = useState({});
  const errors = {};
  // ------------ add product api ------------------

  // -------------- show product details ----------------------
  const [myproductdetail, getMyProductDetail] = useState();
  const { id } = useParams();

  // -------------- show product details ----------------------

  // ------------ add new product -------------------
  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    let question = formData.question;
    let answer = formData.answer;

    question.splice(index, 1);
    answer.splice(index, 1);

    setFormData((prev) => ({ ...prev, question: question }));
    setFormData((prev) => ({ ...prev, answer: answer }));
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setFormData((prev) => ({
      ...prev,
      question: [...prev.question, ""],
      answer: [...prev.answer, ""],
    }));
  };

  const handleAddspecification = () => {
    setFormData((prev) => ({
      ...prev,
      specification: [...prev.specification, ""],
    }));
  };

  const handleRemovespecification = (index) => {
    let specification = formData.specification;
    specification.splice(index, 1);
    setFormData((prev) => ({ ...prev, specification: specification }));
  };

  // ------------ add product api ------------------
  const [formData, setFormData] = useState({
    productname: "",
    description: "",
    quantity: "",
    productsku: "",
    weight: "",
    lengths: "",
    breath: "",
    width: "",
    location: "",
    safetyinstruction: "",
    waranty: "",
    warantydescription: "",
    price: "",
    category: "",
    subcategory: "",
    image: [],
    question: [""],
    answer: [""],
    specification: [""],
    promo_code: "",
    promocode_value: "",
    promo_type: "",
    compare_price: "",
    delivery_time: "",
    shiping_id: "",
    tax_id: "",
  });

  const handleChange = (e, key) => {
    if (e.target != undefined) {
      let { name, value } = e.target;
      if (name == "question") {
        let newState = [...formData[name]];
        if (newState[key] != undefined) {
          newState[key] = value;
        }
        setFormData((formData) => ({ ...formData, [name]: newState }));
      } else if (name == "ansqwer") {
      } else if (name == "image") {
        if (e.target.files.length > 0) {
          for (let i = 0; i < e.target.files.length; i++) {
            let images = {};

            images = {
              id: "",
              image: "",
              video: "",
              file: e.target.files[i],
            };
            setFormData((formData) => ({
              ...formData,
              [name]: [...formData.image, images],
            }));
          }
        }
      } else if (name == "specification") {
        let newStateS = [...formData[name]];
        if (newStateS[key] != undefined) {
          newStateS[key] = value;
        }
        setFormData((formData) => ({ ...formData, [name]: newStateS }));
      } else {
        setFormData((formData) => ({ ...formData, [name]: value }));
      }
    } else {
      if (editorType != "") {
        if (editorType == "answer") {
          let newState = [...formData[editorType]];
          if (newState[key] != undefined) {
            newState[key] = e;
            setFormData((formData) => ({
              ...formData,
              [editorType]: newState,
            }));
          }
        } else {
          setFormData((formData) => ({ ...formData, [editorType]: e }));
        }
      }
    }
  };

  const handleChangeSafetyDescrpt = (e) => {
    setFormData((formData) => ({ ...formData, ["safetyinstruction"]: e }));
  };

  const handleChangeWarantyDescrpt = (e) => {
    setFormData((formData) => ({ ...formData, ["warantydescription"]: e }));
  };

  const validateFormData = async () => {
    try {
      if (formData.productname === "") {
        errors.productname = "Enter product name properly ";
      }
      if (formData.description == "") {
        errors.description = "Enter description";
      }
      if (formData.quantity == "") {
        errors.quantity = "Enter quantity";
      }
      if (formData.weight == "") {
        errors.weight = "Enter weight";
      }
      if (formData.lengths == "") {
        errors.lengths = "Enter length";
      }
      if (formData.breath == "") {
        errors.breath = "Enter breadth";
      }
      if (formData.width == "") {
        errors.width = "Enter width";
      }
      if (formData.safetyinstruction == "") {
        errors.safetyinstruction = "Enter safety care instructions";
      }
      if (formData.waranty == "") {
        errors.waranty = "Enter Warranty";
      }
      if (formData.warantydescription == "") {
        errors.warantydescription = "Enter warranty guarantee description";
      }
      if (formData.price == "") {
        errors.price = "Enter price";
      }
      if (formData.category == "") {
        errors.category = "Choose category";
      }
      if (formData.subcategory == "") {
        errors.subcategory = "Choose subcategory";
      }
      if (formData.productsku == "") {
        errors.productsku = "Enter product SKU";
      }
      if (formData.image == "" || formData.image == undefined) {
        errors.image = "Please choose an image";
      }
      setFormError(errors);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddProduct = async (e) => {
    try {
      e.preventDefault();
      validateFormData();
      if (Object.keys(errors).length === 0) {
        setButtonLoader(true);
        asyncAddProduct(formData, { navigate, setButtonLoader });
      } else {
        handleScrollError(errors);
      }
    } catch (error) {
      errorMsg("something went wrong!");
    }
  };
  // ------------ add product api  End------------------

  const handleRemoveImage = (key) => {
    let images_arr = formData.image;

    images_arr.splice(key, 1);

    setFormData((prev) => ({
      ...prev,
      image: images_arr,
    }));
  };

  // -------------- show product details ----------------------
  useEffect(() => {
    if (id) {
      getMyProductDetails(id, getMyProductDetail, setIsLoader, navigate);
    }
  }, [id]);

  useEffect(() => {
    if (id != "" && myproductdetail != undefined) {
      setFormData({
        ...formData,

        productname: myproductdetail.name,
        description: myproductdetail.description,
        quantity: myproductdetail.quantity,
        productsku: myproductdetail.product_sku,
        weight: myproductdetail.item_weight,
        lengths: myproductdetail.length,
        breath: myproductdetail.breath,
        width: myproductdetail.width,
        location: myproductdetail.location,
        safetyinstruction: myproductdetail.safty_care_instructions,
        waranty: myproductdetail.warranty_guarante,
        warantydescription: myproductdetail.warranty_guarante_description,
        price: myproductdetail.price,
        category: myproductdetail.category_id,
        subcategory: myproductdetail.sub_category_id,
        image: myproductdetail.product_image,
        answer: getArray(myproductdetail.faq, "answer"),
        question: getArray(myproductdetail.faq, "question"),
        specification: getArray(
          myproductdetail.product_specifications,
          "title"
        ),
        compare_price: myproductdetail.compare_price,
        promo_code: myproductdetail.promo_code,
        promocode_value: myproductdetail.promo_code_value,
        promo_type: myproductdetail.type,
        delivery_time: myproductdetail.delivery_time,
        shiping_id: myproductdetail.shiping_id,
        tax_id: myproductdetail.tax_id,
      });
    }
  }, [myproductdetail]);
  // -------------- show product details ----------------------

  // ------------- update product ------------
  const handleupdate = (e, prd_id) => {
    try {
      e.preventDefault();
      validateFormData();
      if (Object.keys(errors).length === 0) {
        setButtonLoader(true);
        asyncAddProduct(formData, { navigate, setButtonLoader }, prd_id);
      } else {
        handleScrollError(errors);
      }
    } catch (error) {
      errorMsg("something went wrong!");
    }
  };

  // ----------- product category ------------
  const [productcategory, setProductCategory] = useState();
  const [subcategory, setSubCategory] = useState();
  useEffect(() => {
    getProductCategory(setProductCategory);
  }, []);

  useEffect(() => {
    if (formData.category != undefined && formData.category != "") {
      getMyProductSubCategory(setSubCategory, formData.category);
    }
  }, [formData.category]);

  // -------------------------
  const [shipinginput, setShipinginput] = useState();
  const [taxidinput, setTaxIdInput] = useState();

  useEffect(() => {
    getShipingInputData(setShipinginput);
    getTaxInputData(setTaxIdInput);
  }, []);
  const handleClick = () => {
    inputRef.current.click();
  };

  console.log("formData=", formData);

  return (
    <motion.div
      initial={{ opacity: 0.2 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 1 }}
      transition={{ duration: 2 }}
    >
      <div className="all_page_bg only_bg_color addprodform add-product-page">
        <div className="container-fluid">
          <Header />
          <div className="page-all-data">
            <div className="add-product-main">
              <div className="add_product_textfild ">
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="heading-group">
                      <div className="back_btn">
                        <button onClick={() => directpage("mystore")}>
                          {reactIcons.arrowLeft}
                        </button>
                      </div>
                      <div className="new_prd_heading">
                        <h1>
                          {" "}
                          {id ? "Edit Your Product" : "Add New Product"}{" "}
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />

                {isLoader == true ? (
                  <div className="">
                    <Loader />
                  </div>
                ) : (
                  <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 new-all-inputs">
                      <div className="product_detail_text">
                        <h4>Product Detail</h4>
                      </div>
                      <div className="mb-3">
                        <FormHelperText
                          sx={{ color: "#fff", marginBottom: "5px" }}
                        >
                          Product Name
                        </FormHelperText>
                        <TextField
                          sx={{
                            width: "100%",
                          }}
                          name="productname"
                          className="input_username new-edit-main-all"
                          id="outlined-basic"
                          value={formData.productname}
                          placeholder="Enter product name"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          error={formErrors.productname}
                          helperText={formErrors.productname}
                        />
                      </div>
                      <div className="mb-3">
                        <FormHelperText
                          sx={{ color: "#fff", marginBottom: "5px" }}
                        >
                          Description
                        </FormHelperText>
                        <TextField
                          sx={{
                            width: "100%",
                          }}
                          error={formErrors.description}
                          name="description"
                          className="input_username new-edit-main-all"
                          id="outlined-basic"
                          placeholder="description"
                          onChange={(e) => handleChange(e)}
                          multiline
                          value={formData.description}
                          rows={4}
                          helperText={formErrors.description}
                        />
                      </div>
                      {/* <div className="account_txteditor_main">
                        <h6>Description</h6>
                        <div
                          style={{
                            display: "grid",
                            color: "#fff",
                            justifyContent: "center",
                          }}
                        >
                          <ReactQuill
                            className="add-new-product-all-description"
                            theme="snow"
                            modules={modules}
                            formats={formats}
                            placeholder=" "
                            onFocus={(e) => {
                              setEditorType("description");
                            }}
                            value={formData.description}
                            // onChange={handleProcedureContentChange}
                            onChange={(e) => handleChange(e)}
                            style={{ height: "80px", color: "#fff" }}
                          ></ReactQuill>
                        </div>
                      </div>
                      {formErrors.description && (
                        <div className="invalid-msg">
                          {formErrors.description}
                        </div>
                      )} */}

                      <div className="search_txteditor_category">
                        <h3>Category</h3>
                        <div className="mb-3">
                          <FormHelperText
                            sx={{ color: "#fff", marginBottom: "5px" }}
                          >
                            Category
                          </FormHelperText>
                          <Select
                            className="input_username new-edit-main-all w-100"
                            id="demo-simple-select-helper-label"
                            name="category"
                            value={formData.category}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            MenuProps={MenuProps}
                            error={formErrors.category}
                            helperText={formErrors.category}
                          >
                            {productcategory &&
                              productcategory.length > 0 &&
                              productcategory.map((value, index) => {
                                return (
                                  <MenuItem key={index} value={value.id}>
                                    {value.name}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                          {formErrors.category && (
                            <div className="invalid-msg">
                              {formErrors.category}
                            </div>
                          )}
                        </div>
                        {/* <FormControl fullWidth>
                          <InputLabel
                            sx={{
                              color: "#fff",
                            }}
                            id="demo-simple-select-helper-label"
                          >
                            Category
                          </InputLabel>
                          <Select
                            className="input_username new-edit-main-all"
                            id="demo-simple-select-helper-label"
                            label="category (required)"
                            name="category"
                            value={formData.category}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            MenuProps={MenuProps}
                            error={formErrors.category}
                            helperText={formErrors.category}
                          >
                            {productcategory &&
                              productcategory.length > 0 &&
                              productcategory.map((value, index) => {
                                return (
                                  <MenuItem key={index} value={value.id}>
                                    {value.name}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        </FormControl>
                        {formErrors.category && (
                          <div className="invalid-msg">
                            {formErrors.category}
                          </div>
                        )} */}
                      </div>

                      <div className="subcategory_productdropdown">
                        <div className="mb-3">
                          <FormHelperText
                            sx={{ color: "#fff", marginBottom: "5px" }}
                          >
                            Sub Category
                          </FormHelperText>
                          <Select
                            className="input_username new-edit-main-all new-sub-category w-100"
                            id="demo-simple-select-helper-label"
                            value={formData.subcategory}
                            name="subcategory"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            MenuProps={MenuProps}
                            error={formErrors.subcategory}
                            helperText={formErrors.subcategory}
                          >
                            {subcategory &&
                              subcategory.length > 0 &&
                              subcategory.map((value, index) => {
                                return (
                                  <MenuItem key={index} value={value.id}>
                                    {value.name}
                                  </MenuItem>
                                );
                              })}
                            {/* <MenuItem value="2">Male</MenuItem>
                        <MenuItem value="3">Female</MenuItem> */}
                          </Select>
                          {formErrors.subcategory && (
                            <div className="invalid-msg">
                              {formErrors.subcategory}
                            </div>
                          )}
                        </div>
                        {/* <FormControl fullWidth>
                          <InputLabel
                            sx={{
                              color: "#fff",
                            }}
                            id="demo-simple-select-helper-label"
                          >
                            Sub Category
                          </InputLabel>
                          <Select
                            className="input_username new-edit-main-all new-sub-category"
                            id="demo-simple-select-helper-label"
                            label="subcategory (required)"
                            value={formData.subcategory}
                            name="subcategory"
                            // defaultValue={userInfo.country_id}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            MenuProps={MenuProps}
                            error={formErrors.subcategory}
                            helperText={formErrors.subcategory}
                          >
                            {subcategory &&
                              subcategory.length > 0 &&
                              subcategory.map((value, index) => {
                                return (
                                  <MenuItem key={index} value={value.id}>
                                    {value.name}
                                  </MenuItem>
                                );
                              })}
                        
                          </Select>
                        </FormControl>
                        {formErrors.subcategory && (
                          <div className="invalid-msg">
                            {formErrors.subcategory}
                          </div>
                        )} */}
                      </div>

                      <div className="search_txteditor_category">
                        <h2>Inventory</h2>
                      </div>
                      <div className="mb-3">
                        <FormHelperText
                          sx={{ color: "#fff", marginBottom: "5px" }}
                        >
                          Quantity
                        </FormHelperText>
                        <TextField
                          sx={{
                            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                              display: "none",
                            },
                            "& input[type=number]": {
                              MozAppearance: "textfield",
                            },
                            width: "100%",
                          }}
                          type="number"
                          name="quantity"
                          className="input_username new-edit-main-all"
                          id="outlined-basic"
                          value={formData.quantity}
                          placeholder="Enter your product quantity"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          error={formErrors.quantity}
                          helperText={formErrors.quantity}
                        />
                      </div>
                      <div className="mb-3">
                        <FormHelperText
                          sx={{ color: "#fff", marginBottom: "5px" }}
                        >
                          Product SKU
                        </FormHelperText>
                        <TextField
                          sx={{
                            width: "100%",
                          }}
                          name="productsku"
                          className="input_username new-edit-main-all"
                          id="outlined-basic"
                          value={formData.productsku}
                          placeholder="Enter text"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          error={formErrors.productsku}
                          helperText={formErrors.productsku}
                        />
                      </div>

                      <div className="search_txteditor_category">
                        <h2>FAQs</h2>
                      </div>

                      {formData.question?.map((x, i) => {
                        return (
                          <>
                            <div className="mb-3">
                              <FormHelperText
                                sx={{ color: "#fff", marginBottom: "5px" }}
                              >
                                Faq
                              </FormHelperText>
                              <TextField
                                sx={{
                                  width: "100%",
                                }}
                                name="question"
                                className="input_username new-edit-main-all"
                                id="outlined-basic"
                                value={x}
                                placeholder="Enter your text"
                                // onChange={(e) => {
                                //   handleChange(e);
                                // }}
                                onChange={(e) => handleChange(e, i)}
                              />
                            </div>
                            <TextField
                              sx={{
                                width: "100%",
                              }}
                              name="answer"
                              className="input_username new-edit-main-all"
                              id="outlined-basic"
                              placeholder="answer"
                              onChange={(e) => handleChange(e, i)}
                              multiline
                              value={formData.answer[i]}
                              rows={4}
                            />
                            {/* <div className="account_txteditor_main">
                              <div
                                style={{
                                  display: "grid",
                                  color: "#fff",
                                  justifyContent: "center",
                                }}
                              >
                                <ReactQuill
                                  className="add-new-product-all-description"
                                  theme="snow"
                                  modules={modules}
                                  formats={formats}
                                  placeholder=" "
                                  value={formData.answer[i]}
                                  onChange={(e) => handleChange(e, i)}
                                  onFocus={() => {
                                    setEditorType("answer");
                                  }}
                                  style={{ height: "80px", color: "#fff" }}
                                ></ReactQuill>
                              </div>
                            </div> */}

                            <div className="addmore_addbtn mb-2 ">
                              {formData.question.length !== 1 && (
                                <button
                                  className="mr-2"
                                  onClick={() => handleRemoveClick(i)}
                                >
                                  Remove
                                </button>
                              )}
                              {formData.question.length - 1 === i && (
                                <button onClick={handleAddClick}>
                                  + Add More
                                </button>
                              )}
                            </div>
                          </>
                        );
                      })}
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 new-all-inputs ">
                      <div className="product_detail_text">
                        <h4>Shipping and Delivery</h4>
                      </div>
                      <div class="mb-3">
                        <FormHelperText
                          sx={{ color: "#fff", marginBottom: "5px" }}
                        >
                          Item Weight in ( Kg )
                        </FormHelperText>
                        <TextField
                          sx={{
                            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                              display: "none",
                            },
                            "& input[type=number]": {
                              MozAppearance: "textfield",
                            },
                            width: "100%",
                          }}
                          type="number"
                          name="weight"
                          className="input_username new-edit-main-all"
                          id="outlined-basic"
                          value={formData.weight}
                          placeholder="Enter your item weight"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          error={formErrors.weight}
                          helperText={formErrors.weight}
                        />
                        <div className="product_package_text">
                          <h4>
                            Package Size ( The package you use to ship your
                            product )
                          </h4>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mb-3">
                          <FormHelperText
                            sx={{ color: "#fff", marginBottom: "5px" }}
                          >
                            Length
                          </FormHelperText>
                          <TextField
                            sx={{
                              "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                display: "none",
                              },
                              "& input[type=number]": {
                                MozAppearance: "textfield",
                              },
                              width: "100%",
                            }}
                            type="number"
                            name="lengths"
                            className="input_username new-edit-main-all"
                            id="outlined-basic"
                            value={formData.lengths}
                            placeholder="in"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            error={formErrors.lengths}
                            helperText={formErrors.lengths}
                          />
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mb-3">
                          <FormHelperText
                            sx={{ color: "#fff", marginBottom: "5px" }}
                          >
                            Breadth
                          </FormHelperText>
                          <TextField
                            sx={{
                              "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                display: "none",
                              },
                              "& input[type=number]": {
                                MozAppearance: "textfield",
                              },
                              width: "100%",
                            }}
                            type="number"
                            name="breath"
                            className="input_username new-edit-main-all"
                            id="outlined-basic"
                            placeholder="in"
                            value={formData.breath}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            error={formErrors.breath}
                            helperText={formErrors.breath}
                          />
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mb-3">
                          <FormHelperText
                            sx={{ color: "#fff", marginBottom: "5px" }}
                          >
                            Width
                          </FormHelperText>
                          <TextField
                            sx={{
                              "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                display: "none",
                              },
                              "& input[type=number]": {
                                MozAppearance: "textfield",
                              },
                              width: "100%",
                            }}
                            type="number"
                            name="width"
                            className="input_username new-edit-main-all"
                            id="outlined-basic"
                            value={formData.width}
                            placeholder="in"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            error={formErrors.width}
                            helperText={formErrors.width}
                          />
                        </div>
                      </div>

                      {/* <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12"> */}
                      <div class="mb-3">
                        <FormHelperText
                          sx={{ color: "#fff", marginBottom: "5px" }}
                        >
                          Delivery Time
                        </FormHelperText>
                        <TextField
                          sx={{
                            width: "100%",
                          }}
                          name="delivery_time"
                          value={formData.delivery_time}
                          className="input_username new-edit-main-all product-mid-div mb-4"
                          id="outlined-basic"
                          placeholder="Enter delivery time"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        />
                      </div>

                      {/* </div> */}
                      <div class="mb-3">
                        <FormHelperText
                          sx={{ color: "#fff", marginBottom: "5px" }}
                        >
                          Select Shiping
                        </FormHelperText>
                        <Select
                          className="input_username new-edit-main-all w-100"
                          id="demo-simple-select-helper-label"
                          value={formData.shiping_id}
                          name="shiping_id"
                          // defaultValue={userInfo.country_id}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          MenuProps={MenuProps}
                        // error={formErrors.subcategory}
                        // helperText={formErrors.subcategory}
                        >
                          {shipinginput != undefined &&
                            shipinginput.length > 0 ? (
                            shipinginput.map((value, index) => {
                              return (
                                <MenuItem value={value.id}>
                                  {value.title}
                                </MenuItem>
                              );
                            })
                          ) : (
                            <MenuItem value="">Record not found</MenuItem>
                          )}
                        </Select>
                      </div>
                      {/* <FormControl fullWidth>
                        <InputLabel
                          sx={{
                            color: "#fff",
                          }}
                          id="demo-simple-select-helper-label"
                        >
                          Select Shiping
                        </InputLabel>
                        <Select
                          className="input_username new-edit-main-all mb-4"
                          id="demo-simple-select-helper-label"
                          label="Select Shiping"
                          value={formData.shiping_id}
                          name="shiping_id"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          MenuProps={MenuProps}
                        >
                          {shipinginput != undefined &&
                          shipinginput.length > 0 ? (
                            shipinginput.map((value, index) => {
                              return (
                                <MenuItem value={value.id}>
                                  {value.title}
                                </MenuItem>
                              );
                            })
                          ) : (
                            <MenuItem value="">Record not found</MenuItem>
                          )}
                        </Select>
                      </FormControl> */}
                      <div class="mb-3">
                        <FormHelperText
                          sx={{ color: "#fff", marginBottom: "5px" }}
                        >
                          Select Tax
                        </FormHelperText>
                        <Select
                          className="input_username new-edit-main-all w-100"
                          id="demo-simple-select-helper-label"
                          value={formData.tax_id}
                          name="tax_id"
                          // defaultValue={userInfo.country_id}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          MenuProps={MenuProps}
                        // error={formErrors.subcategory}
                        // helperText={formErrors.subcategory}
                        >
                          {taxidinput != undefined && taxidinput.length > 0 ? (
                            taxidinput.map((value, index) => {
                              return (
                                <MenuItem value={value.id}>
                                  {value.title}
                                </MenuItem>
                              );
                            })
                          ) : (
                            <MenuItem value="">Record not found</MenuItem>
                          )}
                        </Select>
                      </div>
                      {/* <FormControl fullWidth>
                        <InputLabel
                          sx={{
                            color: "#fff",
                          }}
                          id="demo-simple-select-helper-label"
                        >
                          Select Tax
                        </InputLabel>
                        <Select
                          className="input_username new-edit-main-all mb-4"
                          id="demo-simple-select-helper-label"
                          label="Select Tax"
                          value={formData.tax_id}
                          name="tax_id"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          MenuProps={MenuProps}
                        >
                          {taxidinput != undefined && taxidinput.length > 0 ? (
                            taxidinput.map((value, index) => {
                              return (
                                <MenuItem value={value.id}>
                                  {value.title}
                                </MenuItem>
                              );
                            })
                          ) : (
                            <MenuItem value="">Record not found</MenuItem>
                          )}
                        </Select>
                      </FormControl> */}

                      <div className="search_txteditor_category">
                        <h2>Location</h2>
                      </div>
                      <div class="mb-3">
                        <FormHelperText
                          sx={{ color: "#fff", marginBottom: "5px" }}
                        >
                          Location
                        </FormHelperText>
                        <TextField
                          sx={{
                            width: "100%",
                          }}
                          name="location"
                          className="input_username new-edit-main-all"
                          id="outlined-basic"
                          value={formData.location}
                          placeholder="Enenter your location "
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        />
                      </div>

                      {/* <div className="search_txteditor_category">
                      <h2>Safety & Care Instructions</h2>
                    </div>

                    <div className="new-data-error">
                      <div className="account_txteditor_main">
                        <h6>Instruction</h6>
                        <div
                          style={{
                            display: "grid",
                            color: "#fff",
                            justifyContent: "center",
                          }}
                        >
                          <ReactQuill
                            theme="snow"
                            modules={modules}
                            formats={formats}
                            placeholder=" "
                            onFocus={() => {
                              setEditorType("safetyinstruction");
                            }}
                            value={formData.safetyinstruction}
                            // onChange={handleProcedureContentChange}
                            onChange={(e) => {
                              handleChangeSafetyDescrpt(e);
                            }}
                            style={{ height: "80px", color: "#fff" }}
                          ></ReactQuill>
                        </div>
                      </div>
                      {formErrors.safetyinstruction && (
                        <div className="invalid-msg">
                          {formErrors.safetyinstruction}
                        </div>
                      )}
                    </div> */}

                      <div className="search_txteditor_category">
                        <h2>Warranty/Guarantee </h2>
                      </div>
                      <div class="mb-3">
                        <FormHelperText
                          sx={{ color: "#fff", marginBottom: "5px" }}
                        >
                          Monthly And Yearly
                        </FormHelperText>
                        <TextField
                          sx={{
                            width: "100%",
                          }}
                          name="waranty"
                          className="input_username new-edit-main-all"
                          id="outlined-basic"
                          value={formData.waranty}
                          placeholder="Enter your product Warranty"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          error={formErrors.waranty}
                          helperText={formErrors.waranty}
                        />
                      </div>
                      <div class="mb-3">
                        <FormHelperText
                          sx={{ color: "#fff", marginBottom: "5px" }}
                        >
                          Description
                        </FormHelperText>
                        <TextField
                          sx={{
                            width: "100%",
                          }}
                          name="warantydescription"
                          className="input_username new-edit-main-all"
                          id="outlined-basic"
                          value={formData.warantydescription}
                          placeholder="description"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          multiline
                          rows={4}
                        />
                      </div>
                      {/* <div className="new-data-error">
                        <div className="account_txteditor_main">
                          <h6>Description</h6>
                          <div
                            style={{
                              display: "grid",
                              color: "#fff",
                              justifyContent: "center",
                            }}
                          >
                            <ReactQuill
                              className="add-new-product-all-description"
                              theme="snow"
                              modules={modules}
                              formats={formats}
                              placeholder=" "
                              onFocus={() => {
                                setEditorType("warantydescription");
                              }}
                              value={formData.warantydescription}
                              onChange={(e) => {
                                handleChangeWarantyDescrpt(e);
                              }}
                              style={{ height: "80px", color: "#fff" }}
                            ></ReactQuill>
                          </div>
                        </div>
                        {formErrors.warantydescription && (
                          <div className="invalid-msg">
                            {formErrors.warantydescription}
                          </div>
                        )}
                      </div> */}

                      <div className="product_detail_text">
                        <h4>Pricing</h4>
                      </div>
                      <div class="mb-3">
                        <FormHelperText
                          sx={{ color: "#fff", marginBottom: "5px" }}
                        >
                          Price
                        </FormHelperText>
                        <TextField
                          sx={{
                            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                              display: "none",
                            },
                            "& input[type=number]": {
                              MozAppearance: "textfield",
                            },
                            width: "100%",
                          }}
                          type="number"
                          name="price"
                          className="input_username new-edit-main-all"
                          id="outlined-basic"
                          value={formData.price}
                          placeholder="Enter your price"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          error={formErrors.price}
                          helperText={formErrors.price}
                        />
                      </div>
                      <div class="mb-3">
                        <FormHelperText
                          sx={{ color: "#fff", marginBottom: "5px" }}
                        >
                          Compare at Price
                        </FormHelperText>
                        <TextField
                          sx={{
                            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                              display: "none",
                            },
                            "& input[type=number]": {
                              MozAppearance: "textfield",
                            },
                            width: "100%",
                          }}
                          type="number"
                          value={formData.compare_price}
                          name="compare_price"
                          className="input_username new-edit-main-all product-mid-div"
                          id="outlined-basic"
                          placeholder="Select your compare price"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 new-all-inputs">
                      <div className="product_detail_text">
                        <h4>Product Image</h4>
                      </div>

                      <div className="main_box_add_product">
                        <div className="product-form-image">
                          <div className="box_add_product">
                            <div className="add_product_img">
                              <img src={"/images/upload-post1.png"} alt="" />
                            </div>
                            <div className="add_product_text">
                              <h4>Image Format jpg and png</h4>
                            </div>

                            <div className="file_upload_product">
                              <input
                                style={{ display: "none" }}
                                type="file"
                                name="image"
                                inputProps={{ accept: "image/*" }}
                                accept="image/*"
                                // onChange={fileChangedHandler}
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                // inputProps={{ multiple: true }}
                                multiple={true}
                                ref={inputRef}
                              />

                              <button onClick={handleClick}>
                                Browse files
                              </button>
                            </div>
                          </div>
                          {formErrors.image && (
                            <div className="image_error">
                              <p> {formErrors.image} </p>
                            </div>
                          )}
                        </div>

                        <div
                          show={show}
                          className="main_box_img"
                          onHide={handleClose}
                        >
                          <div className="row">
                            {formData.image != undefined &&
                              formData.image.length > 0 &&
                              formData.image.map((values, index) => {
                                return (
                                  <div
                                    className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 "
                                    key={index}
                                  >
                                    <div className="image_add_prd add_product_mulimg position-relative">
                                      <div className="image-delete-icon">
                                        <button
                                          onClick={() => {
                                            handleRemoveImage(index);
                                          }}
                                        >
                                          <img
                                            src={"/icons/delete.png"}
                                            alt="delete"
                                          />
                                        </button>
                                      </div>
                                      <img
                                        className="preview"
                                        src={
                                          values.image == ""
                                            ? URL.createObjectURL(values.file)
                                            : values.image
                                        }
                                        alt={"image-" + index}
                                        key={index}
                                      />
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                        <div class="mb-3">
                          <FormHelperText
                            sx={{ color: "#fff", marginBottom: "5px" }}
                          >
                            Promo Code
                          </FormHelperText>
                          <TextField
                            sx={{
                              width: "100%",
                            }}
                            name="promo_code"
                            className="input_username new-edit-main-all"
                            id="outlined-basic"
                            value={formData.promo_code}
                            placeholder="Enter product promo code"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            error={formErrors.promo_code}
                            helperText={formErrors.promo_code}
                          />
                        </div>
                        <div class="mb-3">
                          <FormHelperText
                            sx={{ color: "#fff", marginBottom: "5px" }}
                          >
                            Promo Code Value
                          </FormHelperText>
                          <TextField
                            sx={{
                              "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                display: "none",
                              },
                              "& input[type=number]": {
                                MozAppearance: "textfield",
                              },
                              width: "100%",
                            }}
                            type="number"
                            name="promocode_value"
                            className="input_username new-edit-main-all"
                            id="outlined-basic"
                            value={formData.promocode_value}
                            placeholder="Enter product promocode value"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            error={formErrors.promocode_value}
                            helperText={formErrors.promocode_value}
                          />
                        </div>
                        <div class="mb-3">
                          <FormHelperText
                            sx={{ color: "#fff", marginBottom: "5px" }}
                          >
                            Promo Code Value Type
                          </FormHelperText>
                          <Select
                            className="input_username new-edit-main-all w-100"
                            id="demo-simple-select-helper-label"
                            value={formData.promo_type}
                            name="promo_type"
                            // defaultValue={userInfo.country_id}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            MenuProps={MenuProps}
                          // error={formErrors.subcategory}
                          // helperText={formErrors.subcategory}
                          >
                            <MenuItem value="fixed">Fixed</MenuItem>
                            <MenuItem value="percentage">percentage</MenuItem>
                          </Select>
                        </div>
                        {/* <FormControl fullWidth>
                          <InputLabel
                            sx={{
                              color: "#fff",
                            }}
                            id="demo-simple-select-helper-label"
                          >
                            Promo Code Value Type
                          </InputLabel>
                          <Select
                            className="input_username new-edit-main-all"
                            id="demo-simple-select-helper-label"
                            label="Promo Code Value Type"
                            value={formData.promo_type}
                            name="promo_type"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            MenuProps={MenuProps}
                          >
                            <MenuItem value="fixed">Fixed</MenuItem>
                            <MenuItem value="percentage">percentage</MenuItem>
                          </Select>
                        </FormControl> */}
                      </div>

                      <div className="search_txteditor_category">
                        <h2>Safety & Care Instructions</h2>
                      </div>
                      <div class="mb-3">
                        <FormHelperText
                          sx={{ color: "#fff", marginBottom: "5px" }}
                        >
                          Instruction
                        </FormHelperText>
                        <TextField
                          sx={{
                            width: "100%",
                          }}
                          error={formErrors.safetyinstruction}
                          name="safetyinstruction"
                          className="input_username new-edit-main-all"
                          id="outlined-basic"
                          placeholder="instruction"
                          onChange={(e) => handleChange(e)}
                          multiline
                          value={formData.safetyinstruction}
                          rows={4}
                        />
                      </div>
                      {/* <div className="new-data-error">
                        <div className="account_txteditor_main">
                          <h6>Instruction</h6>
                          <div
                            style={{
                              display: "grid",
                              color: "#fff",
                              justifyContent: "center",
                            }}
                          >
                            <ReactQuill
                              className="add-new-product-all-description"
                              theme="snow"
                              modules={modules}
                              formats={formats}
                              placeholder=" "
                              onFocus={() => {
                                setEditorType("safetyinstruction");
                              }}
                              value={formData.safetyinstruction}
                              onChange={(e) => {
                                handleChangeSafetyDescrpt(e);
                              }}
                              style={{ height: "80px", color: "#fff" }}
                            ></ReactQuill>
                          </div>
                        </div>
                        {formErrors.safetyinstruction && (
                          <div className="invalid-msg">
                            {formErrors.safetyinstruction}
                          </div>
                        )}
                      </div> */}

                      {/* <TextField
                      sx={{
                        width: "100%",
                      }}
                      name="delivery_time"
                      value={formData.delivery_time}
                      className="input_username new-edit-main-all product-mid-div mb-4"
                      id="outlined-basic"
                      label="Delivery Time"
                      placeholder="Enter delivery time"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />

                    <FormControl fullWidth>
                      <InputLabel
                        sx={{
                          color: "#fff",
                        }}
                        id="demo-simple-select-helper-label"
                      >
                       Select Shiping 
                      </InputLabel>
                      <Select
                        className="input_username new-edit-main-all mb-4"
                        id="demo-simple-select-helper-label"
                        label="Shiping Id"
                        value={formData.shiping_id}
                        name="shiping_id"
                        // defaultValue={userInfo.country_id}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        MenuProps={MenuProps}
                        // error={formErrors.subcategory}
                        // helperText={formErrors.subcategory}
                      >
                        {shipinginput != undefined &&
                        shipinginput.length > 0 ? (
                          shipinginput.map((value, index) => {
                            return (
                              <MenuItem value={value.id}>
                                {value.title}
                              </MenuItem>
                            );
                          })
                        ) : (
                          <MenuItem value="">Record not found</MenuItem>
                        )}
                      </Select>
                    </FormControl> */}

                      {/* <FormControl fullWidth>
                      <InputLabel
                        sx={{
                          color: "#fff",
                        }}
                        id="demo-simple-select-helper-label"
                      >
                        Select Tax
                      </InputLabel>
                      <Select
                        className="input_username new-edit-main-all mb-4"
                        id="demo-simple-select-helper-label"
                        label="Select Tax"
                        value={formData.tax_id}
                        name="tax_id"
                        // defaultValue={userInfo.country_id}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        MenuProps={MenuProps}
                        // error={formErrors.subcategory}
                        // helperText={formErrors.subcategory}
                      >
                        {taxidinput != undefined && taxidinput.length > 0 ? (
                          taxidinput.map((value, index) => {
                            return (
                              <MenuItem value={value.id}>
                                {value.title}
                              </MenuItem>
                            );
                          })
                        ) : (
                          <MenuItem value="">Record not found</MenuItem>
                        )}
                      </Select>
                    </FormControl> */}

                      <div className="search_txteditor_category">
                        <h2> Specification </h2>
                      </div>

                      {formData.specification?.map((x, i) => {
                        return (
                          <>
                            <FormHelperText
                              sx={{ color: "#fff", marginBottom: "5px" }}
                            >
                              Specification
                            </FormHelperText>
                            <TextField
                              sx={{
                                width: "100%",
                              }}
                              name="specification"
                              className="input_username new-edit-main-all"
                              id="outlined-basic"
                              value={x}
                              placeholder="Enter your text"
                              onChange={(e) => handleChange(e, i)}
                            />

                            <div className="addmore_addbtn mb-2 ">
                              {formData.specification.length !== 1 && (
                                <button
                                  className="mr-2"
                                  onClick={() => handleRemovespecification(i)}
                                >
                                  Remove
                                </button>
                              )}
                              {formData.specification.length - 1 === i && (
                                <button onClick={handleAddspecification}>
                                  + Add More
                                </button>
                              )}
                            </div>
                          </>
                        );
                      })}

                      {/* {formData.question?.map((x, i) => {
                    return (
                      <>
                        <TextField
                          sx={{
                            width: "100%",
                          }}
                          name="specification"
                          className="input_username new-edit-main-all"
                          id="outlined-basic"
                          label="specification"
                          value={x.question}
                          placeholder="Enter your text"
                          onChange={(e) => handleChange(e, i)}
                        />

                        <div className="addmore_addbtn mb-2 ">
                          {formData.question.length !== 1 && (
                            <button
                              className="mr-2"
                              onClick={() => handleRemoveClick(i)}
                            >
                              Remove
                            </button>
                          )}
                          {formData.question.length - 1 === i && (
                            <button onClick={handleAddClick}>+ Add More</button>
                          )}
                        </div>
                      </>
                    );
                  })} */}
                    </div>
                    <div className="col-md-12">
                      <div className="product_button_main">
                        <div className="Discard_btn">
                          <button>Discard</button>
                        </div>
                        <div className="add_product_btn">
                          {buttonloader == true ? (
                            <button type="button">
                              <SmallLoader
                                type="spinner-default"
                                bgColor={"#fff"}
                                color={"#fff"}
                                size={40}
                              />
                            </button>
                          ) : (
                            <>
                              {id ? (
                                <button
                                  type="button"
                                  onClick={(e) =>
                                    handleupdate(e, myproductdetail.id)
                                  }
                                >
                                  Update Product
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  onClick={(e) => {
                                    handleAddProduct(e);
                                  }}
                                >
                                  Add Product
                                </button>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <FooterBig />
        </div>
      </div>
    </motion.div>
  );
};
export default AddProductForm;
