import React, { useEffect, useState } from "react";
import { FormHelperText, TextField } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/fontawesome-free-solid";
import { handleScrollError, images } from "../../actions/customFn";
import { useRef } from "react";
import {
  asyncAddCampaign,
  getCampaignCategory,
} from "../../actions/addCampaign";
import validator from "validator";
import { InputLabel, MenuItem, Select, FormControl } from "@mui/material";
import { each } from "jquery";
import { NavLink } from "react-router-dom";
import SmallLoader from "react-js-loader";

const AddCampaignsModal = (props) => {
  const inputRef = useRef(null);
  // const [file, setFile] = useState(null);

  const [checkterm, setCheckTerm] = useState(false);

  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    image: "",
    accountholder: "",
    accountnumber: "",
    ifscCode: "",
    title: "",
    shortDescription: "",
    campaigntarget: "",
    category: "",
    national_insurance: "",
    // bank_account : "",
    // mailing_address : "",
  });

  const [formErrors, setFormError] = useState({});
  const errors = {};

  const [CampaignCategory, setCampaignCategory] = useState();

  const [buttonloader, setButtonLoader] = useState(false);

  useEffect(() => {
    getCampaignCategory(setCampaignCategory);
  }, []);

  const handleChange = (e) => {
    if (e.target != undefined) {
      const { name, value } = e.target;
      const onlyNumbers = /^[0-9]*$/;
      if (name == "image") {
        setFormData((formData) => ({
          ...formData,
          [name]: e.target.files[0],
        }));
      } else {
        if (
          name == "phone" ||
          name == "campaigntarget" ||
          name == "accountnumber" ||
          name == "ifscCode"
        ) {
          if (onlyNumbers.test(value) === true) {
            setFormData((formData) => ({ ...formData, [name]: value }));
          }
        } else {
          setFormData((formData) => ({ ...formData, [name]: value }));
        }
      }
    }
  };

  const handleCheckTerm = (e) => {
    if (e.target.checked == true) {
      setCheckTerm(true);
    } else {
      setCheckTerm(false);
    }
  };

  // console.log("formData=", formData);
  const handleClick = () => {
    inputRef.current.click();
  };

  const [preview, setpreview] = useState("/images/pdf2_img2.png");
  // console.log("formdata@=",formData);
  const fileChangedHandler = (event) => {
    // let reader = new FileReader();
    // reader.onload = function(e) {
    //   setFile(e.target.result);
    // };
    if (event.target.files[0].type == "video/mp4") {
      setpreview("/images/upload-reel1.png");
    } else {
      setpreview(URL.createObjectURL(event.target.files[0]));
    }
    // console.log("event.target.files[0]=",event.target.files[0]);
    // reader.readAsDataURL(event.target.files[0]);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const validateFormData = async () => {
    try {
      const phoneRegex = /^[0-9]{10}$/;
      const alphabetRegex = /^[A-Za-z]+$/;

      if (formData.firstname === "") {
        errors.firstname = "Enter First name properly ";
      } else if (!alphabetRegex.test(formData.firstname)) {
        errors.firstname = "Enter First name with only alphabets";
      }

      if (formData.lastname === "") {
        errors.lastname = "Enter Last name properly";
      }
      if (formData.email === "") {
        errors.email = "Enter email id";
      } else if (!validator.isEmail(formData.email)) {
        errors.email = "Enter valid email id";
      }

      if (formData.phone === "") {
        errors.phone = "Enter phone number properly";
      } else if (!phoneRegex.test(formData.phone)) {
        errors.phone = "Phone number must be 10 digits";
      }

      if (formData.accountholder === "") {
        errors.accountholder = "Enter Accountholder name properly";
      } else if (!alphabetRegex.test(formData.accountholder)) {
        errors.accountholder =
          "Enter a valid accountholder name with only alphabets";
      }
      // if (formData.ifscCode === "") {
      //   errors.ifscCode = "Enter IFSC code properly";
      // }
      if (formData.image === "") {
        errors.image = "Please choose an image";
      }
      if (formData.title === "") {
        errors.title = "Enter Campaign title properly";
      }
      if (formData.shortDescription === "") {
        errors.shortDescription = "Enter description properly";
      }
      if (formData.accountnumber === "") {
        errors.accountnumber = "Enter Account number properly";
      }
      if (formData.campaigntarget === "") {
        errors.campaigntarget = "Enter campaign target properly";
      }

      if (formData.category === "") {
        errors.category = "Select Category";
      }

      if (checkterm == false) {
        errors.checkterm = "Read and Check term and conditions";
      }

      // if (file == null) {
      //   errors.image = "Please choose an image";
      // }
      setFormError(errors);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddCampaign = async (e) => {
    try {
      e.preventDefault();
      validateFormData();
      if (Object.keys(errors).length === 0) {
        setButtonLoader(true);
        asyncAddCampaign(
          formData,
          props.setShow,
          props.setIsLoader,
          setButtonLoader
        );
      } else {
        handleScrollError(errors);
      }
    } catch (error) {
      // errorMsg(error.response.data.message);
    }
  };

  // textfieldClass: {
  //   '& .MuiInput-input': {
  //    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
  //      '-webkit-appearance': 'none',
  //    },
  //   }
  //  },

  return (
    <div className="new-modal-Campaigns ">
      <Modal
        show={props.show}
        centered
        size="lg"
        className="main_popup_inner donate_popup_main addcampaigns_popup add-campaigns-modal"
        onHide={props.close}
      >
        <FontAwesomeIcon onClick={props.close} icon={faTimes} />

        <Modal.Header>
          <Modal.Title className="popup_title_name">
            Add New Campaigns
          </Modal.Title>
        </Modal.Header>
        <h1>Personal Info</h1>

        <div className="row main_popup_inner1 new-all-inputs">
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-3">
            <FormHelperText sx={{ color: "#fff", marginBottom: "5px" }}>
              First Name
            </FormHelperText>
            <TextField
              sx={{
                width: "100%",
              }}
              error={formErrors.firstname}
              name="firstname"
              className="input_username new-edit-main-all"
              id="outlined-basic"
              placeholder="Enter your first name"
              onChange={(e) => {
                handleChange(e);
              }}
              helperText={formErrors.firstname}
            />
          </div>

          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-3">
            <FormHelperText sx={{ color: "#fff", marginBottom: "5px" }}>
              Last Name
            </FormHelperText>
            <TextField
              sx={{
                width: "100%",
              }}
              error={formErrors.lastname}
              name="lastname"
              className="input_username new-edit-main-all"
              id="outlined-basic"
              placeholder="Enter your last name"
              onChange={(e) => handleChange(e)}
              helperText={formErrors.lastname}
            />
          </div>
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-3">
            <FormHelperText sx={{ color: "#fff", marginBottom: "5px" }}>
              Email Address
            </FormHelperText>
            <TextField
              sx={{
                width: "100%",
              }}
              error={formErrors.email}
              name="email"
              className="input_username new-edit-main-all"
              id="outlined-basic"
              placeholder="Enter your email address"
              onChange={handleChange}
              helperText={formErrors.email}
            />
          </div>
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-3">
            <FormHelperText sx={{ color: "#fff", marginBottom: "5px" }}>
              Phone Number
            </FormHelperText>
            <TextField
              // sx={{
              //   width: "100%",
              // }}
              sx={{
                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                  display: "none",
                },
                "& input[type=number]": {
                  MozAppearance: "textfield",
                },
                width: "100%",
              }}
              error={formErrors.phone}
              name="phone"
              className="input_username new-edit-main-all numberinput_arrow"
              id="outlined-basic"
              type="number"
              placeholder="Enter your phone number"
              onChange={handleChange}
              helperText={formErrors.phone}
            />
          </div>
        </div>

        <h1>Account Details</h1>
        <div className="row main_popup_inner1 new-all-inputs">
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-3">
            <FormHelperText sx={{ color: "#fff", marginBottom: "5px" }}>
              Account Holder Name
            </FormHelperText>
            <TextField
              sx={{
                width: "100%",
              }}
              error={formErrors.accountholder}
              name="accountholder"
              className="input_username new-edit-main-all"
              id="outlined-basic"
              placeholder="Enter account holder name"
              onChange={handleChange}
              helperText={formErrors.accountholder}
            />
          </div>

          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-3">
            <FormHelperText sx={{ color: "#fff", marginBottom: "5px" }}>
              Account Number
            </FormHelperText>
            <TextField
              sx={{
                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                  display: "none",
                },
                "& input[type=number]": {
                  MozAppearance: "textfield",
                },
                width: "100%",
              }}
              error={formErrors.accountnumber}
              name="accountnumber"
              className="input_username new-edit-main-all"
              id="outlined-basic"
              type="number"
              placeholder="Enter your account number"
              onChange={handleChange}
              helperText={formErrors.accountnumber}
            />
          </div>
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-3">
            <FormHelperText sx={{ color: "#fff", marginBottom: "5px" }}>
              IFSC Code (Optional)
            </FormHelperText>
            <TextField
              sx={{
                width: "100%",
              }}
              error={formErrors.ifscCode}
              name="ifscCode"
              className="input_username new-edit-main-all"
              id="outlined-basic"
              type="text"
              placeholder="Enter ifsc code"
              onChange={handleChange}
              helperText={formErrors.ifscCode}
            />
          </div>

          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-3">
            <div className="search_txteditor_category">
              <FormHelperText sx={{ color: "#fff", marginBottom: "5px" }}>
                Category
              </FormHelperText>
              <FormControl fullWidth>
                <Select
                  className="input_username new-edit-main-all"
                  id="demo-simple-select-helper-label"
                  label="category (required)"
                  name="category"
                  value={formData.category}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  MenuProps={MenuProps}
                  error={formErrors.category}
                  helperText={formErrors.category}
                >
                  {CampaignCategory &&
                    CampaignCategory.length > 0 &&
                    CampaignCategory.map((value, index) => {
                      return (
                        <MenuItem key={index} value={value.id}>
                          {value.name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
              {formErrors.category && (
                <div className="invalid-msg">{formErrors.category}</div>
              )}
            </div>
          </div>
        </div>

        <h1>Campaigns Info</h1>
        <div className="row main_popup_inner1 new-all-inputs">
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-3">
            <div className=" mb-3">
              <FormHelperText sx={{ color: "#fff", marginBottom: "5px" }}>
                Campaigns Title
              </FormHelperText>
              <TextField
                sx={{
                  width: "100%",
                }}
                error={formErrors.title}
                name="title"
                className="input_username new-edit-main-all"
                id="outlined-basic"
                placeholder="Enter Campaigns title"
                onChange={handleChange}
                helperText={formErrors.title}
              />
            </div>
            <div className=" mb-3">
              <FormHelperText sx={{ color: "#fff", marginBottom: "5px" }}>
                Target
              </FormHelperText>
              <TextField
                sx={{
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                    display: "none",
                  },
                  "& input[type=number]": {
                    MozAppearance: "textfield",
                  },
                  width: "100%",
                }}
                error={formErrors.campaigntarget}
                name="campaigntarget"
                className="input_username new-edit-main-all"
                id="outlined-basic"
                type="number"
                placeholder="Enter your target"
                onChange={handleChange}
                helperText={formErrors.campaigntarget}
              />
            </div>
          </div>

          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
            <FormHelperText sx={{ color: "#fff", marginBottom: "5px" }}>
              Sort description
            </FormHelperText>
            <TextField
              sx={{
                width: "100%",
              }}
              error={formErrors.shortDescription}
              name="shortDescription"
              multiline
              rows={5.3}
              className="input_username new-edit-main-all dddddddd"
              id="outlined-basic"
              placeholder="Enter your description"
              onChange={handleChange}
              helperText={formErrors.shortDescription}
            />
          </div>
        </div>

        <h1>UK Information</h1>
        <div className="row main_popup_inner1 new-all-inputs">
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-3">
            <FormHelperText sx={{ color: "#fff", marginBottom: "5px" }}>
              National Insurance Number
            </FormHelperText>
            <TextField
              sx={{
                width: "100%",
              }}
              // error={formErrors.title}
              name="national_insurance"
              className="input_username new-edit-main-all"
              id="outlined-basic"
              placeholder="Enter national insurance number"
              onChange={handleChange}
            // helperText={formErrors.title}
            />
          </div>
        </div>

        <div className="box_add_product">
          <div className="add_product_img">
            {
              // preview
            }{" "}
            <img src={preview} alt="" />
            {/* <img className="preview" src={Images} /> */}
          </div>
          <div className="add_product_text">
            <h4>Image Format jpg and png</h4>
          </div>

          <div className="file_upload_product">
            <input
              style={{ display: "none" }}
              id="fileInput"
              name="image"
              type="file"
              // inputProps={{ accept: "image/*" }}
              onChange={(e) => {
                handleChange(e);
                fileChangedHandler(e);
              }}
              placeholder="browser"
              multiple
              ref={inputRef}
            />
            <button onClick={handleClick}>Browse files</button>
          </div>
          <div className="image_error">
            <p> {formErrors.image} </p>
          </div>
        </div>

        <div className="term_condition_camp">
          <div className="all-input-filter">
            <input
              className="form-check-input"
              type="checkbox"
              name="checkterm"
              onChange={(e) => handleCheckTerm(e)}
              id={"terms"}
            />
            <label className="form-check-label" htmlFor={"terms"}></label>
            <p>
              {" "}
              By continuing you are Agreeing to Starbays Terms of Service and
              acknowledge receipt of out
              <span>
                <NavLink to={"/privacy-policy"} target="_blank">
                  {" "}
                  Privacy policy{" "}
                </NavLink>{" "}
              </span>
            </p>
          </div>

          <div className="check_termerror">
            <p> {formErrors.checkterm} </p>
          </div>
        </div>

        <div className="popup_donate_btn">
          {buttonloader == true ? (
            <button type="button">
              <SmallLoader
                type="spinner-default"
                bgColor={"#fff"}
                color={"#fff"}
                size={40}
              />
            </button>
          ) : (
            <button
              onClick={(e) => {
                handleAddCampaign(e);
              }}
            >
              Create Campaigns
            </button>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default AddCampaignsModal;
