import React from "react";


const WhatLearn = (props) => {
  const whatyou_learn = props.whatyou_learn;

  return (
    <>
      <div className="What_learn_main">
        <h1>What you'll learn</h1>

        <div className="What_learn_main_inner">
          <ul className="What_learn_main_inner-list">
            <div className="row">
              {whatyou_learn?.map(function (value, index) {
                return (
                  <div
                    className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                    key={index}
                  >
                    <li
                    // style={{
                    //   backgroundImage: `url(${value.image})`,
                    // }}
                    >
                      {" "}
                      {value.title}
                    </li>
                  </div>
                );
              })}
            </div>
          </ul>
        </div>
      </div>
    </>
  );
};

export default WhatLearn;
