import React, { useEffect, useState } from "react";
import RecordNotfound from "../Home/RecordNotfound";
import Loader from "../../hooks/Loader";

import axios from "../../axios";
import { errorMsg, successMsg } from "../../actions/customFn";

const tokenMapper = {
  "0x0000000000000000000000000000000000000000": "Matic",
  "0x94A47ad349d610143989aB82529a47Ed48d27530": "USDT",
};
export default function Royalty() {
  const [isLoader, setIsLoader] = useState(true);
  const [royaltyList, setRoyaltyList] = useState([]);

  useEffect(() => {
    getAlbumList();
  }, []);
  const getAlbumList = async () => {
    const token = localStorage.getItem("token");

    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`, // Use template literals correctly
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/users/me/tokens/get-royalty`,
        config
      );
      console.log(response);
      if (response.status === 200) {
        // setState(response.data.data);
        setRoyaltyList(response.data);
      } else {
        setRoyaltyList([]);
      }
      setTimeout(() => {
        setIsLoader(false);
      }, 1000);
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response.status !== undefined) {
          if (error.response.status === 401) {
            //   errorMsg("Somthing went wrong...");
          } else {
            console.log(`${error.message}-${error.response.status}`);
          }
        }
      }
    }
  };

  const handleSubmitClaim = async (data) => {
    const token = localStorage.getItem("token");

    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`, // Use template literals correctly
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/payout/generate`,
        data,
        config
      );
      console.log(response);
      if (response.status === 200) {
        successMsg("Royalty claim successfully");
      }
      setTimeout(() => {
        setIsLoader(false);
      }, 1000);
    } catch (error) {
      console.log("error", error);
      if (error.response !== undefined) {
        if (error.response.status === 500) {
          errorMsg(error.response.data.message);
        }
      }
    }
  };

  console.log(royaltyList);
  return (
    <div className="notification-page">
      <div className="top-heading p-2">
        <div className="row">
          <div className="col-xl-10 col-lg-9 col-md-8 col-sm-8 col-8 ">
            <h2>Royalty</h2>
          </div>
          <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-4"></div>
        </div>
      </div>

      <div className="product-table table-responsive">
        <table class="table ">
          <tr className="product-list-table ">
          
            <th>Wallet Address</th>
            <th>Token</th>
            <th>Amount</th>
            <th>Actions</th>
          </tr>
          {isLoader == true ? (
            <tr>
              <td colSpan={6}>
                <Loader />
              </td>
            </tr>
          ) : (
            <>
              {royaltyList != undefined && royaltyList.length > 0 ? (
                <>
                  {" "}
                  {royaltyList.map((value, index) => {
                    return (
                      <tr className="product_history_image" key={index}>
                       
                        <td>{value.payoutAddress}</td>
                        <td>{tokenMapper[value.paymentToken]}</td>
                        <td>{value.totalAmount}</td>
                        <td
                          className="main_popup_inner1 gap-5 d-flex"
                          style={{ paddingBottom: "20px" }}
                        >
                          <button
                            className="custom-btn"
                            onClick={() => handleSubmitClaim(value)}
                          >
                            Claim
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </>
              ) : (
                <tr>
                  <td colSpan={6}>
                    <div className="text-light">
                      <RecordNotfound />
                    </div>
                  </td>
                </tr>
              )}
            </>
          )}
        </table>
      </div>
    </div>
  );
}
