import axios from "../axios";
import { errorMsg, getArray, getappenFormData, successMsg } from "./customFn";

// const user_id = localStorage.getItem("star_uId");
// const user_password = localStorage.getItem("star_upass");

export const asyncAddProduct = async (
  formdata,
  objs,
  id,
  setState,
  setLoadPage
) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  try {
    const config = { headers: { "Content-Type": "multipart/form-data" } };
    let sendformData = new FormData();

    sendformData.append("customer_id", user_id);
    sendformData.append("password", user_password);

    sendformData.append("name", formdata.productname);
    sendformData.append("description", formdata.description);
    sendformData.append("category", formdata.category);
    sendformData.append("sub_category", formdata.subcategory);
    sendformData.append("quantity", formdata.quantity);
    sendformData.append("product_sku", formdata.productsku);
    sendformData.append("item_weight", formdata.weight);
    sendformData.append("length", formdata.lengths);
    sendformData.append("breath", formdata.breath);
    sendformData.append("width", formdata.width);
    sendformData.append("safty_care_instructions", formdata.safetyinstruction);
    sendformData.append("location", formdata.location);
    sendformData.append("warranty_guarante", formdata.waranty);
    sendformData.append("promo_code", formdata.promo_code);
    sendformData.append("promo_code_value", formdata.promocode_value);
    sendformData.append("type", formdata.promo_type);
    sendformData.append("compare_price", formdata.compare_price);

    // ---------
    sendformData.append("delivery_time", formdata.delivery_time);
    sendformData.append("shiping_id", formdata.shiping_id);
    sendformData.append("tax_id", formdata.tax_id);

    sendformData.append(
      "warranty_guarante_description",
      formdata.warantydescription
    );

    sendformData.append("price", formdata.price);
    sendformData = getappenFormData(
      sendformData,
      formdata.question,
      "question"
    );

    sendformData = getappenFormData(sendformData, formdata.answer, "answer");

    sendformData = getappenFormData(
      sendformData,
      formdata.specification,
      "specification"
    );

    // sendformData.append("answer", JSON.stringify(formdata.answer));
    // sendformData.append(
    //   "image_id",
    //   JSON.stringify(getArray(formdata.image, "id"))
    // );

    sendformData = getappenFormData(
      sendformData,
      getArray(formdata.image, "id"),
      "image_id"
    );

    // sendformData.append(
    //   "specification",
    //   JSON.stringify(formdata.specification)
    // );

    if (formdata.image.length > 0) {
      for (let i = 0; i < formdata.image.length; i++) {
        if (
          formdata.image[i] != undefined &&
          formdata.image[i].file != undefined
        ) {
          sendformData.append("product_image[]", formdata.image[i].file);
        }
      }
    }
    if (id != undefined && id != "") {
      sendformData.append("id", id);
    }
    // return false;
    const url = "/add/product";

    await axios.post(url, sendformData, config).then((response) => {
      if (response.data.status === true) {
        // successMsg(response.data.message);

        setTimeout(() => {
          objs.setButtonLoader(false);
          objs.navigate("/mystore");
        }, 1000);
      } else {
        errorMsg(response.data.message);
      }
    });
  } catch (error) {
    // console.log("error", error);
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          //   errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};

// -------------- my product ------------------
export const asyncgetMyProduct = async (
  setState,
  setIsLoader,
  setPagecount,
  currentPage,
  searchproduct
) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  try {
    let sendformData = new FormData();
    sendformData.append("customer_id", user_id);
    sendformData.append("password", user_password);

    if (searchproduct != undefined) {
      if (searchproduct.productname != "") {
        sendformData.append("product_name", searchproduct.productname);
      }
      if (searchproduct.product_category != "") {
        sendformData.append("category", searchproduct.product_category);
      }

      if (
        searchproduct.productname != undefined ||
        searchproduct.product_category != undefined
      ) {
        sendformData.append("offset", 0);
      } else {
        sendformData.append("offset", currentPage);
      }
    } else {
      sendformData.append("offset", currentPage);
    }

    // sendformData.append("offset", currentPage);

    const url = "/my_products";
    await axios.post(url, sendformData).then((response) => {
      if (response.data.status) {
        setState(response.data.product_list);
        setPagecount(response.data.page_count);
      } else {
        //errorMsg(response.data.message);
      }
    });
    setTimeout(() => {
      setIsLoader(false);
    }, 1000);
  } catch (error) {
    // console.log("error", error);
    setTimeout(() => {
      setIsLoader(false);
    }, 1000);
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          //   errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};

// --------- my product details -------------

export const getMyProductDetails = async (
  id,
  setState,
  setIsLoader,
  navigation
) => {
  setIsLoader(true);
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  try {
    let sendformData = new FormData();

    sendformData.append("customer_id", user_id);
    sendformData.append("password", user_password);

    sendformData.append("product_id", id);

    const url = "/my_product_details";

    await axios.post(url, sendformData).then((response) => {
      if (response.data.status == true) {
        setState(response.data.data);
      } else {
        errorMsg(response.data.message);
        navigation("/mystore");
      }
      setTimeout(() => {
        setIsLoader(false);
      }, 1000);
    });
  } catch (error) {
    setTimeout(() => {
      setIsLoader(false);
    }, 1000);
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          //   errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};

// ----------- all products --------------
export const asyncAllProductList = async (
  setState,
  setLoadPage,
  setPagecount,
  currentPage,
  setIsLoader,
  setHasMore,
  searchForm,
  searchproduct,
  setProductTotal
) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");

  var pname = "";
  var minprice = "";
  var maxprice = "";
  var name = "";
  var sort_product = "";

  var offpage = currentPage;

  if (searchproduct !== undefined) {
    if (
      searchproduct.search_name !== undefined &&
      searchproduct.search_name !== ""
    ) {
      pname = searchproduct.search_name;
      // offpage = 0;
    }
    if (
      searchproduct.sort_price !== undefined &&
      searchproduct.sort_price !== ""
    ) {
      sort_product = searchproduct.sort_price;
      // offpage = 0;
    }
  }
  if (searchForm !== undefined) {
    if (
      searchForm.category_name !== undefined &&
      searchForm.category_name !== ""
    ) {
      name = searchForm.category_name;
      // offpage = 0;
    }

    if (searchForm.min_price !== undefined && searchForm.min_price !== "") {
      minprice = searchForm.min_price;
      // offpage = 0;
    }

    if (searchForm.max_price !== undefined && searchForm.max_price !== "") {
      maxprice = searchForm.max_price;
      // offpage = 0;
    }
  }

  try {
    let params = {
      offset: offpage,
      customer_id: user_id,
      password: user_password,
      // category: name,
      // min_price: minprice,
      // max_price: maxprice,
      // product_name: pname,
      // sort: sort_product,
    };

    if (name !== "") {
      params.category = name;
    }

    if (minprice !== "") {
      params.min_price = minprice;
    }

    if (maxprice !== "") {
      params.max_price = maxprice;
    }

    if (pname !== "") {
      params.product_name = pname;
    }

    if (sort_product !== "") {
      params.sort = sort_product;
    }
    // if (offpage == 0) {
    //   setIsLoader(true);
    // }

    const url = "/all_products";
    await axios.post(url, params).then((response) => {

      if (response.data.status == true) {
        if (setProductTotal != undefined) {
          setProductTotal(response.data.total_product)
        }
        if (response.data.product_list.length > 0) {
          console.log("response.data.product_list.length", response.data.product_list.length)

          if (offpage === 0) {
            setState(response.data.product_list);
          } else {
            setState((prevState) => [
              ...prevState,
              ...response.data.product_list,
            ]);
          }
          setPagecount(response.data.page_count);
          setHasMore(true);

        } else {
          setHasMore(false);
        }
      } else {
        setHasMore(false);
        setState([]);
      }


      setTimeout(() => {
        setIsLoader(false);
      }, 1000);


    });
  } catch (error) {
    setTimeout(() => {
      setLoadPage(false);
    }, 1000);
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};

// ------------- delete my product -------------
export const asyncDeleteMyProduct = async (id, setLoadPage, reRender) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  try {
    let sendformData = new FormData();

    sendformData.append("customer_id", user_id);
    sendformData.append("password", user_password);

    sendformData.append("product_id", id);

    const url = "/delete/product";

    await axios.post(url, sendformData).then((response) => {
      if (response.data.status === true) {
        reRender.isRerender(!reRender.setisRerender);
        // successMsg(response.data.message);
        //   setLoadPage(true);
      } else {
        errorMsg(response.data.message);
      }
    });
  } catch (error) {
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          //   errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};

// ------------- update my product -------------
// export const asyncUpdateMyProduct = async ( id,
//   formdata,
//   navigate,
//   setLoadPage
// ) => {
//   try {
//     const config = { headers: { 'Content-Type': 'multipart/form-data' } };
//     let sendformData = new FormData();

//     sendformData.append("customer_id", user_id);
//     sendformData.append("password", user_password);

//     sendformData.append("name", formdata.productname);
//     sendformData.append("description", formdata.description);
//     sendformData.append("category", formdata.category);
//     sendformData.append("sub_category", formdata.subcategory);
//     sendformData.append("quantity", formdata.quantity);
//     sendformData.append("product_sku", formdata.productsku);
//     sendformData.append("item_weight", formdata.weight);
//     sendformData.append("length", formdata.lengths);
//     sendformData.append("breath", formdata.breath);
//     sendformData.append("width", formdata.width);
//     sendformData.append("safty_care_instructions", formdata.safetyinstruction);
//     sendformData.append("location", formdata.location);
//     sendformData.append("warranty_guarante", formdata.waranty);
//     sendformData.append(
//       "warranty_guarante_description",
//       formdata.warantydescription
//     );

//     console.log("formdata.question", formdata.question);
//     sendformData.append("price", formdata.price);
//     // sendformData.append("product_image", formdata.image[0]);
//     sendformData.append("question", JSON.stringify(formdata.question));
//     sendformData.append("answer", JSON.stringify(formdata.answer));

//     if(formdata.image.length > 0){
//       for (let i = 0; i < formdata.image.length; i++) {
//         sendformData.append('product_image[]', formdata.image[i].file);
//       }
//     }
//     // formdata.image.forEach(file=>{
//     //   sendformData.append("product_image[]", file);
//     // });
//     sendformData.append("product_id", id);
//     // console.log("formdata", formdata);
//     const url = "/update/product";

//     await axios.post(url, sendformData,config).then((response) => {
//       if (response.data.status === true) {
//         successMsg(response.data.message);
//       } else {
//         errorMsg(response.data.message);
//       }
//     });
//     // setTimeout(() => {
//     //   setLoadPage(false);
//     // }, 1000);
//   } catch (error) {
//     if (error.response !== undefined) {
//       if (error.response.status !== undefined) {
//         if (error.response.status === 401) {
//           //   errorMsg("Somthing went wrong...");
//         } else {
//           console.log(`${error.message}-${error.response.status}`);
//         }
//       }
//     }
//   }
// };

// ------------ product categories ----------

// ------------- update my product -------------
export const getProductCategory = async (setState, setLoadPage) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  try {
    let sendformData = new FormData();

    sendformData.append("customer_id", user_id);
    sendformData.append("password", user_password);

    const url = "/product_categories";

    await axios.post(url, sendformData).then((response) => {
      if (response.data.status === true) {
        setState(response.data.data);
      } else {
        errorMsg(response.data.message);
      }
    });
  } catch (error) {
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          //   errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};

// ------------ product categories ----------
export const getMyProductSubCategory = async (setState, id) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  try {
    let sendformData = new FormData();

    sendformData.append("customer_id", user_id);
    sendformData.append("password", user_password);

    sendformData.append("category_id", id);

    const url = "/product_sub_categories";

    await axios.post(url, sendformData).then((response) => {
      if (response.data.status === true) {
        setState(response.data.data);
      } else {
        errorMsg(response.data.message);
      }
    });
  } catch (error) {
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          //   errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};

// ---------  product details -------------

export const getProductDetailsAll = async (id, setState, setIsLoader) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  try {
    const formData = {
      customer_id: user_id,
      password: user_password,
      product_id: id,
    };
    const url = "/product_details";
    await axios.post(url, formData).then((response) => {
      if (response.data.status === true) {
        setState(response.data.data);
      } else {
      }
      setTimeout(() => {
        setIsLoader(false);
      }, 1000);
    });
  } catch (error) {
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          //   errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};

export const asyncProductRating = async (formdata, close, NewRatingList) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  try {
    const formData = {
      customer_id: user_id,
      password: user_password,
      product_id: formdata.product_id,
      rating: formdata.rating,
      description: formdata.description,
    };
    const url = "/product_ratings";
    await axios.post(url, formData).then((response) => {
      if (response.data.status === true) {
        successMsg(response.data.message);
        close();
        NewRatingList((prev) => (prev === 0 ? 1 : 0));
      } else {
        errorMsg(response.data.message);
      }
    });
  } catch (error) {
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};

// -------- add product wishlist --------
export const asyncAddProductWishlist = async (prod_id, isWishlist) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  try {
    const formData = {
      customer_id: user_id,
      password: user_password,
      product_id: prod_id,
    };
    const url = "/product_wishlist";
    await axios.post(url, formData).then((response) => {
      if (response.data.status === true) {
        successMsg(response.data.message);
        isWishlist.setAddWishlist(!isWishlist.addwishlist);
      } else {
        errorMsg(response.data.message);
      }
    });
  } catch (error) {
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};

// --------- wishlist product -----------
export const asyncGetProductWishlist = async (
  setWishList,
  setPagecount,
  currentPage,
  setIsLoader
) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  try {
    const formData = {
      customer_id: user_id,
      password: user_password,
      // offset:currentPage
    };
    const url = "/my_product_wishlist";
    await axios.post(url, formData).then((response) => {
      if (response.data.status === true) {
        setIsLoader(false);
        setWishList(response.data.data);
        setPagecount(response.data.page_count);
      } else {
        errorMsg(response.data.message);
      }
    });
  } catch (error) {
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};

// ------------- delete wishlist product -------------
export const asyncDeleteProductWishlist = async (id) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  try {
    let sendformData = new FormData();
    sendformData.append("customer_id", user_id);
    sendformData.append("password", user_password);
    sendformData.append("product_id", id);
    const url = "/delete_product_wishlist";
    const response = await axios.post(url, sendformData);
    if (response.data != undefined) {
      return response.data;
    } else {
      errorMsg("Somthing went wrong...");
    }
  } catch (error) {
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          //   errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};
// ------------- delete wishlist product -------------



// ---------------- shiping input data -----------
export const getShipingInputData = async (setState) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  try {
    let sendformData = new FormData();

    sendformData.append("customer_id", user_id);
    sendformData.append("password", user_password);

    const url = "/get_shiping_list";

    await axios.post(url, sendformData).then((response) => {
      if (response.data.status === true) {
        setState(response.data.data);
      } else {
        errorMsg(response.data.message);
      }
    });
  } catch (error) {
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          //   errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};

// ---------------- tax input data -----------
export const getTaxInputData = async (setState) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  try {
    let sendformData = new FormData();

    sendformData.append("customer_id", user_id);
    sendformData.append("password", user_password);

    const url = "/get_tax_list";

    await axios.post(url, sendformData).then((response) => {
      if (response.data.status === true) {
        setState(response.data.data);
      } else {
        errorMsg(response.data.message);
      }
    });
  } catch (error) {
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          //   errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};
