import React, { useEffect, useRef, useState } from "react";
import {
    errorMsg,
    handleScrollError,
    images,
    validateField,
} from "../../actions/customFn";
import { FormHelperText, TextField } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/fontawesome-free-solid";

import "quill/dist/quill.snow.css";
import SmallLoader from "react-js-loader";
import { asyncUpdateAlbum } from "../../actions/uploadBlogs";
// import validator from "validator";

const UpdateAlbumModal = (props) => {
    const { setShow, setUploadLoad, close, data } = props;

    const inputRef = useRef(null);
    const [preview, setpreview] = useState("/images/upload-post1.png");

    const [editorType, setEditorType] = useState("");
    const [formErrors, setFormError] = useState({});
    const errors = {};
    const [buttonloader, setButtonLoader] = useState(false);
    // const navigate = useNavigate();

    const [formData, setFormData] = useState({
        name: "",
        description: "",
        image: "",
    });


    useEffect(() => {
        if (data) {
            setFormData({ ...data })
            setpreview(data.image || '/images/upload-post1.png')
        }

    }, [data])


    const handleClick = () => {
        inputRef.current.click();
    };

    const handleChange = (e) => {
        if (e.target !== undefined) {
            const { name, value } = e.target;
            if (name === "image") {
                setFormData((formData) => ({ ...formData, [name]: e.target.files[0] }));
            } else {
                setFormData((formData) => ({ ...formData, [name]: value }));
            }
        } else {
            if (editorType != "") {
                setFormData((formData) => ({ ...formData, [editorType]: e }));
            }
        }
    };

    const validateFormData = async () => {
        try {
            if (formData.name === "") {
                errors.name = "Enter post name properly ";
            }
            if (formData.description === "") {
                errors.description = "Enter description";
            }
            // if (formData.description == "<p><br></p>") {
            //   errors.description = "Enter description";
            // }
            if (formData.image === "" || formData.image === undefined) {
                errors.image = "Please choose an image";
            }
            setFormError(errors);
        } catch (error) {
            console.log(error);
        }
    };

    const handleUploadPost = async (e) => {
        setButtonLoader(true);
        try {
            e.preventDefault();
            validateFormData();
            if (Object.keys(errors).length === 0) {
                asyncUpdateAlbum(
                    formData,
                    setShow,
                    setUploadLoad,
                    setButtonLoader,
                );
            } else {
                setButtonLoader(false);
                handleScrollError(errors);
            }
        } catch (error) {
            setButtonLoader(false);
            errorMsg("something went wrong!");
        }
    };

    // const refreshPage =()=>{
    //   setTogglePage(!togglepage);
    // }



    const fileChangedHandler = (event) => {
        setpreview(URL.createObjectURL(event.target.files[0]));
    };

    console.log('formData', formData)

    return (
        <Modal
            show={props.show}
            className="upload_post_popup post-upload-modal"
            onHide={props.close}
        >
            <FontAwesomeIcon onClick={props.close} icon={faTimes} />

            <Modal.Header>
                <h4>Update Album</h4>
            </Modal.Header>

            <div className="uploadpost_cont new-all-inputs">
                <div className="post_image_upload">
                    <img src={preview} alt="" />
                    <h6>Upload Album Image</h6>

                    <div className="file_upload_product">
                        <input
                            style={{ display: "none" }}
                            id="fileInput"
                            name="image"
                            type="file"
                            accept="image/*"
                            onChange={(e) => {
                                handleChange(e);
                                fileChangedHandler(e);
                            }}
                            placeholder="browser"
                            // multiple
                            ref={inputRef}
                        />

                        <button
                            onClick={() => {
                                handleClick();
                            }}
                        >
                            Browse files
                        </button>
                    </div>
                    {formErrors.image && (
                        <div className="image_error">
                            <p> {formErrors.image} </p>
                        </div>
                    )}
                </div>

                <div className=" main_popup_inner1 mb-3">
                    <FormHelperText sx={{ color: "#fff", marginBottom: "5px" }}>
                        Album Name
                    </FormHelperText>
                    <TextField
                        sx={{
                            width: "100%",
                        }}
                        error={formErrors.name}
                        name="name"
                        className="input_username new-edit-main-all"
                        id="outlined-basic"
                        placeholder="Enter Album name"
                        onChange={handleChange}
                        value={formData.name}
                        helperText={formErrors.name}
                    />
                </div>
                <div className="main_popup_inner1">
                    <FormHelperText sx={{ color: "#fff", marginBottom: "5px" }}>
                        Description
                    </FormHelperText>
                    <TextField
                        sx={{
                            width: "100%",
                        }}
                        error={formErrors.description}
                        name="description"
                        className="input_username new-edit-main-all"
                        id="outlined-basic"
                        placeholder="Enter text"
                        onChange={handleChange}
                        multiline
                        rows={4}
                        value={formData.description}
                        helperText={formErrors.description}
                    />
                </div>

                <div className="upload_btn_last">
                    {buttonloader == true ? (
                        <button type="button">
                            <SmallLoader
                                type="spinner-default"
                                bgColor={"#fff"}
                                color={"#fff"}
                                size={40}
                            />
                        </button>
                    ) : (
                        <button
                            onClick={(e) => {
                                handleUploadPost(e);
                            }}
                        >
                            Update{" "}
                        </button>
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default UpdateAlbumModal;
