import axios from "../axios";
import { errorMsg, successMsg } from "./customFn";

// const user_id = localStorage.getItem("star_uId");
// const user_password = localStorage.getItem("star_upass");

export const asyncUploadBlog = async (
  formdata,
  setShow,
  setIsLoader,
  setButtonLoader,
  isRenderState
) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  try {
    const formData = new FormData();
    formData.append("image", formdata.image);
    formData.append("thumbnail", formdata.thumbnail);
    formData.append("customer_id", user_id);
    formData.append("password", user_password);
    formData.append("title", formdata.title);
    formData.append("description", formdata.description);
    formData.append("type", formdata.type);
    formData.append("genre", formdata.genre);
    formData.append("album_id", formdata.album_id);
    const url = "/upload/blog";
    await axios.post(url, formData).then((response) => {
      if (response.data.status === true) {
        // setState(response.data);
        successMsg(response.data.message);
        localStorage.setItem("post_id", response.data.data.id);
        setTimeout(() => {
          setButtonLoader(false);
          setShow(false);
          setIsLoader(true);
        }, 1000);
        isRenderState.setIsRender(!isRenderState.isRender);
        // setLoadPage(true);
      } else {
        errorMsg(response.data.message);
      }
    });
    setTimeout(() => {
      setIsLoader(false);
    }, 1000);
  } catch (error) {
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          //   errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};

export const getMyPostList = async (
  setState,
  setIsLoader,
  currentPage,
  setHasMore,
  setBlogcount
) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  try {
    const params = {
      customer_id: user_id,
      password: user_password,
      offset: currentPage,
    };
    const response = await axios.post("/my_blogs", params);
    if (response.data.status === true) {
      // setState(response.data.data);
      if (response.data.data.length > 0) {
        setState((prevState) => [...prevState, ...response.data.data]);
        setBlogcount(response.data.count);
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    } else {
      setState([]);
    }
    setTimeout(() => {
      setIsLoader(false);
    }, 1000);
  } catch (error) {
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          //   errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};

export const getAllPost = async (
  setState,
  setPagecount,
  currentPage,
  setIsLoader,
  setHasMore
) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  try {
    const params = {
      customer_id: user_id,
      password: user_password,
      offset: currentPage,
    };
    const response = await axios.post("/all_blogs", params);
    if (response.data.status) {
      if (response.data.data.length > 0) {
        setState((prevState) => [...prevState, ...response.data.data]);
        setPagecount(response.data.page_count);
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    } else {
      setHasMore(false);
      setState([]);
    }
    setTimeout(() => {
      setIsLoader(false);
    }, 1000);
  } catch (error) {
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          //   errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};

// ------ like post -----
export const asyncdoPostLike = async (post_id) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  try {
    const params = {
      customer_id: user_id,
      password: user_password,
      blog_id: post_id,
    };
    const response = await axios.post("/blog_like", params);
    if (response.data.status === true) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          //   errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};

//Freind Detail
// Freind POst

export const getFreindPostList = async (
  setState,
  setIsLoader,
  currentPage,
  setHasMore,
  user_id
) => {
  const customer_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  try {
    const params = {
      customer_id: customer_id,
      user_id: user_id,
      password: user_password,
      offset: currentPage,
    };
    const response = await axios.post("/get_user_blogs_list", params);
    if (response.data.status === true) {
      // setState(response.data.data);
      if (response.data.data.length > 0) {
        setState((prevState) => [...prevState, ...response.data.data]);
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    } else {
      setState([]);
    }
    setTimeout(() => {
      setIsLoader(false);
    }, 1000);
  } catch (error) {
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          //   errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};

// ------ friend profile -----
export const asyncgetFriendProfile = async (id, setFriendProfile) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  try {
    const params = {
      customer_id: user_id,
      password: user_password,
      user_id: id,
    };
    const response = await axios.post("/get_user_freind_profile", params);
    if (response.data.status === true) {
      setFriendProfile(response.data.data);

      return true;
    } else {
      // return false;
    }
  } catch (error) {
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          //   errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};

// ------------- delete my post ---------
export const asyncDeleteMyPost = async (id) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  try {
    let sendformData = new FormData();
    sendformData.append("customer_id", user_id);
    sendformData.append("password", user_password);
    sendformData.append("blog_id", id);
    const url = "/delete_blog";

    const response = await axios.post(url, sendformData);
    if (response.data.status === true) {
      successMsg(response.data.message);
      // reRender.setIsRender(!reRender.isRerender);
      // setCurrentPage(0);
      //   setLoadPage(true);
      return true;
    } else {
      errorMsg(response.data.message);
      return false;
    }
    // });
  } catch (error) {
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          //   errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};

// ------------- confirmation popup modal ---------
// export const asyncUpdateStatus = async (royalties) => {
//   const api_url = process.env.REACT_APP_API_URL;
//   const url = "/users/me/tokens";
//   const post_id = localStorage.getItem("post_id");
//   const token = localStorage.getItem("token");
//   const config = {
//     headers: {
//       Accept: "application/json",
//       Authorization: `Bearer ${token}`,
//       "Content-Type": "application/json",
//     },
//   };
//   let data = {
//     entityId: post_id,
//   };
//   if (royalties.length > 0) {
//     data.royalty = royalties;
//   }
//   console.log(data, "royalty");
//   try {
//     await axios.post(api_url + url, data, config).then((response) => {
//       if (response.status === 200 || response.status === 201) {
//         // successMsg('Payment Selected Successfully');
//         console.log(response.data, "data");
//         localStorage.removeItem("post_id");
//         localStorage.setItem("tokenAddress", JSON.stringify(response.data));
//         return response.data;
//         // setShow(false);
//       } else {
//         errorMsg(response.data.message);
//         return null;
//       }
//     });
//   } catch (error) {
//     if (error.response !== undefined) {
//       if (error.response.status !== undefined) {
//         if (error.response.status === 401) {
//           return null;
//         } else {
//           console.log(`${error.message}-${error.response.status}`);
//           return null;
//         }
//       }
//     }
//   }
// };
export const asyncUpdateStatus = async (royalties) => {
  const api_url = process.env.REACT_APP_API_URL;
  const url = "/users/me/tokens";
  const post_id = localStorage.getItem("post_id");
  const token = localStorage.getItem("token");

  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`, // Use template literals correctly
      "Content-Type": "application/json",
    },
  };

  let data = {
    entityId: post_id,
  };

  if (royalties.length > 0) {
    data.royalty = royalties;
  }

  console.log(data, "royalty");

  try {
    const response = await axios.post(api_url + url, data, config);

    if (response.status === 200 || response.status === 201) {
      localStorage.removeItem("post_id");
      return response.data; // Return the data directly
    } else {
      errorMsg(response.data.message);
      return null;
    }
  } catch (error) {
    if (error.response && error.response.status) {
      if (error.response.status === 401) {
        return null;
      } else {
        console.log(`${error.message}-${error.response.status}`);
        return null;
      }
    } else {
      console.log(error.message);
      return null;
    }
  }
};

// ------------- postMint popup modal ---------
export const asyncPostMint = async (data, setShow) => {
  const api_url = process.env.REACT_APP_API_URL;
  const url = "/users/me/orders";
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  try {
    await axios.post(api_url + url, data, config).then((response) => {
      if (response.status === 200 || response.status === 201) {
        successMsg("Successfully Created");
        localStorage.removeItem("tokenAddress");
        localStorage.removeItem("isMint");
        setShow(false);
      } else {
        errorMsg(response.data.message);
      }
    });
  } catch (error) {
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};

//............create albume-------------

export const asyncUploadAlbum = async (
  title,
  setAlbumButtonLoader,
  isRenderState,
  setAlbumList,
  setShowCreateAlbum
) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  try {
    const formData = new FormData();
    formData.append("customer_id", user_id);
    formData.append("password", user_password);
    formData.append("title", title);

    const url = "/create-album";
    await axios.post(url, formData).then((response) => {
      if (response.data.status === true) {
        // setState(response.data);
        successMsg(response.data.message);
        setAlbumList((prev) => [...prev, response.data.data]);
        setTimeout(() => {
          setAlbumButtonLoader(false);
        }, 1000);
        isRenderState.setIsRender(!isRenderState.isRender);
        setShowCreateAlbum(false);
        // setLoadPage(true);
      } else {
        errorMsg(response.data.message);
        setAlbumButtonLoader(false);
      }
    });
    setTimeout(() => {
      setAlbumButtonLoader(false);
    }, 1000);
  } catch (error) {
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          //   errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};

export const asyncUpdateAlbum = async (
  form,
  setShow,
  setUploadLoad,
  setButtonLoader
) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  try {
    const formData = new FormData();
    formData.append("customer_id", user_id);
    formData.append("password", user_password);
    formData.append("name", form.name);
    formData.append("description", form.description);
    formData.append("image", form.image);
    formData.append("id", form.id);

    const url = "/update-album";
    await axios.post(url, formData).then((response) => {
      if (response.data.status === true) {
        // setState(response.data);
        successMsg(response.data.message);
        setTimeout(() => {
          setButtonLoader(false);
        }, 1000);
        setUploadLoad(false);
        setShow(false);
        // setLoadPage(true);
      } else {
        errorMsg(response.data.message);
        setButtonLoader(false);
      }
    });
    setTimeout(() => {
      setButtonLoader(false);
    }, 1000);
  } catch (error) {
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          //   errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};

export const getUserAlbumList = async (setState, setIsLoader) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  try {
    const params = {
      customer_id: user_id,
      password: user_password,
    };
    const response = await axios.post("/user-album", params);
    if (response.data.status === true) {
      // setState(response.data.data);
      setState(response.data.data);
    } else {
      setState([]);
    }
    setTimeout(() => {
      setIsLoader(false);
    }, 1000);
  } catch (error) {
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          //   errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};

export const getFriendUserAlbumList = async (id, setState, setIsLoader) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  try {
    const params = {
      customer_id: user_id,
      password: user_password,
      user_id: id,
    };
    const response = await axios.post("/friend-album", params);
    if (response.data.status === true) {
      // setState(response.data.data);
      setState(response.data.data);
    } else {
      setState([]);
    }
    setTimeout(() => {
      setIsLoader(false);
    }, 1000);
  } catch (error) {
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          //   errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};

export const asyncPostPlay = async (post_id) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  try {
    const params = {
      customer_id: user_id,
      password: user_password,
      blog_id: post_id,
    };
    const response = await axios.post("/update-play-count", params);
    if (response.data.status === true) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          //   errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};
