import axios from "../axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { errorMsg, successMsg } from "./customFn";
import { setIsLogout } from "../redux/authSlice";
import { getAuthData } from "../utils/apiHandlers";

// const user_id = localStorage.getItem("star_uId");
// const user_password = localStorage.getItem("star_upass");

export const userLogin = createAsyncThunk(
  "auth/login",
  async ({ formData, navigate }, { rejectWithValue }) => {
    const user_id = localStorage.getItem("star_uId");
    const user_password = localStorage.getItem("star_upass");
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.post("/login", formData);
      if (data.status) {
        localStorage.setItem("star_uId", data.user_id);
        localStorage.setItem("star_upass", data.password);
        localStorage.setItem("token", data.token);
        successMsg(data.message);
        navigate("/");
        window.location.reload();
        return data;
      } else {
        errorMsg(data.message);
        return rejectWithValue(data.message);
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

//Social login
export const userSocialLogin = createAsyncThunk(
  "auth/login",
  async ({ formData, navigate }, { rejectWithValue }) => {
    const user_id = localStorage.getItem("star_uId");
    const user_password = localStorage.getItem("star_upass");
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.post("/social_login", formData);
      if (data.status) {
        localStorage.setItem("star_uId", data.user_id);
        localStorage.setItem("star_upass", data.password);
        successMsg(data.message);
        navigate("/");
        return data;
      } else {
        errorMsg(data.message);
        return rejectWithValue(data.message);
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const userSignup = createAsyncThunk(
  "auth/signup",
  async ({ signupForm, navigate }, { rejectWithValue }) => {
    const user_id = localStorage.getItem("star_uId");
    const user_password = localStorage.getItem("star_upass");
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.post("/signup", signupForm);
      if (data.status) {
        successMsg(data.message);
        navigate("/account-activation");
        return data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        errorMsg(error.response.data.message);
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getUserDetail = createAsyncThunk(
  "auth/user_details",
  async ({ dispatch, navigate }, { rejectWithValue }) => {
    const user_id = localStorage.getItem("star_uId");
    const user_password = localStorage.getItem("star_upass");
    let sendformData = {};
    sendformData.customer_id = localStorage.getItem("star_uId");
    sendformData.password = localStorage.getItem("star_upass");
    if (
      sendformData.customer_id != null &&
      sendformData.customer_id != undefined
    ) {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const { data } = await axios.post("/user_details", sendformData);
        if (data.status) {
          return data.data;
        } else {
          return rejectWithValue(data.message);
        }
      } catch (error) {
        if (error.response && error.response.data.message) {
          if (error.response.data.message == 401) {
            dispatch(setIsLogout());
            navigate("/login");
          }

          return rejectWithValue(error.response.data.message);
        } else {
          return rejectWithValue(error.message);
        }
      }
    } else {
      rejectWithValue("customer required");
    }
  }
);

export const getWalletAddress = createAsyncThunk(
  'user/getWalletAddress',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await getAuthData("/users/me/wallet");
      if (response?.status === 201 || response?.status === 200) {
        return response.data;
      } else if (response?.status === 401 || response?.status === 400) {
        if (response.data.message === "Unauthorized") {
          dispatch(setIsLogout());
          return rejectWithValue("Unauthorized");
        }
      }
    } catch (e) {
      console.error(e);
      return rejectWithValue(e.message);
    }
  }
);
// Account Activate
export const asyncActivateAccount = async (AccountData, navigate, setState) => {
  try {
    const formData = {
      account_key: AccountData.account_key,
    };

    const url = "/activate-account";
    await axios.post(url, formData).then((response) => {
      if (response.data.status == true) {
        successMsg(response.data.message);
        navigate("/login");
      } else {
        errorMsg(response.data.message);
      }
    });
  } catch (error) {
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          //   errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};

// Forget Password
export const asyncforgetpassword = async (email, navigate, setState) => {
  try {
    const formData = {
      email: email,
    };

    const url = "/forgot-password";

    await axios.post(url, formData).then((response) => {
      if (response.data.status == true) {
        successMsg(response.data.message);
        navigate("/verifyotp", { state: { email: email } });
      } else {
        errorMsg(response.data.message);
      }
    });
  } catch (error) {
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};

// verify Otp
export const asyncverifyotp = async (otp, email, navigate, setState) => {
  try {
    const formData = {
      otp: otp,
      email: email,
    };
    const url = "/forgot_verify_otp";
    await axios.post(url, formData).then((response) => {
      if (response.data.status == true) {
        successMsg(response.data.message);
        localStorage.setItem("f_email", response.data.email);
        localStorage.setItem("f_user_id", response.data.user_id);
        navigate("/reset-password", { state: { email: email } });
      } else {
        errorMsg(response.data.message);
      }
    });
  } catch (error) {
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        errorMsg("Somthing went wrong...");
      }
    }
  }
};

// Change Password
export const asyncChangepassword = async (formdata, close) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  try {
    const formData = {
      customer_id: user_id,
      password: user_password,
      current_password: formdata.current_password,
      new_password: formdata.new_password,
      confirm_password: formdata.confirm_password,
    };
    const url = "/change_password";
    await axios.post(url, formData).then((response) => {
      if (response.data.status) {
        localStorage.setItem("star_upass", response.data.password);
        successMsg(response.data.message);
        close();
      } else {
        errorMsg(response.data.message);
      }
    });
  } catch (error) {
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          //   errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};

export const asyncResetPassword = async (formdata, navigate) => {
  try {
    var email = localStorage.getItem("f_email");
    var userID = localStorage.getItem("f_user_id");

    const formData = {
      email: email,
      user_id: userID,
      confirm_password: formdata.confirm_password,
      password: formdata.password,
    };

    const url = "/reset-password";
    await axios.post(url, formData).then((response) => {
      if (response.data.status) {
        successMsg(response.data.message);
        navigate("/login");
      } else {
        errorMsg(response.data.message);
      }
    });
  } catch (error) {
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          //   errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};

//Update User Detail
//Update User Detail

export const asyncUpdateProfile = async (
  formdata,
  dispatch,
  setButtonLoader
) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  try {
    let sendformData = new FormData();

    sendformData.append("customer_id", user_id);
    sendformData.append("password", user_password);

    sendformData.append("username", formdata.username);

    sendformData.append("first_name", formdata.firstname);
    sendformData.append("last_name", formdata.lastname);
    sendformData.append("address", formdata.address);
    sendformData.append("phone_number", formdata.phone);

    sendformData.append("country", formdata.country);
    sendformData.append("state", formdata.state);
    sendformData.append("city", formdata.city);
    sendformData.append("street_address", formdata.street);

    sendformData.append("dob", formdata.dob);
    sendformData.append("short_info", formdata.short_info);
    sendformData.append("bio_info", formdata.bio_info);

    sendformData.append("image", formdata.image);
    sendformData.append("myai_image", formdata.myai_image);

    const url = "/update_profile";

    await axios.post(url, sendformData).then((response) => {
      if (response.data.status === true) {
        successMsg(response.data.message);
        // --------
        // setTimeout(()=>{
        //   setButtonLoader(false);
        dispatch(getUserDetail({ dispatch }));
        // },1000);
      } else {
        errorMsg(response.data.message);
      }
    });
  } catch (error) {
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          //   errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};
export const asyncAddUpdateProfile = async (form, dispatch) => {
  try {
    let sendformData = new FormData();

    sendformData.append("address", form.tokenAddress);
    const api_url = process.env.REACT_APP_API_URL;
    const url = "/users/me/wallet";
    const token = localStorage.getItem("token");

    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    await axios.post(api_url + url, sendformData, config).then((response) => {
      console.log(response, "response");
      if (response.data.status) {
        successMsg(response.statusText);
      } else {
        errorMsg(response.data.message);
      }
    });
  } catch (error) {
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};

//Update Personal Detail
export const asyncUpdatePersonalInfo = async (
  formdata,
  setIsLoad,
  navigate,
  dispatch,
  setButtonLoader
) => {
  const user_id = localStorage.getItem("star_uId");
  const user_password = localStorage.getItem("star_upass");
  try {
    let sendformData = new FormData();

    sendformData.append("customer_id", user_id);
    sendformData.append("password", user_password);

    sendformData.append("work", formdata.work);

    sendformData.append("study", formdata.study);
    sendformData.append("gender", formdata.gender);
    sendformData.append("skills", formdata.skills);
    sendformData.append("about_me", formdata.aboutme);

    const url = "/add_personal_info";

    await axios.post(url, sendformData).then((response) => {
      if (response.data.status) {
        successMsg(response.data.message);
        setTimeout(() => {
          setButtonLoader(false);
          dispatch(getUserDetail({ dispatch, navigate }));
          navigate("/profile");
        }, 1000);
        // setLoadPage(true);
      } else {
        errorMsg(response.data.message);
      }
    });
  } catch (error) {
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        if (error.response.status === 401) {
          //   errorMsg("Somthing went wrong...");
        } else {
          console.log(`${error.message}-${error.response.status}`);
        }
      }
    }
  }
};

// ---------------- get website data ------------

export const getPageSettingData = createAsyncThunk(
  "auth/pagedetail",
  async ({ dispatch, navigate }, { rejectWithValue }) => {
    // const user_id = localStorage.getItem("star_uId");
    // const user_password = localStorage.getItem("star_upass");
    let sendformData = {};
    sendformData.customer_id = localStorage.getItem("star_uId");
    sendformData.password = localStorage.getItem("star_upass");

    // if (
    //   sendformData.customer_id != null &&
    //   sendformData.customer_id != undefined
    // ) {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.post("/pagedetail", sendformData);
      if (data.status) {
        return data.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        if (error.response.data.message == 401) {
          dispatch(setIsLogout());
          navigate("/login");
        }

        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
    // } else {
    //   rejectWithValue("customer required");
    // }
  }
);
