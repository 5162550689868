import React, { useEffect } from "react";
import { useState } from "react";
import { asyncdoPostLike, asyncPostPlay, getFreindPostList } from "../../actions/uploadBlogs";
import UploadPostModal from "../Modal/UploadPostModal";
import { motion } from "framer-motion";
import Loader from "../../hooks/Loader";
import RecordNotfound from "../Home/RecordNotfound";
import InfiniteScroll from "react-infinite-scroll-component";
import ReactPlayer from "react-player";
import { useParams } from "react-router-dom/dist";
import { errorMsg, images, successMsg } from "../../actions/customFn";
import { useAccount, useClient, useWalletClient } from "wagmi";
import { polygon, polygonAmoy } from "viem/chains";
import { contracts, network, zeroAddress } from "../../utils/env";
import { AssetContractShared, ERC20, Exchange } from "../../contracts";
import { getContract, parseEther, publicActions } from "viem";
import UploadContentModal from "../Modal/UploadContentModal";

const Posts = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [emojicolor, setEmojiColor] = useState();
  const [freindposts, setFreindposts] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [uploadload, setUploadLoad] = useState(false);
  const [likecount, setLikeCount] = useState([]);
  const { address } = useAccount();
  const { id } = useParams();
  useEffect(() => {
    if (currentPage === 0) {
      setIsLoader(true);
    }
    getFreindPostList(setFreindposts, setIsLoader, currentPage, setHasMore, id);
  }, [currentPage, id, uploadload]);

  const handlelikePost = (id, index) => {
    asyncdoPostLike(id).then((res) => {
      if (res) {
        let like_arr = [...freindposts];
        like_arr[index].is_blog_like = !like_arr[index].is_blog_like;
        setEmojiColor(!emojicolor);
        if (like_arr[index].is_blog_like == false) {
          like_arr[index].count_blog_like = like_arr[index].count_blog_like - 1;
          setLikeCount(likecount.filter((value) => value !== id));
        }

        if (like_arr[index].is_blog_like == true) {
          like_arr[index].count_blog_like = like_arr[index].count_blog_like + 1;
          setLikeCount((state) => [...state, id]);
        }
        setFreindposts(like_arr);
      }
    });
  };

  const refresh = (setItems) => {
    setTimeout(() => {
      setCurrentPage(0);
    }, 1000);
  };

  const handleLoadMore = () => {
    setTimeout(() => {
      setCurrentPage(currentPage + 1);
    }, 1000);
  };

  function copyLink() {
    const el = document.createElement("input");
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    successMsg("Link copied to Clipboard");
  }
  // mint post
  const getBuyerReplacementPattern = () => {
    return (
      window.web3.utils.padLeft("0x0", 8) +
      window.web3.utils.toTwosComplement("-1").slice(2) +
      window.web3.utils.toTwosComplement("0x0").slice(2) +
      window.web3.utils.toTwosComplement("0x0").slice(2) +
      window.web3.utils.toTwosComplement("0x0").slice(2) +
      window.web3.utils.toTwosComplement("0x0").slice(2) +
      window.web3.utils.toTwosComplement("0x0").slice(2) +
      window.web3.utils.toTwosComplement("0x0").slice(2) +
      window.web3.utils.toTwosComplement("0x0").slice(2)
    );
  };
  let { data: _walletClient } = useWalletClient({
    chainId: network === "mainnet" ? polygon.id : polygonAmoy.id,
    account: address,
  });
  const walletClient = _walletClient
    ? _walletClient.extend(publicActions)
    : null;
  const client = useClient({
    chainId: process.env.REACT_APP_NETWORK === "mainnet" ? polygon.id : 80002,
  });

  const matchOrder = async (sellerOrder, buyerOrder) => {
    const { request } = await walletClient.simulateContract({
      address: contracts.Exchange,
      abi: Exchange,
      functionName: "atomicMatch",
      value:
        sellerOrder.payment_token === zeroAddress ? buyerOrder.basePrice : 0,
      args: [
        [
          buyerOrder.exchange,
          buyerOrder.maker,
          buyerOrder.taker,
          buyerOrder.feeRecipient,
          buyerOrder.target,
          buyerOrder.staticTarget,
          buyerOrder.paymentToken,
          sellerOrder.exchange,
          sellerOrder.maker,
          sellerOrder.taker,
          sellerOrder.fee_recipient,
          sellerOrder.target,
          sellerOrder.static_target,
          sellerOrder.payment_token,
        ],
        [
          buyerOrder.makerRelayerFee,
          buyerOrder.takerRelayerFee,
          buyerOrder.makerProtocolFee,
          buyerOrder.takerProtocolFee,
          buyerOrder.basePrice,
          buyerOrder.extra,
          buyerOrder.maximumFill,
          buyerOrder.listingTime,
          buyerOrder.expirationTime,
          buyerOrder.salt,
          sellerOrder.maker_relayer_fee,
          sellerOrder.taker_relayer_fee,
          sellerOrder.maker_protocol_fee,
          sellerOrder.taker_protocol_fee,
          parseEther(sellerOrder.base_price),
          sellerOrder.extra,
          sellerOrder.maximum_fill,
          sellerOrder.listing_time,
          sellerOrder.expiration_time,
          sellerOrder.salt,
        ],
        [
          buyerOrder.feeMethod,
          buyerOrder.side,
          buyerOrder.saleKind,
          buyerOrder.howToCall,
          sellerOrder.fee_method,
          sellerOrder.side,
          sellerOrder.sale_kind,
          sellerOrder.how_to_call,
        ],
        buyerOrder.calldata,
        sellerOrder.calldata,
        buyerOrder.replacementPattern,
        sellerOrder.replacement_pattern,
        buyerOrder.staticExtradata,
        sellerOrder.static_extra_data,
        [sellerOrder.v, sellerOrder.v],
        [
          sellerOrder.r,
          sellerOrder.s,
          sellerOrder.r,
          sellerOrder.s,
          "0x0000000000000000000000000000000000000000000000000000000000000000",
        ],
      ],
    });

    await walletClient.switchChain({
      id: network === "mainnet" ? polygon.id : polygonAmoy.id,
    });
    const transaction = await walletClient.waitForTransactionReceipt({
      hash: await walletClient.writeContract(request),
    });
    console.log(transaction, "transaction");
    return transaction;
  };
  const getCalldata = (address, abi, method, ...params) => {
    const contract = new window.web3.eth.Contract(abi, address);
    const calldata = contract.methods[method](...params).encodeABI();
    return calldata;
  };
  const handleMint = async (mint_data) => {
    try {
      setIsLoader(true);
      if (!address) {
        setIsLoader(false);
        errorMsg("Please connect your wallet to make blockchain transaction");
        return;
      }
      const salt = window.web3.utils.hexToNumberString(
        window.web3.utils.randomHex(32)
      );

      const order = {
        exchange: mint_data[0].exchange, // min_order.exchange
        maker: address,
        taker: mint_data[0].maker, // Order taker address //
        makerRelayerFee: mint_data[0].maker_relayer_fee, // Maker relayer fee of the order, unused for taker order
        takerRelayerFee: mint_data[0].taker_relayer_fee, // Taker relayer fee of the order, or maximum taker fee for a taker order
        makerProtocolFee: mint_data[0].maker_protocol_fee, // Maker protocol fee of the order, unused for taker order
        takerProtocolFee: mint_data[0].taker_protocol_fee, // Taker protocol fee of the order, or maximum taker fee for a taker order
        feeRecipient: zeroAddress, // Order fee recipient or zero address for taker order
        feeMethod: mint_data[0].fee_method, // Fee method (protocol token or split fee)
        side: 0, // Side (buy/sell) // 0 bydefault
        saleKind: mint_data[0].sale_kind, // Kind of sale
        target: mint_data[0].target, // Target
        howToCall: mint_data[0].how_to_call, // HowToCall
        calldata: getCalldata(
          contracts.AssetSharedContract,
          AssetContractShared,
          "safeTransferFrom",
          zeroAddress,
          address, // connectted wallet address
          mint_data[0].on_chain_id, // need token onChainId
          1,
          mint_data[0].uri
            ? window.ethers.toQuantity(
              window.ethers.toUtf8Bytes(mint_data[0].uri)
            )
            : []
        ), // Calldata
        maximumFill: 1,
        replacementPattern: getBuyerReplacementPattern(), // Calldata replacement pattern, or an empty byte array for no replacement
        staticTarget: mint_data[0].static_target, // Static call target, zero-address for no static call
        staticExtradata: mint_data[0].static_extra_data, // Static call extra data
        paymentToken: mint_data[0].payment_token, // Token used to pay for the order, or the zero-address as a sentinel value for Ether
        basePrice: parseEther(mint_data[0].base_price), // Base price of the order (in paymentTokens)
        extra: mint_data[0].extra, // m bid increment for English auctions, starting/ending price difference
        listingTime: Math.round(Date.now() / 1000) - 19800, // Listing timestamp
        expirationTime: 0, // Expiration timestamp - 0 for no expiry
        salt: salt, // Order salt, used to prevent duplicate hashes
      };
      console.log(order, "order");
      const ethers = window.ethers;

      const { v, r, s } = ethers.Signature.from(mint_data[0].signature);
      console.log(v, r, s, "splidata");

      if (order.paymentToken !== zeroAddress) {
        // const paymentAddress = order.paymentToken;
        const paymentToken = getContract({
          address: order.paymentToken,
          abi: ERC20,
          client,
        });
        const allowance = await paymentToken.read.allowance([
          address,
          order.exchange,
        ]);
        console.log(allowance, "allowance");
        // eslint-disable-next-line no-undef
        console.log(BigInt(order.basePrice), "bigInt");
        // eslint-disable-next-line no-undef
        if (allowance < BigInt(order.basePrice)) {
          const { request } = await walletClient.simulateContract({
            address: paymentToken.address,
            abi: paymentToken.abi,
            functionName: "approve",
            args: [order.exchange, order.basePrice],
          });
          await walletClient.switchChain({
            id: network === "mainnet" ? polygon.id : polygonAmoy.id,
          });
          const transaction = await walletClient.waitForTransactionReceipt({
            hash: await walletClient.writeContract(request),
          });
          console.debug("Approval tx:", transaction);
        }
      }
      await matchOrder({ ...mint_data[0], v, r, s }, order);
      successMsg("Sucessfully minted");
      setIsLoader(false);
    } catch (error) {
      console.log(error);
      setIsLoader(false);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0.2 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 1 }}
      transition={{ duration: 2 }}
    >
      {isLoader == true ? (
        <Loader />
      ) : (
        <>
          <div className="timelinepost my_post">
            <div className="timep-upload">
              <div className=" ">
                <h2 className="mt-0">Upload Post</h2>
              </div>
            </div>
            <div className="p-2">
              {/* ------------------------- */}
              {freindposts !== undefined && freindposts.length > 0 ? (
                <InfiniteScroll
                  dataLength={freindposts.length}
                  next={handleLoadMore}
                  hasMore={hasMore}
                  loader={<Loader />}
                  scrollableTarget="scrollableDiv"
                  refreshFunction={refresh}
                  endMessage={
                    <p style={{ textAlign: "center" }}>
                      <b>Yay! You have seen it all</b>
                    </p>
                  }
                  pullDownToRefresh
                  pullDownToRefreshThreshold={50}
                  pullDownToRefreshContent={
                    <h3 style={{ textAlign: "center" }}>
                      Pull down to refresh
                    </h3>
                  }
                  releaseToRefreshContent={
                    <h3 style={{ textAlign: "center" }}>Release to refresh</h3>
                  }
                >
                  {freindposts.map((value, index) => {
                    return (
                      <>
                        <div className="mypost_one" key={index}>
                          <div className="timepost_main">
                            <div className="myself_top_post">
                              <div className="timepost-likes">
                                <div className="date-mint-sec">
                                  <h4 className="date-sec">{value.date}</h4>
                                  {value?.mint_data?.length > 0 ? (
                                    <span
                                      onClick={() =>
                                        handleMint(value?.mint_data)
                                      }
                                    >
                                      Mint {value?.mint_data[0]?.fill}
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div className="likes_butn">
                                  <h6>{value.count_blog_like} </h6>{" "}
                                  <button
                                    onClick={() => {
                                      handlelikePost(value.id, index);
                                    }}
                                    className="lb-like"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      height="20"
                                      vishal="20"
                                      viewBox="0 0 20 20"
                                      fill="none"
                                    >
                                      <path
                                        style={{
                                          fill: `${value.is_blog_like === true
                                            ? "#FF0098"
                                            : "#fff"
                                            }`,
                                        }}
                                        d="M3.33334 17.5001H4.16667V6.66675H3.33334C2.89131 6.66675 2.46739 6.84234 2.15483 7.1549C1.84227 7.46747 1.66667 7.89139 1.66667 8.33342V15.8334C1.66667 16.2754 1.84227 16.6994 2.15483 17.0119C2.46739 17.3245 2.89131 17.5001 3.33334 17.5001ZM16.6667 6.66675H10.8333L11.7683 3.86008C11.8518 3.60959 11.8745 3.34287 11.8346 3.08188C11.7948 2.82089 11.6935 2.57309 11.5392 2.35891C11.3848 2.14473 11.1817 1.97028 10.9467 1.84994C10.7117 1.72961 10.4515 1.66682 10.1875 1.66675H10L5.83334 6.19842V17.5001H15L18.26 10.3367L18.3333 10.0001V8.33342C18.3333 7.89139 18.1577 7.46747 17.8452 7.1549C17.5326 6.84234 17.1087 6.66675 16.6667 6.66675Z"
                                        fill="white"
                                      />
                                    </svg>
                                  </button>
                                  <button
                                    type="button"
                                    className="lb-share"
                                    onClick={copyLink}
                                  >
                                    <img
                                      src={"/images/share_post.png"}
                                      alt=""
                                    />
                                  </button>
                                </div>
                              </div>
                            </div>
                            {value.media_type === "video" ? (
                              <ReactPlayer
                                url={value.video}
                                controls
                                onEnded={()=>asyncPostPlay(value.id)}
                                width="100%"
                                height="auto"
                              />
                            ) : (
                              <img
                                src={value.image}
                                alt=""
                                className="my_post_img"
                              />
                            )}
                            <div className="myself_post">
                              <div className="pro-sec">
                                <img src={value.author_image} alt="" />
                                <h6>{value.author_name}</h6>
                              </div>
                              <h2>{value.title}</h2>
                              <p>
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: value.description,
                                  }}
                                ></span>{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </InfiniteScroll>
              ) : (
                <RecordNotfound />
              )}

              {/* <UploadPostModal
                show={show}
                setIsLoader={setIsLoader}
                setShow={setShow}
                close={handleClose}
                setUploadLoad={setUploadLoad}
                /> */}
              <UploadContentModal
                isOpen={show}
                setLoadPage={setIsLoader}
                setIsOpen={setShow}
                close={handleClose}
                setUploadLoad={setUploadLoad}
              />
            </div>
          </div>
        </>
      )}
    </motion.div>
  );
};

export default Posts;
