import React, { useState } from "react";
import MainLoader from "../../hooks/MainLoader";
import { useAccount, useClient, useWalletClient } from "wagmi";
import { polygon, polygonAmoy } from "viem/chains";
import { contracts, network } from "../../utils/env";
import { getContract, publicActions, zeroAddress } from "viem";
import { AssetContractShared, ERC20, Exchange } from "../../contracts";
import { errorMsg, successMsg } from "../../actions/customFn";
import { asyncPostMint } from "../../actions/uploadBlogs";

const CancelSell = (props) => {
  //   console.log(props, "data");
  const [isLoader, setIsLoader] = useState(false);
  const { address } = useAccount();
  const client = useClient({
    chainId: process.env.REACT_APP_NETWORK === "mainnet" ? polygon.id : 80002,
  });
  const salt = window.web3.utils.hexToNumberString(
    window.web3.utils.randomHex(32)
  );
  let { data: _walletClient, isError, isLoading } = useWalletClient({
    chainId: network === "mainnet" ? polygon.id : polygonAmoy.id,
    account: address,
  });
  const walletClient = _walletClient
    ? _walletClient.extend(publicActions)
    : null;
  const getCalldata = (address, abi, method, ...params) => {
    const contract = new window.web3.eth.Contract(abi, address);
    const calldata = contract.methods[method](...params).encodeABI();
    return calldata;
  };
  const getSellerReplacementPattern = () => {
    return (
      window.web3.utils.padLeft("0x0", 8) +
      window.web3.utils.toTwosComplement("0x0").slice(2) +
      window.web3.utils.toTwosComplement("-1").slice(2) +
      window.web3.utils.toTwosComplement("0x0").slice(2) +
      window.web3.utils.toTwosComplement("-1").slice(2) +
      window.web3.utils.toTwosComplement("0x0").slice(2) +
      window.web3.utils.toTwosComplement("0x0").slice(2) +
      window.web3.utils.toTwosComplement("0x0").slice(2) +
      window.web3.utils.toTwosComplement("0x0").slice(2)
    );
  };
  const getOrderSignature = async (order) => {
    const { request } = await walletClient.simulateContract({
      address: contracts.Exchange,
      abi: Exchange,
      functionName: "cancelOrder",
      args: [
        [
          order.exchange,
          order.maker,
          order.taker,
          order.feeRecipient,
          order.target,
          order.staticTarget,
          order.paymentToken,
        ],
        [
          order.makerRelayerFee,
          order.takerRelayerFee,
          order.makerProtocolFee,
          order.takerProtocolFee,
          order.basePrice,
          order.extra,
          order.maximumFill,
          order.listingTime,
          order.expirationTime,
          order.salt,
        ],
        order.feeMethod,
        order.side,
        order.saleKind,
        order.howToCall,
        order.calldata,
        order.replacementPattern,
        order.staticExtradata,
        order.v,
        order.r,
        order.s,
      ],
    });
    await walletClient.switchChain({
      id: network === "mainnet" ? polygon.id : polygonAmoy.id,
    });
    const transaction = await walletClient.waitForTransactionReceipt({
      hash: await walletClient.writeContract(request),
    });
    console.log(transaction, "transaction");
    return transaction;
  };

  const handleCancelSell = async () => {
    setIsLoader(true);
    try {
      if (!address) {
        setIsLoader(false);
        errorMsg("Please connect you wallet to make blockchain transaction");
        return;
      }
      const ethers = window.ethers;
      const { v, r, s } = ethers.Signature.from(props.data.signature);
      // setProxyVerify(false);
      const order = {
        exchange: contracts.Exchange, // Exchange address
        maker: props.data.maker, // Order maker address
        taker: zeroAddress, // Order taker address
        makerRelayerFee: props?.data?.makerRelayerFee, // Maker relayer fee of the order, unused for taker order
        takerRelayerFee: props?.data?.takerRelayerFee, // Taker relayer fee of the order, or maximum taker fee for a taker order
        makerProtocolFee: props?.data?.makerProtocolFee, // Maker protocol fee of the order, unused for taker order
        takerProtocolFee: props?.data?.takerProtocolFee,
        feeRecipient: contracts.AdminAddress, // Order fee recipient or zero address for taker order
        feeMethod: 1, // Fee method (protocol token or split fee)
        side: 1, // Side (buy/sell)
        saleKind: 0, // Kind of sale
        target: contracts.AssetSharedContract, // Target
        howToCall: 0, // HowToCall
        calldata: getCalldata(
          contracts.AssetSharedContract,
          AssetContractShared,
          "safeTransferFrom",
          props.data.maker,
          zeroAddress,
          props.onChainId,
          props.data.maximumFill, // Math.pow(2, 40) - 1
          props.uri
            ? window.ethers.toQuantity(window.ethers.toUtf8Bytes(props.uri))
            : []
        ),
        replacementPattern: getSellerReplacementPattern(), // Calldata replacement pattern, or an empty byte array for no replacement
        staticTarget: zeroAddress, // Static call target, zero-address for no static call
        staticExtradata: "0x", // Static call extra data
        paymentToken: props.data.paymentToken, // Token used to pay for the order, or the zero-address as a sentinel value for Ether
        basePrice: window.web3.utils.toWei(props.data.basePrice), // 5 Base price of the order (in paymentTokens)
        extra: 0, // Auction extra parameter - minimum bid increment for English auctions, starting/ending price difference
        listingTime: props.data.listingTime, // Listing timestamp
        expirationTime: props?.data?.expirationTime, // 0 Expiration timestamp - 0 for no expiry
        salt: props.data.salt,
        maximumFill: props.data.maximumFill,
      };
      console.log(order, "order");
      console.log(order.paymentToken, zeroAddress, "order - zero");
      if (order.paymentToken !== zeroAddress) {
        const paymentToken = getContract({
          address: order.paymentToken,
          abi: ERC20,
          client,
        });
        const allowance = await paymentToken.read.allowance([
          address,
          order.exchange,
        ]);
        console.log(allowance, "allowance");

        // eslint-disable-next-line no-undef
        console.log(BigInt(order.basePrice), "bigInt");
        // eslint-disable-next-line no-undef
        if (allowance < BigInt(order.basePrice)) {
          const { request } = await walletClient.simulateContract({
            address: paymentToken.address,
            abi: paymentToken.abi,
            functionName: "approve",
            args: [order.exchange, order.basePrice],
          });
          await walletClient.switchChain({
            id: network === "mainnet" ? polygon.id : polygonAmoy.id,
          });
          const transaction = await walletClient.waitForTransactionReceipt({
            hash: await walletClient.writeContract(request),
          });
          console.debug("Approval tx:", transaction);
          props.refresh();
        }
      }
      await getOrderSignature({ ...order, v, r, s });
      successMsg("Sucessfully Cancel Sell");
      setIsLoader(false);
    } catch (error) {
      console.log(error);
      setIsLoader(false);
    }
  };
  return (
    <>
      {isLoader && <MainLoader />}
      <button className="buy-and-cancel" onClick={() => handleCancelSell()}>
        Cancel Sell
      </button>
    </>
  );
};

export default CancelSell;
