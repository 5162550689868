import React, { useEffect, useState } from "react";
import { errorMsg } from "../actions/customFn";
import TextField from "@mui/material/TextField";
import { NavLink, useNavigate } from "react-router-dom";
import { asyncActivateAccount } from "../actions/authActions";
import { asyncGetPagesData } from "../actions/pageAction";

const AccountActivation = () => {
  const navigate = useNavigate();
  const [homeDetail, setHomeDetail] = useState({});

  const [AccountData, setAccountData] = useState({
    account_key: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAccountData((formData) => ({ ...formData, [name]: value }));
  };

  const [formErrors, setFormError] = useState({});
  const errors = {};

  const validateFormData = async () => {
    try {
      if (AccountData.account_key == "") {
        errors.account_key = "Enter account key ";
      }

      setFormError(errors);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAccount = async (e) => {
    try {
      e.preventDefault();
      validateFormData();
      if (Object.keys(errors).length == 0) {
        // console.log("ddddd");
        asyncActivateAccount(AccountData, navigate);
      }
    } catch (error) {
      errorMsg("something went wrong!");
    }
  };

  useEffect(() => {
    asyncGetPagesData(3, setHomeDetail);
  }, []);

  return (
    <>
      <div className="register-page">
        <div
          className="left-register"
          style={{
            backgroundImage: `url(${"/images/home-img.png"})`,
          }}
        >
          <div className="right-div">
            <h1>
              {homeDetail.heading != undefined && homeDetail.heading != ""
                ? homeDetail.heading
                : ""}
            </h1>
            <p>
              {homeDetail.short_description != undefined &&
                homeDetail.short_description != ""
                ? homeDetail.short_description
                : ""}
            </p>
          </div>
        </div>

        <div className="right-register account-activation-page left_row account-activate  new-all-inputs">
          <div className=" login-page_right_inner ">
            <div className="logo_heding right-top reset-head">
              <NavLink to="/">
                <img src={"/images/register-R1.png"} alt="" />
              </NavLink>
              <h2>Account Activation</h2>
            </div>

            <form onSubmit={handleAccount}>
              <div className="input_field reset-pasw">
                <TextField
                  error={formErrors.account_key}
                  name="account_key"
                  sx={{
                    width: "100%",
                  }}
                  className={`input_username new-edit-main-all `}
                  id="outlined-basic"
                  label="Activation Key"
                  placeholder="Enter your activation key"
                  onChange={handleChange}
                  helperText={formErrors.account_key}
                />
              </div>

              <div className=" account_activation_btn">
                <button>Activate</button>
              </div>
            </form>
            <div className="nav_login_register_btn">
              <NavLink className="login_nav_btn" to="/login">
                Login
              </NavLink>
              <span>|</span>
              <NavLink className="login_nav_btn" to="/register">
                Register
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountActivation;
