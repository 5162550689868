import React, { useState } from "react";
import { errorMsg } from "../../actions/customFn";
import Modal from "react-bootstrap/Modal";

import "quill/dist/quill.snow.css";
import SmallLoader from "react-js-loader";
import { asyncPostMint, asyncUpdateStatus } from "../../actions/uploadBlogs";
import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Flatpickr from "react-flatpickr";
import {
  contracts,
  network,
  paymentTokens,
  zeroAddress,
} from "../../utils/env";
import { AssetContractShared, Exchange, ERC20 } from "../../contracts";
import { useAccount, useClient, useWalletClient } from "wagmi";
import { polygon, polygonAmoy } from "viem/chains";
import { etherUnits, getContract, parseEther, publicActions } from "viem";
import { signMessage } from "@wagmi/core";
import moment from "moment";
// import validator from "validator";

const PostMintPopup = (props) => {
  const { setShow } = props;
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormError] = useState({});
  const [royalties, setRoyalties] = useState([]);

  const handleChangeNew = (index, e) => {
    const { name, value } = e.target;
    const updatedFields = [...royalties];
    updatedFields[index][name] = value;
    setRoyalties(updatedFields);
  };
  console.log(royalties, "royalties");

  const addMoreRoyalties = () => {
    setRoyalties([...royalties, { percent: "", address: "" }]);
  };
  const removeRoyalties = (index) => {
    const updatedFields = royalties.filter((_, i) => i !== index);
    setRoyalties(updatedFields);
  };
  // const [form, setForm] = useState({
  //   paymentToken: "",
  // });
  const [formData, setFormData] = useState({
    amount: "",
    quantity: "",
    paymentToken: "",
    date: moment().format(),
  });
  const { address } = useAccount();
  // const { signMessage } = useSignMessage();
  const client = useClient({
    chainId: process.env.REACT_APP_NETWORK === "mainnet" ? polygon.id : 80002,
  });
  const [selectedValue, setSelectedValue] = useState(paymentTokens[0].value);
  // const navigate = useNavigate();

  const salt = window.web3.utils.hexToNumberString(
    window.web3.utils.randomHex(32)
  );
  const getCalldata = (address, abi, method, ...params) => {
    const contract = new window.web3.eth.Contract(abi, address);
    const calldata = contract.methods[method](...params).encodeABI();

    return calldata;
  };

  let { data: _walletClient, isError, isLoading } = useWalletClient({
    chainId: network === "mainnet" ? polygon.id : polygonAmoy.id,
    account: address,
  });
  const walletClient = _walletClient
    ? _walletClient.extend(publicActions)
    : null;

  const getSellerReplacementPattern = () => {
    return (
      window.web3.utils.padLeft("0x0", 8) +
      window.web3.utils.toTwosComplement("0x0").slice(2) +
      window.web3.utils.toTwosComplement("-1").slice(2) +
      window.web3.utils.toTwosComplement("0x0").slice(2) +
      window.web3.utils.toTwosComplement("-1").slice(2) +
      window.web3.utils.toTwosComplement("0x0").slice(2) +
      window.web3.utils.toTwosComplement("0x0").slice(2) +
      window.web3.utils.toTwosComplement("0x0").slice(2) +
      window.web3.utils.toTwosComplement("0x0").slice(2)
    );
  };
  const exchangeToken = getContract({
    address: contracts.Exchange,
    abi: Exchange,
    client,
  });

  const getOrderSignature = async (order) => {
    const orderHash = await exchangeToken.read.hashOrder([
      [
        order.exchange,
        order.maker,
        order.taker,
        order.feeRecipient,
        order.target,
        order.staticTarget,
        order.paymentToken,
      ],
      [
        order.makerRelayerFee,
        order.takerRelayerFee,
        order.makerProtocolFee,
        order.takerProtocolFee,
        order.basePrice,
        order.extra,
        order.maximumFill,
        order.listingTime,
        order.expirationTime,
        order.salt,
      ],
      order.feeMethod,
      order.side,
      order.saleKind,
      order.howToCall,
      order.calldata,
      order.replacementPattern,
      order.staticExtradata,
    ]);
    const signature = await walletClient.signMessage({
      account: address,
      // Hex data representation of message.
      message: { raw: orderHash },
    });
    // const signature = await signMessage({ message: orderHash });
    console.log(signature, "signature", orderHash);

    return { signature, orderHash };
  };

  const getOrderMakerRelayerFee = (royalty) => {
    let percent = 2.5; // Platform fee
    if (royalty instanceof Array) {
      royalty.forEach((i) => (percent += Number(i.percent)));
    }
    return percent * 100;
  };

  const handleMint = async () => {
    setLoading(true);
    const totalPercent = royalties.reduce(
      (sum, item) => sum + parseFloat(item.percent || 0),
      0
    );
    const addressEmpty = royalties.map((item) => {
      if (item.address === "") {
        return true;
      }
      return false;
    });

    if (formData.amount === "") {
      setFormError({
        amount: "Require",
      });
      setLoading(false);
      return;
    }
    if (formData.amount < "0") {
      setFormError({
        amount: "Positive amount",
      });
      setLoading(false);
      return;
    }
    if (totalPercent > 10) {
      errorMsg("Percent not more than 10");
      setLoading(false);
      return;
    }
    if (addressEmpty.includes(true)) {
      errorMsg("All address need to be fill");
      setLoading(false);
      return;
    }

    const tokenDetails = await asyncUpdateStatus(royalties);
    console.log(tokenDetails, "tokenDetails");
    if (!tokenDetails) {
      setLoading(false);
      errorMsg("Failed to fetch token details.");
      return;
    }
    try {
      const order = {
        exchange: contracts.Exchange, // Exchange address
        maker: tokenDetails.creator, // Order maker address
        taker: zeroAddress, // Order taker address
        makerRelayerFee: getOrderMakerRelayerFee(tokenDetails.royalty), // Maker relayer fee of the order, unused for taker order
        takerRelayerFee: 0, // Taker relayer fee of the order, or maximum taker fee for a taker order
        makerProtocolFee: 0, // Maker protocol fee of the order, unused for taker order
        takerProtocolFee: 0, // Taker protocol fee of the order, or maximum taker fee for a taker order
        feeRecipient: contracts.AdminAddress, // Order fee recipient or zero address for taker order
        feeMethod: 1, // Fee method (protocol token or split fee)
        side: 1, // Side (buy/sell)
        saleKind: 0, // Kind of sale
        target: contracts.AssetSharedContract, // Target
        howToCall: 0, // HowToCall
        calldata: getCalldata(
          contracts.AssetSharedContract,
          AssetContractShared,
          "safeTransferFrom",
          tokenDetails.creator,
          zeroAddress,
          tokenDetails.onChainId,
          Math.pow(2, 40) - 1, // formData.quantity
          tokenDetails.uri
            ? window.ethers.toQuantity(
                window.ethers.toUtf8Bytes(tokenDetails.uri)
              )
            : "0x"
        ),

        replacementPattern: getSellerReplacementPattern(), // Calldata replacement pattern, or an empty byte array for no replacement
        staticTarget: zeroAddress, // Static call target, zero-address for no static call
        staticExtradata: "0x", // Static call extra data
        paymentToken: selectedValue, // Token used to pay for the order, or the zero-address as a sentinel value for Ether
        basePrice: window.web3.utils.toWei(formData.amount), // 5 Base price of the order (in paymentTokens)
        extra: 0, // Auction extra parameter - minimum bid increment for English auctions, starting/ending price difference
        listingTime: Math.round(Date.now() / 1000) - 200000, // Listing timestamp
        expirationTime: 0, // Expiration timestamp - 0 for no expiry
        salt: salt,
        maximumFill: Math.pow(2, 40) - 1, // formData.quantity,
      };
      console.log(order, "order");
      if (order.paymentToken !== zeroAddress) {
        const paymentToken = getContract({
          address: order.paymentToken,
          abi: ERC20,
          client,
        });
        const allowance = await paymentToken.read.allowance([
          address,
          order.exchange,
        ]);
        console.log(allowance, "allowance");
        // eslint-disable-next-line no-undef
        console.log(BigInt(order.basePrice), "bigInt");
        // eslint-disable-next-line no-undef
        if (allowance < BigInt(order.basePrice)) {
          const { request } = await walletClient.simulateContract({
            address: paymentToken.address,
            abi: paymentToken.abi,
            functionName: "approve",
            args: [order.exchange, order.basePrice],
          });
          await walletClient.switchChain({
            id: network === "mainnet" ? polygon.id : polygonAmoy.id,
          });
          const transaction = await walletClient.waitForTransactionReceipt({
            hash: await walletClient.writeContract(request),
          });
          console.debug("Approval tx:", transaction);
          const { signature, orderHash } = await getOrderSignature(order);
          console.log(signature, "signature", orderHash);
          if (signature.hasOwnProperty("code")) {
            setLoading(false);
            errorMsg(signature.message);
            return;
          } else {
            let data = {
              tokenId: tokenDetails.id,
              listingTime: order.listingTime,
              paymentToken: selectedValue,
              hash: orderHash,
              salt: order.salt,
              signature: signature,
              type: "mint",
              basePrice: Number(formData.amount),
            };
            try {
              asyncPostMint(data, setShow);
              setLoading(false);
              setSelectedValue(paymentTokens[0].value);
              setRoyalties([]);
            } catch (error) {
              errorMsg("something went wrong!");
              setLoading(false);
            }
          }
        } else {
          const { signature, orderHash } = await getOrderSignature(order);
          console.log(signature, "signature", orderHash);
          if (signature.hasOwnProperty("code")) {
            setLoading(false);
            errorMsg(signature.message);
            return;
          } else {
            let data = {
              tokenId: tokenDetails.id,
              listingTime: order.listingTime,
              paymentToken: selectedValue,
              hash: orderHash,
              salt: order.salt,
              signature: signature,
              type: "mint",
              basePrice: Number(formData.amount),
            };
            try {
              asyncPostMint(data, setShow);
              setLoading(false);
              setSelectedValue(paymentTokens[0].value);
              setRoyalties([]);
            } catch (error) {
              errorMsg("something went wrong!");
              setLoading(false);
            }
          }
        }
      } else {
        const { signature, orderHash } = await getOrderSignature(order);
        console.log(signature, "signature", orderHash);
        if (signature.hasOwnProperty("code")) {
          setLoading(false);
          errorMsg(signature.message);
          return;
        } else {
          let data = {
            tokenId: tokenDetails.id,
            listingTime: order.listingTime,
            paymentToken: selectedValue,
            hash: orderHash,
            salt: order.salt,
            signature: signature,
            type: "mint",
            basePrice: Number(formData.amount),
          };
          try {
            asyncPostMint(data, setShow);
            setLoading(false);
            setSelectedValue(paymentTokens[0].value);
            setRoyalties([]);
          } catch (error) {
            errorMsg("something went wrong!");
            setLoading(false);
          }
        }
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleRemoveMint = () => {
    localStorage.removeItem("isMint");
    setShow(false);
  };
  // const handleChange = (e) => {
  //   if (e.target !== undefined) {
  //     const { name, value } = e.target;
  //     setForm((form) => ({ ...form, [name]: value }));
  //     setSelectedValue(value);
  //   }
  // };
  const handleChange = (e) => {
    if (e.target !== undefined) {
      const { name, value } = e.target;
      setFormData((formData) => ({ ...formData, [name]: value }));
    }
  };
  const handleSelect = (e) => {
    if (e.target !== undefined) {
      const { name, value } = e.target;
      setFormData((formData) => ({ ...formData, [name]: value }));
      setSelectedValue(value);
    }
  };
  return (
    <Modal
      show={props.show}
      className="upload_post_popup post-upload-modal post_mint_popup"
      onHide={props.close}
    >
      <Modal.Header>
        <h4>Complete the form for the Mint post</h4>
      </Modal.Header>

      <div className="row main_popup_inner1 new-all-inputs">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
          <FormHelperText sx={{ color: "#fff", marginBottom: "5px" }}>
            Price
          </FormHelperText>
          <TextField
            sx={{
              width: "100%",
            }}
            error={formErrors.amount}
            name="amount"
            className="input_username new-edit-main-all"
            id="outlined-basic"
            placeholder="price"
            type="number"
            onChange={(e) => {
              handleChange(e);
            }}
            helperText={formErrors.amount}
          />
        </div>
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3 hidden">
          <FormHelperText sx={{ color: "#fff", marginBottom: "5px" }}>
            Quantity
          </FormHelperText>
          <TextField
            sx={{
              width: "100%",
            }}
            error={formErrors.quantity}
            name="quantity"
            className="input_username new-edit-main-all"
            id="outlined-basic"
            type="number"
            placeholder="quantity"
            onChange={(e) => handleChange(e)}
            helperText={formErrors.quantity}
          />
        </div>
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
          <FormHelperText sx={{ color: "#fff", marginBottom: "5px" }}>
            Payment Token
          </FormHelperText>
          <FormControl fullWidth>
            <Select
              className="input_username new-edit-main-all"
              id="demo-simple-select-helper-label"
              name="paymentToken"
              value={selectedValue}
              onChange={handleSelect}
            >
              {paymentTokens &&
                paymentTokens.length > 0 &&
                paymentTokens.map((value, index) => {
                  return (
                    <MenuItem key={index} value={value.value}>
                      {value.label}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </div>
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3 hidden">
          <FormHelperText sx={{ color: "#fff", marginBottom: "5px" }}>
            Expiration Time
          </FormHelperText>
          <Flatpickr
            name="dob"
            placeholder="dob"
            options={{
              minDate: formData.date,
            }}
            defaultValue={formData.date}
            className="input_username new-edit-main-all flatpickrDate"
            onChange={([date]) => {
              setFormData((formData) => ({
                ...formData,
                date: moment(date).format(),
              }));
            }}
          />
        </div>
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-2 mt-3">
          <h3 className="mb-2">Royalty</h3>
          {royalties.map((royalty, index) => (
            <div className="row" key={index}>
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                <FormHelperText sx={{ color: "#fff", marginBottom: "5px" }}>
                  Percent
                </FormHelperText>
                <TextField
                  sx={{ width: "100%" }}
                  name="percent"
                  className="input_username new-edit-main-all"
                  id={`percent-${index}`}
                  type="number"
                  placeholder="Percent"
                  value={royalty.percent}
                  onChange={(e) => handleChangeNew(index, e)}
                />
              </div>
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <FormHelperText sx={{ color: "#fff", marginBottom: "5px" }}>
                  Address
                </FormHelperText>
                <TextField
                  sx={{ width: "100%" }}
                  name="address"
                  className="input_username new-edit-main-all"
                  id={`address-${index}`}
                  type="text"
                  placeholder="Address"
                  value={royalty.address}
                  onChange={(e) => handleChangeNew(index, e)}
                />
              </div>
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3 text-right mt-1">
                <button
                  onClick={() => removeRoyalties(index)}
                  className="remove-royalty"
                >
                  Remove
                </button>
              </div>
            </div>
          ))}
          {royalties.length > 0 ? (
            <div className="mb-3 text-right">
              <button className="add-more-royalty" onClick={addMoreRoyalties}>
                Add more +
              </button>
            </div>
          ) : (
            <div className="mb-3 text-right">
              <button className="add-more-royalty" onClick={addMoreRoyalties}>
                Add Royality
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="confirm-btn-sec">
        <button className="confirm_no_btn" onClick={handleRemoveMint}>
          Cancel
        </button>
        {loading === true ? (
          <button type="button" className="confirm_yes_btn">
            <SmallLoader
              type="spinner-default"
              bgColor={"#fff"}
              color={"#fff"}
              size={40}
            />
          </button>
        ) : (
          <button className="confirm_yes_btn" onClick={handleMint}>
            {loading ? "Wait..." : "Confirm"}{" "}
          </button>
        )}
      </div>
    </Modal>
  );
};

export default PostMintPopup;
