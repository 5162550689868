import React, { useEffect, useState } from "react";
import {
  CoursePlaylist,
  CourseRating,
  CourseIncludes,
  CoursesDetailBanner,
  Header,
  WhatLearn,
  TopCourses,
  FooterBig,
  FooterMobile,
  RecordNotfound,
} from "../components";
import Description from "../components/CoursesDetail/Description";
import { motion } from "framer-motion";
import { getCourseDetail } from "../actions/courseAction";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Loader from "../hooks/Loader";
import AskEducatorQuestionModal from "../components/Modal/AskEducatorQuestionModal";
// import EducatorFeedback from "../components/Modal/EducatorFeedback";
import CourseBuyNow from "../components/Modal/CourseBuyNow";
// import RelatedCourse from "../components/CoursesDetail/RelatedCourse";
import CourseRelatedcourse from "../components/CoursesDetail/CourseRelatedcourse";
import { courseRating } from "../utils/data";
// import ChapterDetails from "./ChapterDetails";

const CoursesDetail = () => {
  //Ask Question
  const [show, setShow] = useState(false);
  const [isRender, setIsRender] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // EducatorFeedback Modal
  // const [Educatorshow, setEducatorShow] = useState(false);
  // const handleEducatorClose = () => setEducatorShow(false);
  // const handleEducatorShow = () => setEducatorShow(true);

  //Payment Form
  const [paymentModalshow, setpaymentModalShow] = useState(false);
  const handlepaymentClose = () => setpaymentModalShow(false);
  const handlepaymentShow = () => setpaymentModalShow(true);
  // const [loadpage,setLoadPage]                  = useState(false);
  const [CourseDetail, setCourseDetail] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  // const directpage = (path) => {
  //   navigate(`/${path}`);
  // };

  useEffect(() => {
    setIsLoader(true);
    if (id !== "") {
      getCourseDetail(id, setCourseDetail, setIsLoader);
    }
  }, [id, isRender]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 3 }}
    >
      <div className="all_page_bg">
        <div className="container-fluid">
          <Header />

          {isLoader === true ? (
            <Loader />
          ) : (
            <div className="page-all-data">
              {CourseDetail !== "" ? (
                <>
                  <div>
                    <div className="courses_detail_main">
                      <div className="row">
                        <div className="col-xl-9 col-lg-7 col-md-12 col-sm-12 ">
                          <div className="list_Breadcrumb">
                            <ul>
                              <li>
                                <NavLink className="top_nav_link" to="/courses">
                                  Elite Academy <span>/</span>
                                </NavLink>
                              </li>
                              {/* <li>
                              {" "}
                              <NavLink className="top_nav_link" to="/">
                                3D Animation <span>/</span>
                              </NavLink>
                            </li> */}

                              <li>
                                <NavLink to="/">Course Details</NavLink>
                              </li>
                            </ul>
                          </div>
                          {CourseDetail.course_details !== undefined && (
                            <>
                              <CoursesDetailBanner
                                bannerdetails={CourseDetail.course_details}
                              />

                              {CourseDetail.course_details
                                .add_what_you_learn !== undefined &&
                                CourseDetail.course_details.add_what_you_learn
                                  .length > 0 && (
                                  <WhatLearn
                                    whatyou_learn={
                                      CourseDetail.course_details
                                        .add_what_you_learn
                                    }
                                  />
                                )}

                              {CourseDetail.course_details.description !==
                                undefined &&
                                CourseDetail.course_details.description !==
                                  "" && (
                                  <>
                                    <Description
                                      description={
                                        CourseDetail.course_details.description
                                      }
                                    />
                                    {CourseDetail.course_details
                                      .course_playlist != undefined &&
                                      CourseDetail.course_details
                                        .course_playlist.length > 0 && (
                                        <CoursePlaylist
                                          course_playlist={
                                            CourseDetail.course_details
                                              .course_playlist
                                          }
                                        />
                                      )}
                                    <CourseRating
                                      course_retings={courseRating}
                                    />
                                  </>
                                )}
                            </>
                          )}

                          {CourseDetail.course_retings != undefined && (
                            <CourseRating
                              course_retings={CourseDetail.course_retings}
                            />
                          )}

                          {CourseDetail.course_details.course_questions !=
                            undefined &&
                            CourseDetail.course_details.course_questions
                              .length > 0 && (
                              <div className="top_courses_heading">
                                <h1>Questions</h1>

                                <div className="askedquestion_section">
                                  {CourseDetail.course_details
                                    .course_questions != undefined &&
                                    CourseDetail.course_details.course_questions.map(
                                      (value, index) => {
                                        return (
                                          <div className="course_questionlist">
                                            <h5>
                                              {" "}
                                              Que. {index + 1} :{" "}
                                              {value.course_question}
                                            </h5>
                                          </div>
                                        );
                                      }
                                    )}
                                </div>
                              </div>
                            )}
                        </div>
                        <div className="col-xl-3 col-lg-5 col-md-12 col-sm-12 col-12">
                          <div className="">
                            <div className="course-includes-top">
                              <CourseIncludes
                                CourseDetail={CourseDetail.course_details}
                                handleShow={handleShow}
                                // handlepaymentClose={handlepaymentClose}
                                handlepaymentShow={handlepaymentShow}
                                // course_details={CourseDetail.course_details}
                              />
                            </div>

                            {CourseDetail.top_courses != undefined &&
                              CourseDetail.top_courses.length > 0 && (
                                <div className="top-courses-bottom">
                                  <div className="top_courses_heading">
                                    {CourseDetail.top_courses != undefined &&
                                      CourseDetail.top_courses.length > 0 && (
                                        <>
                                          <h1>Top Courses</h1>
                                          <TopCourses
                                            topcourse={CourseDetail.top_courses}
                                          />
                                        </>
                                      )}
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className="row"></div> */}
                  </div>
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 d-none">
                    {CourseDetail.releted_courses !== undefined &&
                      CourseDetail.releted_courses.length > 0 && (
                        <div className="related_course_all">
                          <div className="d-flex justify-content-between mb-3">
                            <div className="col-md-2">
                              <h1>Related Course</h1>
                            </div>
                            <div className="col-md-2 mr-3">
                              {CourseDetail != undefined &&
                              CourseDetail.course_details != undefined &&
                              CourseDetail.course_details.category_id !=
                                undefined &&
                              CourseDetail.course_details.category_id != "" ? (
                                <div class="mountain_button text-right">
                                  <button
                                    onClick={() =>
                                      navigate(
                                        `/courses-category/${CourseDetail?.course_details?.category_id}`
                                      )
                                    }
                                  >
                                    View more
                                  </button>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          {/* <SimpleCourse
                      courselist={CourseDetail.releted_courses}
                    /> */}
                          {/* <RelatedCourse
                      relatedcourse={CourseDetail.releted_courses}
                    /> */}
                          <CourseRelatedcourse
                            courselist={CourseDetail.releted_courses}
                          />
                          {/* <div className="course_rating_main_last_btn">
                      <button
                        onClick={(e) => {
                          directpage("post-list");
                        }}
                      >
                        Show More
                      </button>
                    </div> */}
                        </div>
                      )}
                  </div>

                  <div className="mobile-footer-new">
                    <FooterMobile />
                  </div>
                  <FooterBig />

                  {/* <EducatorFeedback
                  show={Educatorshow}
                  close={handleEducatorClose}
                /> */}

                  <AskEducatorQuestionModal
                    show={show}
                    setShow={setShow}
                    close={handleClose}
                  />

                  <CourseBuyNow
                    show={paymentModalshow}
                    setShow={setpaymentModalShow}
                    amount={CourseDetail.course_details.price}
                    close={handlepaymentClose}
                    id={CourseDetail.course_details.id}
                    isRender={isRender}
                    setIsRender={setIsRender}
                    setLoadPage={setIsLoader}
                  />
                </>
              ) : (
                <RecordNotfound />
              )}
            </div>
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default CoursesDetail;
