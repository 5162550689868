import React from "react";

import ProgressBar from "react-bootstrap/ProgressBar";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";

const HalfScroreBoard = () => {
  const range = 30;
  const { userInfo } = useSelector((state) => state.userAuth);
  const [userDetail, setUserDetail] = useState({});

  useEffect(() => {
    setUserDetail(userInfo);
  }, [userInfo]);

  return (
    <>
      <div className="ScoreBoard_main">
        <div className="ScoreBoard_top_box  half_score_board">
          <div className="player_profile">
            <div className="icon_king">
              <img src={"/images/icon_king.png"} alt="" />
            </div>
            {userDetail && <img src={userDetail.myai_image} alt="" />}
          </div>
          <div className="player_info_card">
            <div className="player_range">
              <img src={"/images/shield-1.png"} alt="" />
              <div className="progress_div_inner">
                <ProgressBar now={userDetail != "" &&
                  userDetail.customer_reward_point != undefined
                  ? userDetail.customer_reward_point / 100
                  : ""} />
              </div>
            </div>
            {/* {console.log("ssdsddsd",userDetail != "" &&
                  userDetail.customer_reward_point != undefined
                    ? userDetail.customer_reward_point/100
                    : "")} */}

            <div className="player_dim_main">
              <div className="player_icon">
                <img src={"/images/star.png"} alt="" />
                <span>
                  {" "}
                  {userDetail != "" &&
                    userDetail.customer_reward_point != undefined
                    ? userDetail.customer_reward_point
                    : ""}
                </span>
              </div>

              <div className="player_icon">
                <img src={"/images/role.png"} alt="" />
                <span>14342</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HalfScroreBoard;
