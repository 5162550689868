import React, { useEffect, useState } from "react";
import { errorMsg, images } from "../actions/customFn";
import validator from "validator";
import { NavLink, useNavigate } from "react-router-dom";
import { asyncforgetpassword } from "../actions/authActions";
import { TextField } from "@mui/material";
import { asyncGetPagesData } from "../actions/pageAction";

const ForgetPassword = () => {
  const navigate = useNavigate();
  const [homeDetail, setHomeDetail] = useState({});
  const [forgetForm, setforgetForm] = useState({
    email: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setforgetForm((formData) => ({ ...formData, [name]: value }));
  };

  const [formErrors, setFormError] = useState({});
  const errors = {};

  const validateFormData = async () => {
    try {
      if (forgetForm.email === "") {
        errors.email = "Enter email id";
      } else if (!validator.isEmail(forgetForm.email)) {
        errors.email = "Enter valid email id";
      }
      setFormError(errors);
    } catch (error) {
      console.log(error);
    }
  };

  const handleforgetpassword = async (e) => {
    try {
      e.preventDefault();
      validateFormData();
      if (Object.keys(errors).length === 0) {
        asyncforgetpassword(forgetForm.email, navigate);
      }
    } catch (error) {
      errorMsg("something went wrong!");
    }
  };

  useEffect(() => {
    asyncGetPagesData(3, setHomeDetail);
  }, []);

  return (
    <>
      <div className="register-page reset-password">
        <div
          className="left-register"
          style={{
            backgroundImage: `url(${"/images/home-img.png"})`,
          }}
        >
          <div className="right-div">
            <h1>
              {homeDetail.heading != undefined && homeDetail.heading != ""
                ? homeDetail.heading
                : ""}
            </h1>
            <p>
              {homeDetail.short_description != undefined &&
                homeDetail.short_description != ""
                ? homeDetail.short_description
                : ""}
            </p>
          </div>
        </div>

        <div className="right-register account-activation-page new-all-inputs">
          <div className="right-div">
            <div className="logo_heding right-top ">
              <NavLink to="/">
                <img src={"/images/register-R1.png"} alt="" />
              </NavLink>
              <h1>Forget Password</h1>
            </div>

            <form onSubmit={handleforgetpassword}>
              <div className="input_field">
                <TextField
                  error={formErrors.email}
                  name="email"
                  sx={{
                    width: "100%",
                  }}
                  className={`input_username new-edit-main-all `}
                  id="outlined-basic"
                  label="Email address"
                  placeholder="Enter your email address"
                  onChange={handleChange}
                  helperText={formErrors.email}
                />
              </div>

              <div className=" account_activation_btn">
                <button>Submit</button>
              </div>
            </form>

            <div className="nav_login_register_btn">
              <NavLink className="login_nav_btn" to="/login">
                Login
              </NavLink>
              <span>|</span>
              <NavLink className="login_nav_btn" to="/register">
                Register
              </NavLink>
            </div>
          </div>
          {/* </div> */}
        </div>
      </div>
    </>
  );
};

export default ForgetPassword;
