import React, { useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {
  TextField,
  // IconButton,
  // Autocomplete,
  FormControl,
  InputLabel,
} from "@mui/material";
// import SearchIcon from "@mui/icons-material/Search";
import { reactIcons } from "../../utils/icons";

const SearchInput = (props) => {
  const { setSearchCourse, setCurrentPage, setCourseList } = props;
  const [isSearch, setIsSearch] = useState(false);

  // ---------------
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const [searchdata, setSearchdata] = useState({
    search_name: "",
    sort_price: "",
  });

  const handleChange = (e) => {
    if (e.target !== undefined) {
      let { name, value } = e.target;
      setSearchdata((searchdata) => ({ ...searchdata, [name]: value }));
      setIsSearch(true);
    }
  };

  const findCourse = async () => {
    if (isSearch) {
      setIsSearch(false);
      setSearchCourse(searchdata);
      setCurrentPage(0);
      await setCourseList([]);
    }
  };
  useEffect(() => {
    const findCourse = async () => {
      if (isSearch) {
        setIsSearch(false);
        setSearchCourse(searchdata);
        setCurrentPage(0);
        await setCourseList([]);
      }
    };
    if (searchdata.sort_price !== "") {
      findCourse();
    }
  }, [isSearch, searchdata, setCourseList, setCurrentPage, setSearchCourse]);

  const handleClear = () => {
    // if(searchdata.search_name != "" || searchdata.sort_price != "" ){
    setSearchdata({ search_name: "", sort_price: "" });
    setSearchCourse({ search_name: "", sort_price: "" });
    setCourseList([]);
    setCurrentPage(0);
    // }
  };

  return (
    <div className="page_data_mid_inner_input make_smallsearch course-search">
      <div className="input_search_new  new-all-inputs searchby_namecourse">
        <TextField
          sx={{
            width: "100%",
          }}
          name="search_name"
          className="input_username new-edit-main-all"
          id="outlined-basic"
          value={searchdata.search_name}
          placeholder="Search Courses"
          onChange={(e) => {
            handleChange(e);
          }}
          val
        />
        <button className="search_icon" onClick={() => findCourse()}>
          {reactIcons.search}
        </button>
      </div>
      <div className="sort-sec">
        <div className="prd_Sort_by">
          <span className="new_sort_title">Sort by name</span>
          <div className="sort_select  new-all-inputs">
            <FormControl fullWidth>
              <Select
                value={searchdata.sort_price}
                onChange={(e) => {
                  handleChange(e);
                }}
                name="sort_price"
                // displayEmpty
                placeholder="sort by"
                inputProps={{ "aria-label": "Without label" }}
                labelId="demo-simple-select-label"
                className="new_sort_list new-edit-main-all"
                id="demo-simple-select"
                label="Low to High"
                defaultValue=""
              >
                {/* <MenuItem value="">
              <em>Low to High</em>
               
            </MenuItem> */}
                {/* <MenuItem value={10}>Sort by Price</MenuItem> */}
                {/* <MenuItem value="name_az">Low to high</MenuItem>
              <MenuItem value="name_za">High to low</MenuItem> */}
                <MenuItem value="name_az">A to Z</MenuItem>
                <MenuItem value="name_za">Z to A</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>

        {/* <button className="btn_findcoursenow mx-1" onClick={() => findCourse()}>
          {reactIcons.search}
        </button> */}
      </div>

      {/* <div className="clearbtn_searchdiv">
        <button
          type="button"
          className="upload_btn"
          onClick={() => {
            handleClear();
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="26"
            viewBox="0 0 32 32"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M29.3331 21.3334V10.6667C29.3331 9.60582 28.9117 8.58841 28.1616 7.83826C27.4114 7.08811 26.394 6.66669 25.3331 6.66669H10.8918C9.7398 6.66669 8.64513 7.16269 7.88513 8.02935L3.20646 13.3627C2.56664 14.0923 2.21387 15.0296 2.21387 16C2.21387 16.9704 2.56664 17.9078 3.20646 18.6374L7.88513 23.9707C8.26036 24.3988 8.72271 24.7419 9.24123 24.9769C9.75976 25.2119 10.3225 25.3334 10.8918 25.3334H25.3331C26.394 25.3334 27.4114 24.9119 28.1616 24.1618C28.9117 23.4116 29.3331 22.3942 29.3331 21.3334ZM26.6665 10.6667V21.3334C26.6665 21.687 26.526 22.0261 26.2759 22.2762C26.0259 22.5262 25.6868 22.6667 25.3331 22.6667H10.8918C10.7019 22.6667 10.5143 22.6261 10.3413 22.5477C10.1684 22.4693 10.0143 22.3548 9.88913 22.212L5.2118 16.8787C4.9985 16.6357 4.88088 16.3234 4.88088 16C4.88088 15.6767 4.9985 15.3644 5.2118 15.1214L9.88913 9.78802C10.0143 9.64522 10.1684 9.53078 10.3413 9.45237C10.5143 9.37396 10.7019 9.33338 10.8918 9.33335H25.3331C25.6868 9.33335 26.0259 9.47383 26.2759 9.72388C26.526 9.97393 26.6665 10.3131 26.6665 10.6667ZM15.4478 16L13.7238 17.724C13.4809 17.9755 13.3465 18.3123 13.3496 18.6619C13.3526 19.0115 13.4928 19.3459 13.74 19.5931C13.9873 19.8403 14.3217 19.9805 14.6713 19.9836C15.0209 19.9866 15.3577 19.8522 15.6091 19.6094L17.3331 17.8854L19.0571 19.6094C19.3086 19.8522 19.6454 19.9866 19.995 19.9836C20.3446 19.9805 20.679 19.8403 20.9262 19.5931C21.1734 19.3459 21.3137 19.0115 21.3167 18.6619C21.3197 18.3123 21.1853 17.9755 20.9425 17.724L19.2185 16L20.9425 14.276C21.1853 14.0246 21.3197 13.6877 21.3167 13.3382C21.3137 12.9886 21.1734 12.6541 20.9262 12.4069C20.679 12.1597 20.3446 12.0195 19.995 12.0165C19.6454 12.0134 19.3086 12.1478 19.0571 12.3907L17.3331 14.1147L15.6091 12.3907C15.3577 12.1478 15.0209 12.0134 14.6713 12.0165C14.3217 12.0195 13.9873 12.1597 13.74 12.4069C13.4928 12.6541 13.3526 12.9886 13.3496 13.3382C13.3465 13.6877 13.4809 14.0246 13.7238 14.276L15.4478 16Z"
              fill="white"
            />
          </svg>
        </button>
      </div> */}

      {/* <div className="left_searchcourses">
        <div className="search_course new-all-inputs">
          <FormControl fullWidth>
            <InputLabel
              sx={{
                color: "#fff",
              }}
              id="demo-simple-select-helper-label"
            >
              Category
            </InputLabel>
            <Select
              className="input_username new-edit-main-all"
              id="demo-simple-select-helper-label"
              label="Sort By price (required)"
              name="sort_price"
              // value={formData.category}
              onChange={(e) => {
                handleChange(e);
              }}
              MenuProps={MenuProps}
            >
              <MenuItem value={10}>Sort-1</MenuItem>
              <MenuItem value={20}>Sort-2</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div className="">
          <button className="btn_findcoursenow" onClick={() => findCourse()}>
            Find
          </button>
        </div>
      </div> */}
    </div>
  );
};

export default SearchInput;
