import React, { useEffect, useState } from 'react';
import RecordNotfound from '../Home/RecordNotfound';
import Loader from '../../hooks/Loader';
import { getUserAlbumList } from '../../actions/uploadBlogs';
import UpdateAlbumModal from '../Modal/UpdateAlbumModal';
import { cutString } from '../../utils/basicReq';
import { useNavigate } from 'react-router-dom';

export default function Albums() {
    const navigate = useNavigate();
    const [isLoader, setIsLoader] = useState(true);
    const [albumList, setAlbumList] = useState([]);
    const [selectedData, setSelectedData] = useState({});
    const [show, setShow] = useState(false);

    const handleEdit = (item) => {
        setSelectedData(item);
        setShow(true);
    };

    useEffect(() => {
        getAlbumList();
    }, []);
    const getAlbumList = () => {
        getUserAlbumList(setAlbumList, setIsLoader);
    };

    return (
        <div className='notification-page'>
            <div className='top-heading p-2'>
                <div className='row'>
                    <div className='col-xl-10 col-lg-9 col-md-8 col-sm-8 col-8 '>
                        <h2>Albums</h2>
                    </div>
                    <div className='col-xl-2 col-lg-3 col-md-4 col-sm-4 col-4'></div>
                </div>
            </div>

            <div className='product-table table-responsive'>
                <table class='table '>
                    <tr className='product-list-table '>
                        <th>Image</th>
                        <th>Album Name</th>
                        <th>Description</th>
                        <th>Views</th>
                        <th>Actions</th>
                    </tr>
                    {isLoader == true ? (
                        <tr>
                            <td colSpan={6}>
                                <Loader />
                            </td>
                        </tr>
                    ) : (
                        <>
                            {albumList != undefined && albumList.length > 0 ? (
                                <>
                                    {' '}
                                    {albumList.map((value, index) => {
                                        return (
                                            <tr className='product_history_image' key={index}>
                                                <td>
                                                    <img src={value.image || '/images/upload_img.png'} />
                                                </td>
                                                <td>{value.name}</td>
                                                <td>{cutString(value.description, 45)}</td>
                                                <td>{value.view_count}</td>
                                                <td className='main_popup_inner1 gap-5 d-flex' style={{paddingBottom:'20px'}}>
                                                    <button className='custom-btn' onClick={() => navigate(`/album-details/${value.id}`)}>View</button>
                                                    <button className='custom-btn' onClick={() => handleEdit(value)}>Edit</button>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </>
                            ) : (
                                <tr>
                                    <td colSpan={6}>
                                        <div className='text-light'>
                                            <RecordNotfound />
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </>
                    )}
                </table>
            </div>
            <UpdateAlbumModal
                setUploadLoad={setIsLoader}
                close={() => setShow(false)}
                setShow={() => { getAlbumList(); setShow(false); }}
                show={show}
                data={selectedData}
            />
        </div>
    );
}
