/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { FooterBig, Header } from "../components";
import axios from "../axios";
import { errorMsg } from "../actions/customFn";
import { reactIcons } from "../utils/icons";
import OwlCarousel from "react-owl-carousel";
import { NavLink, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import ReactPlayer from "react-player";
import AudioPlayer from "../components/AudioPlayerCard";
import { asyncPostPlay, getAllPost } from "../actions/uploadBlogs";
import WeekSongs from "./WeekSongs";
import Loader from "../hooks/Loader";

const allMusic = {
  loop: false,
  margin: 0,
  nav: false,
  dots: false,
  autoplay: false,
  autoplaySpeed: 3000,
  // navText: ["<div dangerouslysetinnerhtml=" + __html + " ></div>"],
  responsive: {
    0: {
      items: 1,
    },

    400: {
      items: 2,
    },
    600: {
      items: 3,
    },
    1000: {
      items: 4,
    },
    1200: {
      items: 5,
    },
  },
};

const TheBayPage = () => {
  const navigate = useNavigate();

  const [featureBlogs, setFeatureBlogs] = useState([]);
  const [videoPost, setVideoPost] = useState([]);
  const [artistPost, setArtistPost] = useState([]);
  const [isLoader, setIsLoader] = useState(true);
  const [hasMore, setHasMore] = useState(false);
  const [pagecount, setPagecount] = useState(0);
  const [topArtist, setTopArtist] = useState([]);
  const [videoLoader, setVideoLoader] = useState(true);
  const [featureLoader, setFeatureLoader] = useState(true);
  const [artistLoader, setArtistLoader] = useState(true);

  const getTopArtist = async () => {
    const user_id = localStorage.getItem("star_uId");
    const user_password = localStorage.getItem("star_upass");
    try {
      const params = {
        customer_id: user_id,
        password: user_password,
      };
      const response = await axios.post("/top-artist", params);
      if (response.data.status) {
        setTopArtist(response.data.data);
        setArtistLoader(false);
      } else {
        errorMsg(response.data.message);
        setArtistLoader(false);
      }
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response.status !== undefined) {
          if (error.response.status === 401) {
            setArtistLoader(false);
            //   errorMsg("Somthing went wrong...");
          } else {
            console.log(`${error.message}-${error.response.status}`);
            setArtistLoader(false);
          }
        }
      }
    }
  };

  const getFeaturedSongs = async () => {
    const user_id = localStorage.getItem("star_uId");
    const user_password = localStorage.getItem("star_upass");
    try {
      const params = {
        customer_id: user_id,
        password: user_password,
      };
      const response = await axios.post("/get-featured-blogs", params);
      if (response.data.status) {
        setFeatureBlogs(response.data.data);
        setFeatureLoader(false);
      } else {
        errorMsg(response.data.message);
        setFeatureLoader(false);
      }
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response.status !== undefined) {
          if (error.response.status === 401) {
            setFeatureLoader(false);
            //   errorMsg("Somthing went wrong...");
          } else {
            console.log(`${error.message}-${error.response.status}`);
            setFeatureLoader(false);
          }
        }
      }
    }
  };

  const getVideoSongs = async () => {
    const user_id = localStorage.getItem("star_uId");
    const user_password = localStorage.getItem("star_upass");
    try {
      const params = {
        customer_id: user_id,
        password: user_password,
      };
      const response = await axios.post("/get-most-view-video", params);
      if (response.data.status) {
        if (response.data.data.length > 0) {
          setVideoPost(response.data.data);
          setVideoLoader(false);
        } else {
          setVideoLoader(false);
        }
      } else {
        setVideoPost([]);
        setVideoLoader(false);
      }
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response.status !== undefined) {
          if (error.response.status === 401) {
            //   errorMsg("Somthing went wrong...");
            setVideoLoader(false);
          } else {
            console.log(`${error.message}-${error.response.status}`);
            setVideoLoader(false);
          }
        }
      }
    }
  };

  useEffect(() => {
    getVideoSongs();
    getFeaturedSongs();
    getTopArtist();
    getAllPost(setArtistPost, setPagecount, 0, setIsLoader, setHasMore);
  }, []);

  const videoSong = {
    loop: false,
    margin: 0,
    nav: true,
    dots: false,
    autoplay: false,
    autoplaySpeed: 3000,
    // navText: ["<div dangerouslysetinnerhtml=" + __html + " ></div>"],
    responsive: {
      0: {
        items: 1,
      },

      400: {
        items: 2,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 4,
      },
      1200: {
        items: 5,
      },
    },
  };

  const cardData = [
    {
      artistName: "Shreya Ghoshal",
      listeners: "14,574,534",
      title: "Shreya Ghoshal",
      updateInfo: "",
      imageSrc: "/images/shreya.png",
      profileImage: "/images/fresh-profile-1.png",
      profileName: "Dheerendra singh rajput",
    },
    {
      artistName: "Neha Kakkar",
      listeners: "4,574,534",
      title: "Neha Kakkar",
      updateInfo: "",
      imageSrc: "/images/neha.png",
      profileImage: "/images/fresh-profile-1.png",
      profileName: "Dheerendra singh rajput",
    },
    {
      artistName: "Justin Bieber",
      listeners: "4,574,534",
      title: "Justin Bieber",
      updateInfo: "",
      imageSrc: "/images/justin.png",
      profileImage: "/images/fresh-profile-1.png",
      profileName: "Krishnapal patel ",
    },
    {
      artistName: "Diljit Dosanjh",
      listeners: "4,574,534",
      title: "Diljit Dosanjh",
      updateInfo: "",
      imageSrc: "/images/diljit.png",
      profileImage: "/images/fresh-profile-1.png",
      profileName: "Krishnapal patel ",
    },
    {
      artistName: "Atif Aslam",
      listeners: "5,008,350",
      title: "Atif Aslam",
      updateInfo: "",
      imageSrc: "/images/atif.png",
      profileImage: "/images/fresh-profile-1.png",
      profileName: "kalika patel ",
    },
    {
      artistName: "Badshah",
      listeners: "5,530,147",
      title: "Badshah",
      updateInfo: "",
      imageSrc: "/images/badshah.png",
      profileImage: "/images/fresh-profile-1.png",
      profileName: "Rahul singh patel ",
    },
    {
      artistName: "Nucleya ",
      listeners: "92,179",
      title: "Nucleya ",
      updateInfo: " · 30 Songs",
      imageSrc: "/images/nucleya.png",
      profileImage: "/images/fresh-profile-1.png",
      profileName: "Kalika patel",
    },
    {
      artistName: "Armaan Malik",
      listeners: "5,008,350",
      title: "Armaan Malik",
      updateInfo: " · 30 Songs",
      imageSrc: "/images/arman.png",
      profileImage: "/images/fresh-profile-1.png",
      profileName: "Kalika patel",
    },
    {
      artistName: "Sanam Puri",
      listeners: "509,352",
      title: "Sanam Puri",
      updateInfo: " · 30 Songs",
      imageSrc: "/images/sanam.png",
      profileImage: "/images/fresh-profile-1.png",
      profileName: "Kalika patel",
    },
  ];
  const categories = [
    {
      title: "Hip-Hop",
      link: "hip_hop",
      updateInfo: "",
      imageSrc: "/images/trending-song.png",
      profileImage: "/images/fresh-profile-1.png",
      profileName: "Krishnapal patel",
    },
    {
      title: "Rock",
      link: "rock",
      updateInfo: " ",
      imageSrc: "/images/most-love.png",
      profileImage: "/images/fresh-profile-1.png",
      profileName: "Amit patel",
    },
    {
      title: "Pop",
      link: "pop",
      updateInfo: " ",
      imageSrc: "/images/best-dance.png",
      profileImage: "/images/fresh-profile-1.png",
      profileName: "Deendayal patel",
    },
    {
      title: "Jazz",
      link: "jazz",
      updateInfo: " ",
      imageSrc: "/images/asli-pop.png",
      profileImage: "/images/fresh-profile-1.png",
      profileName: "rahul patel",
    },
  ];
  return (
    <main className="background-cover all_page_bg ">
      <div className="container-new">
        <Header />
        <WeekSongs navigate={navigate} />
        {/* ------Top artist---- */}
        <div className="tranding_courses_section py-15">
          <div className="background-none  many-courses">
            <div className="many-courses_heading">
              <h5>TOP ARTIST</h5>
            </div>
            {topArtist?.length > 0 && (
              <OwlCarousel
                {...videoSong}
                className="owl-theme"
                nav                
                margin={32}
              >
                {topArtist.map((card, index) => (
                  <NavLink to="" className="max-w-full course-card" key={index}>
                    <div className="cc-img-sec">
                      <div className="overlay-bg"></div>
                      <img
                        className="img-card-200"
                        src={card.author_image}
                        alt={card.title || "Anonymous"}
                      />
                    </div>
                    <div className="cc-text-sec h-118">
                      <div className="mb-2">
                        <p className="artist-name">
                          Artist · {card.total_play_count || 0} Listeners
                        </p>
                        <h5 className="video-font title">{card.author_name}</h5>
                        <p className="para clamped_text-1">{''}</p>
                      </div>
                      {/* <div className="d-flex align-items-center gap-1 justify-content-between w-100 cc-profile">
                         <div className="d-flex w-100">
                        <img
                          src={card.profileImage}
                          className="rounded-circle mr-2"
                          style={{ width: "22px", height: "22px" }}
                          alt={card.profileName}
                        />
                        <h3 className="font-medium text-truncate text-capitalize">
                          {card.profileName}
                        </h3>
                      </div> 
                      </div> */}
                    </div>
                  </NavLink>
                ))}
              </OwlCarousel>
            )}
            {artistLoader && <Loader />}
          </div>
        </div>
        {/* ------video song---- */}

        <div className="tranding_courses_section py-15">
          <div className="background-none  many-courses">
            <div className="many-courses_heading">
              <h5>VIDEO SONGS </h5>
              <button onClick={() => navigate("/all-posts/video")}>
                View All
              </button>
            </div>
            {videoPost?.length > 0 && (
              <OwlCarousel
                // key={`carousel_tranding_courses_${Date.now()}`}
                {...videoSong}
                className="owl-theme"
                nav                
                margin={32}
              >
                {videoPost.map((value, index) => (
                  <div
                    
                    className="max-w-full course-card"
                  >
                    <div className="cc-img-sec">
                      <div className="overlay-bg"></div>
                      <ReactPlayer
                        url={value.video}
                        controls
                        onEnded={()=>asyncPostPlay(value.id)}

                        width="100%"
                        height="235px"
                        className="img-card-200"
                      />
                    </div>
                    <div className="cc-text-sec">
                      <div className="mb-2">
                        <h5 className="video-font title">
                          {value.title || "Anonymous"}
                        </h5>
                        {/* <p className="para clamped_text-1">Snoop Dogg</p> */}
                      </div>
                      <div className="d-flex align-items-center gap-1 justify-content-between w-100 cc-profile">
                        <div className="d-flex w-50">
                          <img
                            src={value.author_image}
                            className="rounded-circle mr-2"
                            style={{ width: "22px", height: "22px" }}
                          />
                          <h3 className="font-medium text-truncate text-capitalize">
                            {value.author_name}
                          </h3>
                        </div>

                        <Button variant="dark" className="tp-mint">
                        Mint {value?.mint_data[0]?.fill || 0}
                        </Button>
                      </div>
                    </div>
                  </div>
                ))}
              </OwlCarousel>
            )}
            {videoLoader && <Loader />}
          </div>
        </div>

        {/* ********* feature song  ******** */}

        <div className="viral_video_section py-15 ">
          <div className="background-none many-courses mp-t">
            <div className="many-courses_heading">
              <h5>FEATURED SONG</h5>
              <button onClick={() => navigate("/all-posts/feature")}>
                View All
              </button>
            </div>
            {featureBlogs?.length > 0 && (
              <div className="vv-card-group">
                {featureBlogs?.map((item, index) => {
                  return (
                    <div className="vv-card">
                      <h6 className="vv-count">{index + 1}</h6>
                      <img src={item.image} className="vv-img" />
                      <div>
                        <h5 className="break-word vv-title">
                          {item.title || "Anonymous"}
                        </h5>
                        <div className="vv-bottom">
                          <div>
                            <h6 className="vv-artist">{item.author_name}</h6>
                          </div>
                          <div className="position-relative">
                            <div className="vv-point" />
                            <p className="vv-artist">
                             Mint {item?.mint_data[0]?.fill || 0}
                            </p>
                          </div>
                          {item.endsIn && (
                            <div className="position-relative">
                              <div className="vv-point" />
                              <p className="vv-artist">{item.endsIn}</p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            {featureLoader && <Loader />}
          </div>
        </div>
        {/* artist update  */}

        <div className="artist-update">
          <div className="many-courses_heading">
            <h5>ARTIST UPDATE</h5>
          </div>
          {artistPost.length > 0 && (
            <div className="music-slider">
              <OwlCarousel
                // key={`carousel_tranding_courses_${Date.now()}`}
                {...allMusic}
                className="owl-theme"
                nav
                
                margin={32}
              >
                {artistPost.map((value, index) => (
                  <div key={index} className="music-slider-card trending-post">
                    <div className="d-flex justify-content-between align-items-center gap-2 mb-2 tp-heading">
                      <div className="d-flex align-items-center gap-10">
                        <img
                          src={value.author_image}
                          className="rounded-circle border border-white"
                          width={40}
                          height={40}
                          alt=""
                        />
                        <h6 className="profile-name-heading flex-1 text-16 capitalize font-poppins font-medium truncate popins">
                          {value.author_name}
                        </h6>
                      </div>
                      <span className="text-14 text-gray-300 popins font-500">
                        1d
                      </span>
                    </div>
                    <div className="w-100">
                      <div className="">
                        <p>{value.title}</p>

                        {value.media_type === "video" ? (
                          <ReactPlayer
                            url={value.video}
                            onEnded={()=>asyncPostPlay(value.id)}

                            controls
                            width="100%"
                            height="235px"
                          />
                        ) : value.media_type === "audio" ? (
                          <AudioPlayer
                          id={value.id}
                            src={value.audio}
                            backgroundImage={value.thumbnail}
                          />
                        ) : (
                          <img src={value.image} className="song-img" />
                        )}
                      </div>
                    </div>
                    <div>
                      {/* <div className="d-flex align-items-center justify-content-between mt-2">
                        <div className="d-flex flex-1 align-items-center gap-10">
                          <div className="d-flex align-items-center gap-5">
                            <span className="text-gray-300 text-20">
                              {reactIcons.circleUp}
                            </span>
                            <span className="text-gray-300 font-poppins text-14 font-medium">
                              0
                            </span>
                          </div>
                          <div className="d-flex align-items-center gap-5">
                            <span className="text-gray-300 text-20">
                              {reactIcons.repeat}
                            </span>
                            <span className="text-gray-300 font-poppins text-14 font-medium">
                              0
                            </span>
                          </div>
                          <div className="d-flex align-items-center gap-5">
                            <span className="text-gray-300 text-20">
                              {reactIcons.message}
                            </span>
                            <span className="text-gray-300 font-poppins text-14 font-medium">
                              0
                            </span>
                          </div>
                        </div>
                          <Button variant="dark" className="tp-mint">
                          {value?.mint_data[0]?.fill || 0}
                          </Button>
                      </div> */}
                    </div>
                  </div>
                ))}
              </OwlCarousel>
            </div>
          )}
          {isLoader && <Loader />}
        </div>
        {/* categories  */}
        <div className="categories">
          <div className="many-courses_heading">
            <h5>CATEGORIES</h5>
            {/* <button>View All</button> */}
          </div>
          <div className="categories-slider">
            <OwlCarousel
              // key={`carousel_tranding_courses_${Date.now()}`}
              {...allMusic}
              className="owl-theme"
              nav
              loop
              disabled
              margin={32}
            >
              {categories.map((card, index) => (
                <NavLink
                  to={`/categories/${card.link}`}
                  className="max-w-full categories-card"
                  key={index}
                >
                  <div className="categories-img">
                    <img src={card.imageSrc} className="" alt={card.title} />
                    <div className="overlay-gradient"></div>
                  </div>
                  <div className="categories-bg"></div>
                  <div className="categories-details">
                    <h4 className="video-font">{card.title}</h4>
                    <p className="clamped_text-1">{card.updateInfo}</p>
                    {/* <div className="profile-name">
                      <img
                        src={card.profileImage}
                        className="rounded-circle mr-2"
                        style={{ width: "22px", height: "22px" }}
                        alt={card.profileName}
                      />
                      <h3 className="font-medium text-truncate text-capitalize">
                        {card.profileName}
                      </h3>
                    </div> */}
                  </div>
                </NavLink>
              ))}
            </OwlCarousel>
          </div>
        </div>
        <FooterBig additionalClass={"container-new"} />
      </div>
    </main>
  );
};

export default TheBayPage;
