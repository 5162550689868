import axios from "axios";

export async function getAuthData(url) {
  const api_url = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("token");
  // console.log(token, "apihandler token");
  const route_url = api_url + url;
  const response = await axios
    .get(route_url, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((e) => e.response);

  return response;
}
