import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faStarHalf } from "@fortawesome/fontawesome-free-solid";
import { Link, useNavigate } from "react-router-dom";
import ReactStars from "react-rating-stars-component";

import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../../hooks/Loader";
import RecordNotfound from "../Home/RecordNotfound";
const GridCourse = (props) => {
  const { courselist } = props;
  const reactRatings = {
    edit: false,
    activeColor: "#fc5301",
    isHalf: true,
    emptyIcon: <FontAwesomeIcon icon={faStar} />,
    halfIcon: <FontAwesomeIcon icon={faStarHalf} />,
    filledIcon: <FontAwesomeIcon icon={faStar} />,
  };

  const navigate = useNavigate();
  const handleRoute = (url) => {
    navigate(url);
  };

  return (
    <>
      <div className="wishlist_main_product gridcourse" id="scrollableDiv">
        <div className="">
          {courselist.isLoader == undefined || courselist.isLoader == true ? (
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 courses_card">
              <Loader />
            </div>
          ) : courselist.courselist != undefined &&
            courselist.courselist.length > 0 ? (
            <InfiniteScroll
              dataLength={courselist.courselist.length}
              next={courselist.handleLoadMore}
              hasMore={courselist.hasMore}
              loader={<Loader />}
              scrollableTarget="scrollableDiv"
              refreshFunction={courselist.refresh}
              endMessage={
                <p style={{ textAlign: "center" }}>
                  <b>Yay! You have seen it all</b>
                </p>
              }
              pullDownToRefresh
              pullDownToRefreshThreshold={50}
            // pullDownToRefreshContent={
            //   <h3 style={{ textAlign: "center" }}>Pull down to refresh</h3>
            // }
            // releaseToRefreshContent={
            //   <h3 style={{ textAlign: "center" }}>Release to refresh</h3>
            // }
            >
              <div className="row">
                {courselist.courselist.map(function (value, index) {
                  return (
                    <div
                      className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 courses_card"
                      key={index}
                    >
                      <div className="inner_list_main">
                        <div className="ne-inner-all-dis">
                          <div className="ne-inner-image">
                            {value.image == "" || value.image == undefined ? (
                              <div
                                onClick={() =>
                                  handleRoute(`/courses-detail/${value.id}`)
                                }
                                className="product_img "
                                style={{
                                  backgroundImage: `url(${"/images/timepost8.png"})`,
                                }}
                              ></div>
                            ) : (
                              <div
                                onClick={() =>
                                  handleRoute(`/courses-detail/${value.id}`)
                                }
                                className="product_img "
                                style={{
                                  backgroundImage: `url(${value.image})`,
                                }}
                              ></div>
                            )}
                          </div>
                          <div className="ne-inner-text">
                            <div className="wish_lsit_info">
                              {value.category_id !== undefined ? (
                                <button
                                  onClick={() =>
                                    handleRoute(
                                      `/courses-category/${value.category_id}`
                                    )
                                  }
                                >
                                  {value.category_title}
                                </button>
                              ) : (
                                <h1></h1>
                              )}

                              {value.title ? <h2>{value.title}</h2> : <h1></h1>}
                              <h6
                                dangerouslySetInnerHTML={{
                                  __html: value.description,
                                }}
                              ></h6>
                            </div>
                          </div>

                          <div className="ne-inner-content">
                            <div className="div_last_main grid-last-main">
                              <div className="product_price">
                                <div className="wish_lsit_info">
                                  <div className=" prd_star_rating">
                                    <ul>
                                      {/* <li> */}
                                      <ReactStars
                                        {...reactRatings}
                                        size={16}
                                        value={Number(value.rating)}
                                      />
                                      {/* </li> */}
                                      <li className="star_point">
                                        {value.rating}
                                      </li>
                                    </ul>
                                  </div>

                                  <Link
                                    to={`/friend_user_profile/${value.author_id}`}
                                    state={{ val: 1 }}
                                  >
                                    <div className="latestblogs_right_profile1">
                                      <div className="right_profile_left_bg">
                                        <img
                                          src={value.author_image}
                                          alt={value.author_name}
                                        />
                                      </div>
                                      <span>{value.author_name}</span>
                                    </div>
                                  </Link>
                                </div>

                                <div className="price_main_tex">
                                  <p>
                                    {" "}
                                    <span>$</span>
                                    {value.price}
                                  </p>
                                  {/* <h3 className="per_person">13 Lectures</h3> */}
                                </div>
                              </div>

                              <div className="card_Buy_now">
                                <button
                                  onClick={() => {
                                    navigate(`/courses-detail/${value.id}`);
                                  }}
                                >
                                  Buy now
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </InfiniteScroll>
          ) : (
            <RecordNotfound />
          )}
        </div>
      </div >
    </>
  );
};

export default GridCourse;
