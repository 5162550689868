import axios from "axios";
// const instance = axios.create({ baseURL: 'https://starbay.infosparkles.com/admin/api' });
const instance = axios.create({ baseURL: "https://starbay.xyz/admin/api" });
instance.defaults.headers.common["Content-Type"] =
  "application/x-www-form-urlencoded";
instance.defaults.params = {
  // 'user_id' : localStorage.getItem("star_uId"),
  // 'user_id' : localStorage.getItem("star_upass"),
};
export default instance;
