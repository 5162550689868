import React, { useEffect, useState } from "react";
import { errorMsg, images } from "../../actions/customFn";
import {
  asyncAddToFriend,
  asyncAddToFriendList,
  asynccancelrequest,
} from "../../actions/friendAction";
import Loader from "../../hooks/Loader";
import RecordNotfound from "../Home/RecordNotfound";
import { useDispatch } from "react-redux";

const Addtofriend = (props) => {
  const {
    setIsSearch,
    isSearch,
    handleChat,
    handleUnfriend,
    handleacceptfriend,
    handlerejectfriend,
  } = props;
  const [AddFriendList, setAddFriendList] = useState([]);
  const [FriendsId, setFriendsId] = useState([]);
  const [isLoader, setIsLoader] = useState(true);
  const [reRender, setRerender] = useState(false);
  const dispatch = useDispatch();
  // Add To Friend List
  useEffect(() => {
    setIsLoader(true);
    asyncAddToFriendList(setAddFriendList, setIsLoader, isSearch);
  }, [reRender, isSearch]);

  // Add To Friend
  const handleaddtofriend = async (customer_id) => {
    try {
      asyncAddToFriend(customer_id, setFriendsId, FriendsId);
    } catch (error) {
      errorMsg("something went wrong!");
    }
  };

  // Cancel Request
  const handlecancelrequest = async (customer_id) => {
    try {
      asynccancelrequest(customer_id, setRerender, reRender);
    } catch (error) {
      errorMsg("something went wrong!");
    }
  };

  return (
    <>
      {isLoader == true ? (
        <Loader />
      ) : (
        <div className="add-to-friend_main">
          <div className="row m-0">
            {/* {AddFriendList != undefined &&
              AddFriendList.map(function(value, index) {
                return (
                  <div
                    className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12"
                    key={index}
                  >
                    <div className="friends_card_inner">
                      <div className="tab-info-dis">
                        <div className="user-img-tab">
                          <img src={"/images/friend-img1.png"} alt="" />
                        </div>
                        <div className="user-text-info-heading_name">
                          <h1>
                            {" "}
                            {value.first_name} {value.last_name}
                          </h1>
                          <div className="addto-friens_desc">
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="15"
                                viewBox="0 0 15 15"
                                fill="none"
                              >
                                <path
                                  d="M1.5 14.25H2.25V4.5H1.5C1.10218 4.5 0.720644 4.65804 0.43934 4.93934C0.158035 5.22064 0 5.60218 0 6V12.75C0 13.1478 0.158035 13.5294 0.43934 13.8107C0.720644 14.092 1.10218 14.25 1.5 14.25ZM13.5 4.5H8.25L9.0915 1.974C9.16659 1.74856 9.18704 1.50851 9.15118 1.27362C9.11532 1.03872 9.02417 0.81571 8.88523 0.622946C8.7463 0.430182 8.56356 0.273182 8.35206 0.164877C8.14056 0.056572 7.90636 6.15846e-05 7.66875 0H7.5L3.75 4.0785V14.25H12L14.934 7.803L15 7.5V6C15 5.60218 14.842 5.22064 14.5607 4.93934C14.2794 4.65804 13.8978 4.5 13.5 4.5Z"
                                  fill="#FFFA55"
                                />
                              </svg>
                            </span>
                            <p>1025</p>
                          </div>
                        </div>
                      </div>

                      {value.send_request == true ||
                      FriendsId.includes(value.customer_id) ? (
                        <div className="add-to-friend-btn-clock">
                          <span>
                            {" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                            >
                              <path
                                d="M11.2501 3.75C10.7566 3.75063 10.2645 3.79983 9.78069 3.89688C10.0398 4.71532 10.0692 5.5892 9.86565 6.4232C9.6621 7.2572 9.23342 8.01929 8.62639 8.62632C8.01935 9.23336 7.25726 9.66204 6.42326 9.86559C5.58926 10.0691 4.71538 10.0398 3.89694 9.78063C3.59396 11.2971 3.76717 12.8702 4.39286 14.2844C5.01854 15.6987 6.0661 16.8849 7.39208 17.6807C8.71807 18.4765 10.2576 18.843 11.8 18.7299C13.3423 18.6169 14.8119 18.0298 16.0077 17.0491C17.2034 16.0684 18.0668 14.7421 18.4795 13.2517C18.8923 11.7613 18.8342 10.1798 18.3133 8.72373C17.7924 7.26764 16.8341 6.00817 15.5697 5.1178C14.3053 4.22743 12.7965 3.74968 11.2501 3.75ZM15.0001 11.875H11.2501C11.0843 11.875 10.9253 11.8092 10.8081 11.6919C10.6909 11.5747 10.6251 11.4158 10.6251 11.25V7.5C10.6251 7.33424 10.6909 7.17527 10.8081 7.05806C10.9253 6.94085 11.0843 6.875 11.2501 6.875C11.4158 6.875 11.5748 6.94085 11.692 7.05806C11.8092 7.17527 11.8751 7.33424 11.8751 7.5V10.625H15.0001C15.1658 10.625 15.3248 10.6908 15.442 10.8081C15.5592 10.9253 15.6251 11.0842 15.6251 11.25C15.6251 11.4158 15.5592 11.5747 15.442 11.6919C15.3248 11.8092 15.1658 11.875 15.0001 11.875Z"
                                fill="#FF871A"
                              />
                              <path
                                d="M9.375 5.3125C9.375 4.50902 9.13674 3.72357 8.69035 3.0555C8.24395 2.38742 7.60948 1.86672 6.86715 1.55924C6.12483 1.25176 5.308 1.17131 4.51995 1.32806C3.7319 1.48481 3.00803 1.87173 2.43988 2.43988C1.87173 3.00803 1.48481 3.7319 1.32806 4.51995C1.17131 5.308 1.25176 6.12483 1.55924 6.86715C1.86672 7.60948 2.38742 8.24395 3.0555 8.69035C3.72357 9.13674 4.50902 9.375 5.3125 9.375C6.38954 9.37368 7.42208 8.94524 8.18366 8.18366C8.94524 7.42208 9.37368 6.38954 9.375 5.3125ZM4.6875 3.75C4.6875 3.58424 4.75335 3.42527 4.87056 3.30806C4.98777 3.19085 5.14674 3.125 5.3125 3.125C5.47826 3.125 5.63723 3.19085 5.75444 3.30806C5.87165 3.42527 5.9375 3.58424 5.9375 3.75V5C5.9375 5.16576 5.87165 5.32473 5.75444 5.44194C5.63723 5.55915 5.47826 5.625 5.3125 5.625C5.14674 5.625 4.98777 5.55915 4.87056 5.44194C4.75335 5.32473 4.6875 5.16576 4.6875 5V3.75ZM4.6875 6.875C4.6875 6.75139 4.72416 6.63055 4.79283 6.52777C4.86151 6.42499 4.95912 6.34488 5.07332 6.29758C5.18753 6.25027 5.3132 6.2379 5.43443 6.26201C5.55567 6.28613 5.66704 6.34565 5.75444 6.43306C5.84185 6.52047 5.90138 6.63183 5.92549 6.75307C5.94961 6.87431 5.93723 6.99998 5.88993 7.11418C5.84262 7.22838 5.76251 7.32599 5.65973 7.39467C5.55695 7.46335 5.43612 7.5 5.3125 7.5C5.14674 7.5 4.98777 7.43415 4.87056 7.31694C4.75335 7.19973 4.6875 7.04076 4.6875 6.875Z"
                                fill="#FF871A"
                              />
                            </svg>
                          </span>
                          <button
                            className="add-to-friend-btn-Cancel"
                            onClick={() =>
                              handlecancelrequest(value.customer_id)
                            }
                          >
                            Cancel
                          </button>
                        </div>
                      ) : (
                        <div className="chat_unfrind_btn">
                          <button
                            className="unfriend_btn_main"
                            onClick={() => handleaddtofriend(value.customer_id)}
                          >
                            Send Request
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })} */}

            {AddFriendList != undefined && AddFriendList.length > 0 ? (
              <>
                {" "}
                {AddFriendList.map((value, index) => {
                  return (
                    <div
                      className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 mb-2"
                      key={index}
                    >
                      <div className="friends_card_inner">
                        <div className="tab-info-dis">
                          <div className="user-img-tab">
                            <img src={value.image} alt="" />
                          </div>
                          <div className="user-text-info-heading_name">
                            <h1> {value.username}</h1>
                            <div className="addtoFriendBtns">
                              <div className="addto-friens_desc">
                                <span>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="15"
                                    height="15"
                                    viewBox="0 0 15 15"
                                    fill="none"
                                  >
                                    <path
                                      d="M1.5 14.25H2.25V4.5H1.5C1.10218 4.5 0.720644 4.65804 0.43934 4.93934C0.158035 5.22064 0 5.60218 0 6V12.75C0 13.1478 0.158035 13.5294 0.43934 13.8107C0.720644 14.092 1.10218 14.25 1.5 14.25ZM13.5 4.5H8.25L9.0915 1.974C9.16659 1.74856 9.18704 1.50851 9.15118 1.27362C9.11532 1.03872 9.02417 0.81571 8.88523 0.622946C8.7463 0.430182 8.56356 0.273182 8.35206 0.164877C8.14056 0.056572 7.90636 6.15846e-05 7.66875 0H7.5L3.75 4.0785V14.25H12L14.934 7.803L15 7.5V6C15 5.60218 14.842 5.22064 14.5607 4.93934C14.2794 4.65804 13.8978 4.5 13.5 4.5Z"
                                      fill="#FFFA55"
                                    />
                                  </svg>
                                </span>
                                <p>{value.total_likes}</p>
                              </div>

                            </div>
                          </div>
                        </div>
                        <div>
                          {value.send_request == true ||
                            FriendsId.includes(value.customer_id) ? (
                            <div className="add-to-friend-btn-clock">
                              <span>
                                {" "}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                >
                                  <path
                                    d="M11.2501 3.75C10.7566 3.75063 10.2645 3.79983 9.78069 3.89688C10.0398 4.71532 10.0692 5.5892 9.86565 6.4232C9.6621 7.2572 9.23342 8.01929 8.62639 8.62632C8.01935 9.23336 7.25726 9.66204 6.42326 9.86559C5.58926 10.0691 4.71538 10.0398 3.89694 9.78063C3.59396 11.2971 3.76717 12.8702 4.39286 14.2844C5.01854 15.6987 6.0661 16.8849 7.39208 17.6807C8.71807 18.4765 10.2576 18.843 11.8 18.7299C13.3423 18.6169 14.8119 18.0298 16.0077 17.0491C17.2034 16.0684 18.0668 14.7421 18.4795 13.2517C18.8923 11.7613 18.8342 10.1798 18.3133 8.72373C17.7924 7.26764 16.8341 6.00817 15.5697 5.1178C14.3053 4.22743 12.7965 3.74968 11.2501 3.75ZM15.0001 11.875H11.2501C11.0843 11.875 10.9253 11.8092 10.8081 11.6919C10.6909 11.5747 10.6251 11.4158 10.6251 11.25V7.5C10.6251 7.33424 10.6909 7.17527 10.8081 7.05806C10.9253 6.94085 11.0843 6.875 11.2501 6.875C11.4158 6.875 11.5748 6.94085 11.692 7.05806C11.8092 7.17527 11.8751 7.33424 11.8751 7.5V10.625H15.0001C15.1658 10.625 15.3248 10.6908 15.442 10.8081C15.5592 10.9253 15.6251 11.0842 15.6251 11.25C15.6251 11.4158 15.5592 11.5747 15.442 11.6919C15.3248 11.8092 15.1658 11.875 15.0001 11.875Z"
                                    fill="#FF871A"
                                  />
                                  <path
                                    d="M9.375 5.3125C9.375 4.50902 9.13674 3.72357 8.69035 3.0555C8.24395 2.38742 7.60948 1.86672 6.86715 1.55924C6.12483 1.25176 5.308 1.17131 4.51995 1.32806C3.7319 1.48481 3.00803 1.87173 2.43988 2.43988C1.87173 3.00803 1.48481 3.7319 1.32806 4.51995C1.17131 5.308 1.25176 6.12483 1.55924 6.86715C1.86672 7.60948 2.38742 8.24395 3.0555 8.69035C3.72357 9.13674 4.50902 9.375 5.3125 9.375C6.38954 9.37368 7.42208 8.94524 8.18366 8.18366C8.94524 7.42208 9.37368 6.38954 9.375 5.3125ZM4.6875 3.75C4.6875 3.58424 4.75335 3.42527 4.87056 3.30806C4.98777 3.19085 5.14674 3.125 5.3125 3.125C5.47826 3.125 5.63723 3.19085 5.75444 3.30806C5.87165 3.42527 5.9375 3.58424 5.9375 3.75V5C5.9375 5.16576 5.87165 5.32473 5.75444 5.44194C5.63723 5.55915 5.47826 5.625 5.3125 5.625C5.14674 5.625 4.98777 5.55915 4.87056 5.44194C4.75335 5.32473 4.6875 5.16576 4.6875 5V3.75ZM4.6875 6.875C4.6875 6.75139 4.72416 6.63055 4.79283 6.52777C4.86151 6.42499 4.95912 6.34488 5.07332 6.29758C5.18753 6.25027 5.3132 6.2379 5.43443 6.26201C5.55567 6.28613 5.66704 6.34565 5.75444 6.43306C5.84185 6.52047 5.90138 6.63183 5.92549 6.75307C5.94961 6.87431 5.93723 6.99998 5.88993 7.11418C5.84262 7.22838 5.76251 7.32599 5.65973 7.39467C5.55695 7.46335 5.43612 7.5 5.3125 7.5C5.14674 7.5 4.98777 7.43415 4.87056 7.31694C4.75335 7.19973 4.6875 7.04076 4.6875 6.875Z"
                                    fill="#FF871A"
                                  />
                                </svg>
                              </span>
                              <button
                                className="add-to-friend-btn-Cancel mt-0"
                                onClick={() =>
                                  handlecancelrequest(value.customer_id)
                                }
                              >
                                Cancel
                              </button>
                            </div>
                          ) : value.is_friend == true ? (
                            <div className="chat_unfrind_btn">
                              <button
                                className="chat_btn_main"
                                onClick={() => {
                                  handleChat(value.customer_id);
                                }}
                              >
                                Chat
                              </button>

                              <button
                                className="unfriend_btn_main unfriend_btn_freinds"
                                onClick={(e) => {
                                  handleUnfriend(
                                    value.customer_id,
                                    AddFriendList,
                                    setAddFriendList,
                                    index,
                                    dispatch
                                  );
                                }}
                              >
                                Unfriend
                              </button>
                            </div>
                          ) : value.recive_request == true ? (
                            <div className="accept_unfrind_btn">
                              <button
                                onClick={() =>
                                  handleacceptfriend(
                                    value.customer_id,
                                    AddFriendList,
                                    setAddFriendList,
                                    dispatch,
                                    index,
                                    "add_to_friend"
                                  )
                                }
                                className="accept_btn_main new-request-btn"
                              >
                                Accept
                              </button>
                              <button
                                className="reject_btn_main"
                                onClick={() =>
                                  handlerejectfriend(
                                    value.customer_id,
                                    AddFriendList,
                                    setAddFriendList,
                                    dispatch,
                                    index,
                                    "add_to_friend"
                                  )
                                }
                              >
                                Reject
                              </button>
                            </div>
                          ) : (
                            <div className="chat_unfrind_btn">
                              <button
                                className="unfriend_btn_main mt-0"
                                onClick={() =>
                                  handleaddtofriend(value.customer_id)
                                }
                              >
                                Send Request
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <div className="friends-record">
                <RecordNotfound />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Addtofriend;
