import React, { useEffect, useRef, useState } from "react";
import {
  errorMsg,
  handleScrollError,
} from "../../actions/customFn";
import { FormHelperText, TextField } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/fontawesome-free-solid";
import { InputLabel, MenuItem, Select, FormControl } from "@mui/material"
import "quill/dist/quill.snow.css";
import SmallLoader from "react-js-loader";
import { asyncUploadBlog, asyncUploadAlbum, getUserAlbumList } from "../../actions/uploadBlogs";
// import validator from "validator";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const UploadContentModal = (props) => {
  const { setIsOpen, setUploadLoad, isRenderState } = props;

  const inputRef = useRef(null);
  const inputRef_1 = useRef(null);

  const [editorType, setEditorType] = useState("");
  const [formErrors, setFormError] = useState({});
  const [preview, setpreview] = useState({ image: "/images/upload-post1.png", thumbnail: "/images/upload-post1.png" });
  const [showCreateAlbum, setShowCreateAlbum] = useState(false)
  const [albumName, setAlbumName] = useState('')
  const [albumList, setAlbumList] = useState([]);

  const errors = {};
  // const navigate = useNavigate();

  const [formData, setFormData] = useState({
    title: "",
    description: "",
    image: "",
    thumbnail: "",
    type: 1,
    genre: ' ',
    album_id: ' '
  });

  const [buttonloader, setButtonLoader] = useState(false);
  const [albumButtonLoader, setAlbumButtonLoader] = useState(false)

  const handleClick = () => {
    inputRef.current.click();
  };

  const handleClick_1 = () => {
    inputRef_1.current.click();
  };


  useEffect(() => {
    getAlbumList();
  }, [])
  const getAlbumList = () => {
    getUserAlbumList(
      setAlbumList,
      setUploadLoad,

    );
  }

  const handleChange = (e) => {
    if (e.target !== undefined) {
      const { name, value } = e.target;
      if (name === "image" || name === "thumbnail") {
        setFormData((formData) => ({ ...formData, [name]: e.target.files[0] }));
      } else {
        setFormData((formData) => ({ ...formData, [name]: value }));
      }
    } else {
      if (editorType != "") {
        setFormData((formData) => ({ ...formData, [editorType]: e }));
      }
    }
  };

  const validateFormData = async () => {
    try {
      if (formData.title === "") {
        errors.title = "Enter post title properly ";
      }
      if (formData.description === "") {
        errors.description = "Enter description";
      }
      if (formData.image === "" || formData.image === undefined) {
        errors.image = "Please choose an image";
      }
      if (formData.thumbnail === "" || formData.thumbnail === undefined) {
        errors.thumbnail = "Please choose an thumbnail";
      }
      setFormError(errors);
    } catch (error) {
      console.log(error);
    }
  };

  const handleUploadPost = async (e) => {
    setButtonLoader(true);
    try {
      e.preventDefault();
      validateFormData();
      if (Object.keys(errors).length === 0) {
        asyncUploadBlog(
          formData,
          setIsOpen,
          setUploadLoad,
          setButtonLoader,
          isRenderState
        );
      } else {
        setButtonLoader(false);
        handleScrollError(errors);
      }
    } catch (error) {
      setButtonLoader(false);
      errorMsg("something went wrong!");
    }
  };

  // const refreshPage =()=>{
  //   setTogglePage(!togglepage);
  // }



  const fileChangedHandler = (event) => {
    console.log('event.target.files[0].type', event.target.files[0].type)
    if (event.target.files[0].type == "image/jpeg") {
      setpreview({ ...preview, [event.target.name]: URL.createObjectURL(event.target.files[0]) });
    } else {
      setpreview({ ...preview, [event.target.name]: "/images/upload-reel1.png" });
    }
  };

  const handleCreateAlbum = () => {
    setAlbumButtonLoader(true);
    try {
      if (albumName.length > 0) {
        asyncUploadAlbum(
          albumName,
          setAlbumButtonLoader,
          isRenderState,
          setAlbumList,
          setShowCreateAlbum
        ).then(()=>setShowCreateAlbum(false));

      } else {
        setAlbumButtonLoader(false);
      }
    } catch (error) {
      setAlbumButtonLoader(false);
      errorMsg("something went wrong!");
    }
  }

  console.log('formdata', formData, formErrors)

  return (
    <Modal
      show={props.isOpen}
      className="upload_post_popup post-upload-modal"
      onHide={props.close}
    >
      <FontAwesomeIcon onClick={props.close} icon={faTimes} />

      <Modal.Header>
        <h4>Create Post</h4>
      </Modal.Header>

      <div className="uploadpost_cont new-all-inputs">
        <div className="row main_popup_inner1 upload-course-page ">
          <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 new-all-inputs  mb-2">

            <FormHelperText sx={{ color: "#fff", marginBottom: "5px" }}>
              Content Type
            </FormHelperText>
            <FormControl fullWidth>
              <Select
                className=" input_username new-edit-main-all w-100"
                id="demo-simple-select-helper-label"
                name="type"
                defaultValue={1}
                onChange={handleChange}
                value={formData.type}
                MenuProps={MenuProps}
                error={formErrors.type}
                helperText={formErrors.type}
              >

                <MenuItem value={1}
                // key={index} value={value.id}
                >
                  Audio Music
                </MenuItem>
                <MenuItem
                  value={2}
                // key={index} value={value.id}
                >
                  Video Music
                </MenuItem>
                <MenuItem
                  value={3}
                // key={index} value={value.id}
                >
                  Announcement/Update
                </MenuItem>

              </Select>
            </FormControl>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 new-all-inputs mb-2">

            <FormHelperText sx={{ color: "#fff", marginBottom: "5px" }}>
              Genre
            </FormHelperText>
            <FormControl fullWidth>
              <Select
                className="input_username new-edit-main-all w-100"
                id="demo-simple-select-helper-label"
                name="genre"
                defaultValue={'Hip-Hop'}
                onChange={handleChange}
                MenuProps={MenuProps}
                error={formErrors.genre}
                helperText={formErrors.genre}
                value={formData.genre}
              >     <MenuItem value=' '
              >
                  Please Select
                </MenuItem>

                <MenuItem value='hip_hop'
                >
                  Hip-Hop
                </MenuItem>
                <MenuItem
                  value='rock'
                >
                  Rock
                </MenuItem>
                <MenuItem
                  value='pop'
                >
                  Pop
                </MenuItem>
                <MenuItem
                  value='Jazz'
                >
                  Jazz
                </MenuItem>

              </Select>
            </FormControl>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 new-all-inputs mb-2 d-flex gap-10">
            <div>
              <FormHelperText sx={{ color: "#fff", marginBottom: "5px" }}>
                Choose Album
              </FormHelperText>
              <FormControl fullWidth>
                <Select
                  className="input_username new-edit-main-all w-100"
                  id="demo-simple-select-helper-label"
                  name="album_id"
                  defaultValue={''}
                  onChange={handleChange}
                  MenuProps={MenuProps}
                  error={formErrors.album_id}
                  value={formData.album_id}
                  helperText={formErrors.album_id}
                >

                  <MenuItem
                    value=' '
                  // key={index} value={value.id}
                  > Select Album

                  </MenuItem>
                  {albumList.map((item, index) => (
                    <MenuItem
                      key={index} value={item.id}
                    > {item.name}

                    </MenuItem>
                  ))}

                </Select>
              </FormControl>
            </div>

            <h6 onClick={() => setShowCreateAlbum(true)} className="add-icone-post-upload">+</h6>

          </div>
        </div>
        {showCreateAlbum && <div className="main_popup_inner1 mb-3">
          <FormHelperText sx={{ color: "#fff", marginBottom: "5px" }}>
            Add New Album
          </FormHelperText>
          <div style={{ display: "flex", alignItems: "center", gap: '10px' }}>
            <TextField
              sx={{
                width: "100%",
                borderRight: 0,

              }}

              error={albumName == ''}
              name="title"
              className="input_username new-edit-main-all"
              id="outlined-basic"
              placeholder="Enter Album Name"
              value={albumName}
              onChange={(e) => setAlbumName(e.target.value)}
            />
            {albumButtonLoader == true ? (
              <button type="button">
                <SmallLoader
                  type="spinner-default"
                  bgColor={"#fff"}
                  color={"#fff"}
                  size={40}
                />
              </button>
            ) : (
              <button style={{ whiteSpace: "nowrap" }} onClick={handleCreateAlbum}
              >
                Create Album
              </button>
            )}

          </div>
        </div>}


        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12  mb-2">

            <div className="post_image_upload">
              <img src={preview.image} alt="" />
              {formData.type == 1 ? <h6>Upload Music (MP3, OGG)</h6> :
                formData.type == 2 ? <h6>Upload Video (MP4)</h6>
                  : <h6>Upload Image (JPG, JPEG, PNG)</h6>
              }


              <div className="file_upload_product">
                <input
                  style={{ display: "none" }}
                  id="fileInput"
                  name="image"
                  type="file"
                  inputProps={{ accept: "image/*" }}
                  onChange={(e) => {
                    handleChange(e);
                    fileChangedHandler(e);
                  }}
                  placeholder="browser"
                  // multiple
                  ref={inputRef}
                />

                <button
                  onClick={() => {
                    handleClick();
                  }}
                >
                  Browse files
                </button>
              </div>
              {formErrors.image && (
                <div className="image_error">
                  <p> {formErrors.image} </p>
                </div>
              )}
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12  mb-2">

            <div className="post_image_upload">
              <img src={preview.thumbnail} alt="" />
              <h6>Upload Thumbnail (JPG, JPEG, PNG)</h6>

              <div className="file_upload_product">
                <input
                  style={{ display: "none" }}
                  id="fileInput"
                  name="thumbnail"
                  type="file"
                  inputProps={{ accept: "image/*" }}
                  onChange={(e) => {
                    handleChange(e);
                    fileChangedHandler(e);
                  }}
                  placeholder="browser"
                  // multiple
                  ref={inputRef_1}
                />

                <button
                  onClick={() => {
                    handleClick_1();
                  }}
                >
                  Browse files
                </button>
              </div>
              {formErrors.thumbnail && (
                <div className="image_error">
                  <p> {formErrors.thumbnail} </p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className=" main_popup_inner1 mb-3">
          <FormHelperText sx={{ color: "#fff", marginBottom: "5px" }}>
            Content Title
          </FormHelperText>
          <TextField
            sx={{
              width: "100%",
            }}
            error={formErrors.title}
            name="title"
            className="input_username new-edit-main-all"
            id="outlined-basic"
            placeholder="Enter your title"
            onChange={handleChange}
            helperText={formErrors.title}
          />
        </div>
        <div className="main_popup_inner1">
          <FormHelperText sx={{ color: "#fff", marginBottom: "5px" }}>
            Description
          </FormHelperText>
          <TextField
            sx={{
              width: "100%",
            }}
            error={formErrors.description}
            name="description"
            className="input_username new-edit-main-all"
            id="outlined-basic"
            placeholder="Enter text"
            onChange={handleChange}
            multiline
            rows={4}
            helperText={formErrors.description}
          />
        </div>

        <div className="upload_btn_last">
          {buttonloader == true ? (
            <button type="button">
              <SmallLoader
                type="spinner-default"
                bgColor={"#fff"}
                color={"#fff"}
                size={40}
              />
            </button>
          ) : (
            <button
              onClick={(e) => {
                handleUploadPost(e);
              }}
            >
              Upload{" "}
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default UploadContentModal;
