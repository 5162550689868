import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { errorMsg } from "../actions/customFn";
import { TextField } from "@mui/material";
import { asyncGetPagesData } from "../actions/pageAction";
import { asyncResetPassword } from "../actions/authActions";

const ResetPassword = () => {
  const [homeDetail, setHomeDetail] = useState({});
  const navigate = useNavigate();
  const [passwordData, setPasswordData] = useState({
    password: "",
    confirm_password: "",
  });
  const [formErrors, setFormError] = useState({});
  const errors = {};

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPasswordData((formData) => ({ ...formData, [name]: value }));
  };

  const validateFormData = async () => {
    try {
      if (passwordData.password === "") {
        errors.password = "Enter Password";
      } else if (passwordData.password.length < 8) {
        errors.password = "Password must have atleast 8 character";
      }
      if (passwordData.confirm_password === "") {
        errors.confirm_password = "Enter Confirm Password";
      } else if (passwordData.password !== passwordData.confirm_password) {
        errors.confirm_password = "Confirm Password does not match";
      }
      setFormError(errors);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangePassword = async (e) => {
    try {
      e.preventDefault();
      validateFormData();
      if (Object.keys(errors).length === 0) {
        asyncResetPassword(passwordData, navigate);
      }
    } catch (error) {
      errorMsg("something went wrong!");
    }
  };

  useEffect(() => {
    asyncGetPagesData(3, setHomeDetail);
  }, []);

  return (
    <>
      <div className="Change-password-main">
        <>
          <div className="register-page reset-password">
            <div
              className="left-register"
              style={{
                backgroundImage: `url(${"/images/home-img.png"})`,
              }}
            >
              <div className="right-div">
                <h1>
                  {homeDetail.heading != undefined && homeDetail.heading != ""
                    ? homeDetail.heading
                    : ""}
                </h1>
                <p>
                  {homeDetail.short_description != undefined &&
                    homeDetail.short_description != ""
                    ? homeDetail.short_description
                    : ""}
                </p>
              </div>
            </div>

            <div className="right-register new-all-inputs">
              <div className="right-div">
                <div className="logo_heding right-top ">
                  <NavLink to="/">
                    <img src={"/images/register-R1.png"} alt="" />
                  </NavLink>
                  <h1>Reset Password</h1>
                </div>
                <form onSubmit={handleChangePassword}>
                  <div className="input_field">
                    <TextField
                      name="password"
                      error={formErrors.password}
                      sx={{
                        width: "100%",
                      }}
                      className={`input_username new-edit-main-all `}
                      id="outlined-basic"
                      label="New Password"
                      placeholder="Enter your new password"
                      onChange={handleChange}
                      helperText={formErrors.password}
                    />

                    <TextField
                      name="confirm_password"
                      error={formErrors.confirm_password}
                      sx={{
                        width: "100%",
                      }}
                      className={`input_username new-edit-main-all `}
                      id="outlined-basic"
                      label="Confirm Password"
                      placeholder="Enter your confirm password"
                      onChange={handleChange}
                      helperText={formErrors.confirm_password}
                    />
                  </div>

                  <div className=" account_activation_btn">
                    <button>Submit</button>
                  </div>
                </form>

                <div className="nav_login_register_btn">
                  <NavLink className="login_nav_btn" to="/login">
                    Login
                  </NavLink>
                  <span>|</span>
                  <NavLink className="login_nav_btn" to="/register">
                    Register
                  </NavLink>
                </div>
              </div>
              {/* </div> */}
            </div>
          </div>
        </>
      </div>
    </>
  );
};

export default ResetPassword;
