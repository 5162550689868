import React, { useState } from "react";
import { images } from "../actions/customFn";
import { useSelector } from "react-redux";
import { FooterBig, Header } from "../components";
import { motion } from "framer-motion";

import FooterMobile from "../components/Home/FooterMobile";
import { NavLink, useLocation, useParams } from "react-router-dom";

const BoughtCourse = () => {
  const { ids } = useParams();

  console.log("buyname=", ids);

  return (
    <motion.div
      initial={{ opacity: 0.2 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 1 }}
      transition={{ duration: 2 }}
    >
      <div
        className="all_page_bg"
        style={{
          backgroundImage: `url(${"/images/bg_mian.png"})`,
        }}
      >
        <Header />

        <div className="page-all-data">
          <div className="page-container">
            <div className="paga-data-dis">
              <div className="bought_course_page mt-5">
                <div className="boughtcourse_link">
                  <img src={"/images/thanks_img8.png"} alt="" />

                  <h2>
                    Thank You for Buy {ids == "course" ? "Course" : "Product"}{" "}
                  </h2>
                  <h3>
                    To Buy more {ids == "course" ? " courses" : " products"} go
                    to the
                    {ids == "course" ? " Courses List" : " Product List"}
                  </h3>

                  <h4>
                    {ids == "course" ? (
                      <NavLink to="/courses"> Continue Shopping ... </NavLink>
                    ) : (
                      <NavLink to="/product-listing">
                        Continue Shopping ...{" "}
                      </NavLink>
                    )}
                  </h4>

                  <NavLink className="bought_homelink" to="/">
                    Home
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mobile-footer-new">
          <FooterMobile />
        </div>
        <FooterBig />
      </div>
    </motion.div >
  );
};

export default BoughtCourse;
