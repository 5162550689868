import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";
// import { DatePicker } from 'react-responsive-datepicker'
// import 'react-responsive-datepicker/dist/index.css'
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import RecordNotfound from "../Home/RecordNotfound";
import Loader from "../../hooks/Loader";
import moment from "moment";
import {
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  TextField,
  FormHelperText,
} from "@mui/material";
// var ValuePiece = Date | null;

// var Value = ValuePiece | [ValuePiece, ValuePiece];

const MyOrder = (props) => {
  const {
    orderList,
    isLoader,
    productcategory,
    setSearchOrder,
    setCurrentPage,
  } = props;

  // ---------------
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const navigate = useNavigate();
  const directpage = (path) => {
    navigate(`/${path}`);
  };

  // const [isOpen, setIsOpen] = React.useState(false)
  // const [value, onChange] = useState(new Date());

  const [searchForm, setSearchForm] = useState({
    productname: "",
    product_category: "",
    order_date: "",
  });

  const handleChange = (e) => {
    if (e.target != undefined) {
      let { name, value } = e.target;
      setSearchForm((searchForm) => ({ ...searchForm, [name]: value }));
    }
  };

  const findProduct = () => {
    if (searchForm != undefined) {
      setSearchOrder(searchForm);
    }
  };

  const clearSearch = () => {
    setSearchForm({ productname: "", product_category: "", order_date: "" });
    setSearchOrder({ productname: "", product_category: "", order_date: "" });
    setCurrentPage(0);
  };

  return (
    <div className="my-product-main">
      {/* <h1>Product</h1> */}
      <div className="product-input product-order-inner">
        <div className="myorder_search product-input-left new-all-inputs">
          <div className="namecategory">
            <div>
              <TextField
                sx={{
                  width: "100%",
                }}
                name="productname"
                className="input_username new-edit-main-all"
                id="outlined-basic"
                value={searchForm.productname}
                placeholder="product name"
                onChange={(e) => {
                  handleChange(e);
                }}
              />
            </div>
            <div>
              <Select
                className="input_username new-edit-main-all"
                id="demo-simple-select-helper-label"
                name="product_category"
                value={searchForm.product_category}
                displayEmpty
                onChange={(e) => {
                  handleChange(e);
                }}
                MenuProps={MenuProps}
              >
                <MenuItem value="">Category</MenuItem>
                {productcategory &&
                  productcategory.length > 0 &&
                  productcategory.map((value, index) => {
                    return (
                      <MenuItem key={index} value={value.id}>
                        {value.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </div>
          </div>

          <div className="pic_dates ">
            <Flatpickr
              name="order_date"
              placeholder="Date"
              options={{
                maxDate: "today",
              }}
              value={searchForm.order_date}
              className="input_username new-edit-main-all"
              onChange={([date]) => {
                setSearchForm((searchForm) => ({
                  ...searchForm,
                  order_date: moment(date).format("yyyy-MM-DD"),
                }));
              }}
            />
            <span className="date_time">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="22"
                viewBox="0 0 24 22"
                fill="none"
              >
                <g clipPath="url(#clip0_830_1362)">
                  <path
                    d="M16.5 8.9375C16.5 8.75516 16.579 8.5803 16.7197 8.45136C16.8603 8.32243 17.0511 8.25 17.25 8.25H18.75C18.9489 8.25 19.1397 8.32243 19.2803 8.45136C19.421 8.5803 19.5 8.75516 19.5 8.9375V10.3125C19.5 10.4948 19.421 10.6697 19.2803 10.7986C19.1397 10.9276 18.9489 11 18.75 11H17.25C17.0511 11 16.8603 10.9276 16.7197 10.7986C16.579 10.6697 16.5 10.4948 16.5 10.3125V8.9375ZM12 8.9375C12 8.75516 12.079 8.5803 12.2197 8.45136C12.3603 8.32243 12.5511 8.25 12.75 8.25H14.25C14.4489 8.25 14.6397 8.32243 14.7803 8.45136C14.921 8.5803 15 8.75516 15 8.9375V10.3125C15 10.4948 14.921 10.6697 14.7803 10.7986C14.6397 10.9276 14.4489 11 14.25 11H12.75C12.5511 11 12.3603 10.9276 12.2197 10.7986C12.079 10.6697 12 10.4948 12 10.3125V8.9375ZM4.5 13.0625C4.5 12.8802 4.57902 12.7053 4.71967 12.5764C4.86032 12.4474 5.05109 12.375 5.25 12.375H6.75C6.94891 12.375 7.13968 12.4474 7.28033 12.5764C7.42098 12.7053 7.5 12.8802 7.5 13.0625V14.4375C7.5 14.6198 7.42098 14.7947 7.28033 14.9236C7.13968 15.0526 6.94891 15.125 6.75 15.125H5.25C5.05109 15.125 4.86032 15.0526 4.71967 14.9236C4.57902 14.7947 4.5 14.6198 4.5 14.4375V13.0625ZM9 13.0625C9 12.8802 9.07902 12.7053 9.21967 12.5764C9.36032 12.4474 9.55109 12.375 9.75 12.375H11.25C11.4489 12.375 11.6397 12.4474 11.7803 12.5764C11.921 12.7053 12 12.8802 12 13.0625V14.4375C12 14.6198 11.921 14.7947 11.7803 14.9236C11.6397 15.0526 11.4489 15.125 11.25 15.125H9.75C9.55109 15.125 9.36032 15.0526 9.21967 14.9236C9.07902 14.7947 9 14.6198 9 14.4375V13.0625Z"
                    fill="white"
                  />
                  <path
                    d="M5.25 0C5.44891 0 5.63968 0.0724328 5.78033 0.201364C5.92098 0.330295 6 0.505164 6 0.6875V1.375H18V0.6875C18 0.505164 18.079 0.330295 18.2197 0.201364C18.3603 0.0724328 18.5511 0 18.75 0C18.9489 0 19.1397 0.0724328 19.2803 0.201364C19.421 0.330295 19.5 0.505164 19.5 0.6875V1.375H21C21.7956 1.375 22.5587 1.66473 23.1213 2.18046C23.6839 2.69618 24 3.39565 24 4.125V19.25C24 19.9793 23.6839 20.6788 23.1213 21.1945C22.5587 21.7103 21.7956 22 21 22H3C2.20435 22 1.44129 21.7103 0.87868 21.1945C0.316071 20.6788 0 19.9793 0 19.25V4.125C0 3.39565 0.316071 2.69618 0.87868 2.18046C1.44129 1.66473 2.20435 1.375 3 1.375H4.5V0.6875C4.5 0.505164 4.57902 0.330295 4.71967 0.201364C4.86032 0.0724328 5.05109 0 5.25 0ZM1.5 5.5V19.25C1.5 19.6147 1.65804 19.9644 1.93934 20.2223C2.22064 20.4801 2.60218 20.625 3 20.625H21C21.3978 20.625 21.7794 20.4801 22.0607 20.2223C22.342 19.9644 22.5 19.6147 22.5 19.25V5.5H1.5Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_830_1362">
                    <rect width="24" height="22" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </span>
          </div>
        </div>

        <div className="btn-my-product ">
          <div className="clearfind_btn">
            <button className="btn-find-now mr-2" onClick={() => findProduct()}>
              Find
            </button>
            {/* <button className="btn-find-now" onClick={() => clearSearch()}>
              Clear
            </button> */}
          </div>
        </div>
      </div>

      <div className="product-table">
        <table class="table">
          <tr className="product-list-table">
            <th className="product-list-border-left">Product Image</th>
            <th>Product Name</th>
            <th>Order ID</th>
            <th>Category</th>
            <th>Date</th>
            <th className="product-list-border-right">Price</th>
          </tr>
          {isLoader == true ? (
            <tr>
              <td colSpan={6}>
                <Loader />
              </td>
            </tr>
          ) : (
            <>
              {orderList.length > 0 ? (
                orderList.map((value, index) => {
                  return (
                    <tr key={index}>
                      <td className="product-list-table-img">
                        <img src={value.image} alt="" />
                      </td>
                      <td>{value.product_name}</td>
                      <td>{value.id}</td>
                      <td>{value.category}</td>
                      <td>
                        <span>{value.date}</span>
                      </td>
                      <td className="product-list-btn-all">
                        <span className="dolar_color">$</span> {value.price}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={6}>
                    <RecordNotfound />
                  </td>
                </tr>
              )}
            </>
          )}
        </table>
      </div>
    </div>
  );
};

export default MyOrder;
