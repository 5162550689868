import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { FooterBig, Header } from "../components";
import axios from "../axios";
import { errorMsg, successMsg } from "../actions/customFn";
import { useParams } from "react-router-dom";
import Loader from "../hooks/Loader";
import { reactIcons } from "../utils/icons";
import OwlCarousel from "react-owl-carousel";
import { Button } from "react-bootstrap";
import ReactPlayer from "react-player";
import AudioPlayer from "../components/AudioPlayerCard/index";
import { asyncPostPlay } from "../actions/uploadBlogs";

const AlbumDetails = () => {
  const { id } = useParams();
  const [albumInfo, setAlbumInfo] = useState({});
  const [posts, setPosts] = useState([]);
  const [isLoader, setIsLoader] = useState(true);
  const [blogLoader, setBlogLoader] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);

  const getAlbumdetails = async () => {
    const user_id = localStorage.getItem("star_uId");
    const user_password = localStorage.getItem("star_upass");
    try {
      const params = {
        customer_id: user_id,
        password: user_password,
        album_id: id,
      };
      const response = await axios.post("/album-details", params);
      if (response.data.status) {
        setAlbumInfo(response.data.data);
        setIsLoader(false);
      } else {
        errorMsg(response.data.message);
        setIsLoader(false);
      }
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response.status !== undefined) {
          if (error.response.status === 401) {
            setIsLoader(false);
            //   errorMsg("Somthing went wrong...");
          } else {
            console.log(`${error.message}-${error.response.status}`);
            setIsLoader(false);
          }
        }
      }
    }
  };

  const getAlbumPost = async () => {
    const user_id = localStorage.getItem("star_uId");
    const user_password = localStorage.getItem("star_upass");
    try {
      const params = {
        customer_id: user_id,
        password: user_password,
        album_id: id,
      };
      const response = await axios.post("/album-blogs", params);
      if (response.data.status) {
        if (response.data.data.length > 0) {
          setPosts(response.data.data);
          setHasMore(true);
          setBlogLoader(false);
        } else {
          setHasMore(false);
          setBlogLoader(false);
        }
      } else {
        setHasMore(false);
        setPosts([]);
        setBlogLoader(false);
      }
      setTimeout(() => {
        setBlogLoader(false);
      }, 1000);
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response.status !== undefined) {
          if (error.response.status === 401) {
            //   errorMsg("Somthing went wrong...");
            setIsLoader(false);
          } else {
            console.log(`${error.message}-${error.response.status}`);
            setIsLoader(false);
          }
        }
      }
    }
  };

  useEffect(() => {
    if (id) {
      getAlbumdetails(id);
      getAlbumPost();
    }
  }, [id]);

  return (
    <motion.div
      initial={{ opacity: 0.2 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 1 }}
      transition={{ duration: 2 }}
    >
      <div className="all_page_bg ">
        <div className="container-fluid">
          <Header />
          {isLoader == true ? (
            <Loader />
          ) : (
            <div className="page-all-data">
              <div className="add-product-main">
                <div className="add_product_textfild ">
                  <div className="row">
                    <div className="page-container tab_showdata ">
                      <div className="banner">
                        <img
                          src={albumInfo.image}
                          alt="Banner"
                          className="banner-image"
                        />
                      </div>
                      <h1 className="page-title">{albumInfo.name || ""}</h1>
                      <h6>{albumInfo.description}</h6>
                      <div className="music-slider">
                        {/* <OwlCarousel
                                                    {...allMusic}
                                                    className='owl-theme'
                                                    nav
                                                    loop
                                                    margin={32}
                                                > */}
                        {posts.length > 0 ? (
                          <div className="row">
                            {posts.map((value, index) => (
                              <div className="col-md-3">
                                <div
                                  key={index}
                                  className="music-slider-card trending-post"
                                >
                                  <div className="d-flex justify-content-between align-items-center gap-2 mb-2 tp-heading">
                                    <div className="d-flex align-items-center gap-10">
                                      <img
                                        src={value.author_image}
                                        className="rounded-circle border border-white"
                                        width={40}
                                        height={40}
                                        alt=""
                                      />
                                      <h6 className="profile-name-heading flex-1 text-16 capitalize font-poppins font-medium truncate popins">
                                        {value.author_name}
                                      </h6>
                                    </div>
                                    <span className="text-14 text-gray-300 popins font-500">
                                      1d
                                    </span>
                                  </div>
                                  <div className="w-100">
                                    <div className="">
                                      <p>{value.title}</p>

                                      {value.media_type === "video" ? (
                                        <ReactPlayer
                                  onEnded={()=>asyncPostPlay(value.id)}

                                          url={value.video}
                                          controls
                                          width="100%"
                                          height="235px"
                                        />
                                      ) : value.media_type === "audio" ? (
                                        <AudioPlayer
                                        id={value.id}
                                          src={value.audio}
                                          backgroundImage={value.thumbnail}
                                        />
                                      ) : (
                                        <img
                                          src={value.image}
                                          className="song-img"
                                        />
                                      )}
                                    </div>
                                  </div>
                                  <div className="d-flex align-items-center justify-content-between mt-2">
                                    <div className="d-flex flex-1 align-items-center gap-10">
                                      <div className="d-flex align-items-center gap-5">
                                        <span className="text-gray-300 text-20">
                                          {reactIcons.circleUp}
                                        </span>
                                        <span className="text-gray-300 font-poppins text-14 font-medium">
                                          0
                                        </span>
                                      </div>
                                      <div className="d-flex align-items-center gap-5">
                                        <span className="text-gray-300 text-20">
                                          {reactIcons.repeat}
                                        </span>
                                        <span className="text-gray-300 font-poppins text-14 font-medium">
                                          0
                                        </span>
                                      </div>
                                      <div className="d-flex align-items-center gap-5">
                                        <span className="text-gray-300 text-20">
                                          {reactIcons.message}
                                        </span>
                                        <span className="text-gray-300 font-poppins text-14 font-medium">
                                          0
                                        </span>
                                      </div>
                                    </div>
                                    <Button variant="dark" className="tp-mint">
                                      Mint 0
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <Loader />
                        )}
                        {/* </OwlCarousel> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <FooterBig />
        </div>
      </div>
    </motion.div>
  );
};

export default AlbumDetails;
