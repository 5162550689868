import React, { useEffect, useState } from "react";

import InfiniteScroll from "react-infinite-scroll-component";



import { NavLink } from "react-router-dom";

import {
  asyncAllProductList,
  getProductCategory,
} from "../../actions/productAction";
import Loader from "../../hooks/Loader";
import RecordNotfound from "../Home/RecordNotfound";
import { reactIcons } from "../../utils/icons";

const NftProduct = (props) => {
  const { isLoader, productList, handleLoadMore, hasMore, refresh } = props;

  return (
    <>
      <div className=" ">
        {isLoader == true ? (
          <Loader />
        ) : productList != undefined && productList.length > 0 ? (
          <div className="product_listing_data" id="scrollableDiv">
            <>
              {productList != undefined && productList.length > 0 ? (
                <InfiniteScroll
                  dataLength={productList.length}
                  next={handleLoadMore}
                  hasMore={hasMore}
                  loader={<Loader />}
                  scrollableTarget="scrollableDiv"
                  refreshFunction={refresh}
                  endMessage={
                    <p
                      className="end-message-loader"
                      style={{ textAlign: "center" }}
                    >
                      <b>Yay! You have seen it all</b>
                    </p>
                  }
                  pullDownToRefresh
                  pullDownToRefreshThreshold={50}
                  pullDownToRefreshContent={
                    <h3 style={{ textAlign: "center" }}>
                      Pull down to refresh
                    </h3>
                  }
                  releaseToRefreshContent={
                    <h3 style={{ textAlign: "center" }}>Release to refresh</h3>
                  }
                >
                  <div className="row m-0">
                    {productList.map((value, index) => {
                      return (
                        <>
                          <div
                            className="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-12"
                            key={index}
                          >
                            <NavLink to={`/product-details/${value.id}`}>
                              <div className="product_inner productlist_img">
                                <div className="card_img">
                                  {value.product_image != undefined &&
                                    value.product_image.length > 0 ? (
                                    <img
                                      src={
                                        value.product_image[0].image != ""
                                          ? value.product_image[0].image
                                          : "/images/product_listing3.png"
                                      }
                                      alt=""
                                    />
                                  ) : (
                                    <img
                                      src={"/images/product_listing3.png"}
                                      alt=""
                                    />
                                  )}
                                  <div className="overlay-product"></div>
                                  <ul className="product-wishlist-sec">
                                    <li>{reactIcons.heartOutline}</li>
                                    <li>{reactIcons.add}</li>
                                  </ul>
                                </div>
                                <div className="product_text">
                                  <div className="product_inner_hedding">
                                    <h2>{value.name}</h2>
                                    <h3>{value.category}</h3>
                                  </div>
                                  <div className="product_number">
                                    <p>Buy Now</p>
                                    <h4>
                                      <span>$</span> {value.price}
                                    </h4>
                                  </div>
                                </div>
                              </div>
                            </NavLink>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </InfiniteScroll>
              ) : (
                <RecordNotfound />
              )}
            </>
          </div>
        ) : (
          <h1> Record Not Found </h1>
        )}
      </div>
    </>
  );
};

export default NftProduct;
