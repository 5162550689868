import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import GoogleLogin from "react-google-login";
import { userSocialLogin } from "../../actions/authActions";
import { gapi } from "gapi-script";

const GoogleLoginComponent = () => {
  // const googleClientId = "882652252861-fuuifqegc6klkd5dl9uh3g6jk7de5458.apps.googleusercontent.com"; 

  const { settingData } = useSelector((state) => state.userAuth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [googleClientId, setGoogleClientId] = useState("");

  useEffect(() => {
    if (Object.keys(settingData).length > 0 && settingData.google_login_key != undefined && settingData.google_login_key != "") {
      setGoogleClientId(settingData.google_login_key)
    }
  }, [settingData])

  gapi.load("client:auth2", () => {
    gapi.client.init({
      clientId: googleClientId,
      plugin_name: "chat",
      scope: ""
    });
  });

  const responseGoogle = async (data) => {
    try {
      if (data.accessToken) {
        const formData = {
          email: data.profileObj.email,
          username: data.profileObj.name,
          image: data.profileObj.imageUrl,
        };
        dispatch(userSocialLogin({ formData, navigate }));
      }

    } catch (error) {
      console.log("error", error);
    }
  };

  const ErrorGoogle = (data) => {
    console.log("ErrorGoogle", data);
  };

  return (
    <>
      {
        googleClientId &&
        <GoogleLogin
          clientId={googleClientId}
          scope=""
          onSuccess={responseGoogle}
          onFailure={ErrorGoogle}
          cookiePolicy={"single_host_origin"}
          // buttonText={
          //   <img src={"/images/icon_google.png"} alt="" />
          // }
          buttonText={
            <>
              <div className={"google-inner"}>
                <span className={"google-icon"}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M3.63545 9C3.63414 10.2748 4.08832 11.509 4.91761 12.484C5.7469 13.4591 6.89781 14.1122 8.16663 14.3277C9.43546 14.5433 10.7404 14.3073 11.8504 13.6617C12.9605 13.0161 13.804 12.0024 14.2319 10.8H9.08861V7.2H18V10.8H17.9955C17.1529 14.9076 13.4857 18 9.08861 18C4.06897 18 0 13.9707 0 9C0 4.0293 4.06897 2.28092e-06 9.08861 2.28092e-06C10.5742 -0.00104545 12.0373 0.358877 13.3496 1.0482C14.662 1.73753 15.7835 2.73519 16.6158 3.9537L13.6366 6.0192C12.9898 5.05117 12.0453 4.31526 10.943 3.92055C9.84074 3.52584 8.63936 3.49334 7.51696 3.82787C6.39456 4.16239 5.41088 4.84614 4.71173 5.77777C4.01258 6.70939 3.63517 7.83929 3.63545 9Z"
                      fill="white"
                    />
                  </svg>
                </span>
                <span>Google</span>
              </div>
            </>
          }
          icon={false}
          prompt="select_account"
          disabled={false}
        />
      }
    </>
  );
};

export default GoogleLoginComponent;
