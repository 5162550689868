import React, { useState } from 'react'
import MainLoader from '../../hooks/MainLoader'
import { useAccount, useClient, useWalletClient } from 'wagmi';
import { polygon, polygonAmoy } from 'viem/chains';
import { contracts, network, zeroAddress } from '../../utils/env';
import { getContract, parseEther, publicActions } from 'viem';
import { AssetContractShared, ERC20, Exchange } from '../../contracts';
import { errorMsg, successMsg } from '../../actions/customFn';

const MintBtn = (props) => {
    const [isLoader, setIsLoader] = useState(false);

    const { address } = useAccount();
    const getBuyerReplacementPattern = () => {
        return (
            window.web3.utils.padLeft("0x0", 8) +
            window.web3.utils.toTwosComplement("-1").slice(2) +
            window.web3.utils.toTwosComplement("0x0").slice(2) +
            window.web3.utils.toTwosComplement("0x0").slice(2) +
            window.web3.utils.toTwosComplement("0x0").slice(2) +
            window.web3.utils.toTwosComplement("0x0").slice(2) +
            window.web3.utils.toTwosComplement("0x0").slice(2) +
            window.web3.utils.toTwosComplement("0x0").slice(2) +
            window.web3.utils.toTwosComplement("0x0").slice(2)
        );
    };
    let { data: _walletClient } = useWalletClient({
        chainId: network === "mainnet" ? polygon.id : polygonAmoy.id,
        account: address,
    });
    const walletClient = _walletClient
        ? _walletClient.extend(publicActions)
        : null;
    const client = useClient({
        chainId: process.env.REACT_APP_NETWORK === "mainnet" ? polygon.id : 80002,
    });
    const matchOrder = async (sellerOrder, buyerOrder) => {
        const { request } = await walletClient.simulateContract({
            address: contracts.Exchange,
            abi: Exchange,
            functionName: "atomicMatch",
            value:
                sellerOrder.paymentToken === zeroAddress ? buyerOrder.basePrice : 0,
            args: [
                [
                    buyerOrder.exchange,
                    buyerOrder.maker,
                    buyerOrder.taker,
                    buyerOrder.feeRecipient,
                    buyerOrder.target,
                    buyerOrder.staticTarget,
                    buyerOrder.paymentToken,
                    sellerOrder.exchange,
                    sellerOrder.maker,
                    sellerOrder.taker,
                    sellerOrder.feeRecipient,
                    sellerOrder.target,
                    sellerOrder.staticTarget,
                    sellerOrder.paymentToken,
                ],
                [
                    buyerOrder.makerRelayerFee,
                    buyerOrder.takerRelayerFee,
                    buyerOrder.makerProtocolFee,
                    buyerOrder.takerProtocolFee,
                    buyerOrder.basePrice,
                    buyerOrder.extra,
                    buyerOrder.maximumFill,
                    buyerOrder.listingTime,
                    buyerOrder.expirationTime,
                    buyerOrder.salt,
                    sellerOrder.makerRelayerFee,
                    sellerOrder.takerRelayerFee,
                    sellerOrder.makerProtocolFee,
                    sellerOrder.takerProtocolFee,
                    parseEther(sellerOrder.basePrice),
                    sellerOrder.extra,
                    sellerOrder.maximumFill,
                    sellerOrder.listingTime,
                    sellerOrder.expirationTime,
                    sellerOrder.salt,
                ],
                [
                    buyerOrder.feeMethod,
                    buyerOrder.side,
                    buyerOrder.saleKind,
                    buyerOrder.howToCall,
                    sellerOrder.feeMethod,
                    sellerOrder.side,
                    sellerOrder.saleKind,
                    sellerOrder.howToCall,
                ],
                buyerOrder.calldata,
                sellerOrder.calldata,
                buyerOrder.replacementPattern,
                sellerOrder.replacementPattern,
                buyerOrder.staticExtradata,
                sellerOrder.staticExtraData,
                [sellerOrder.v, sellerOrder.v],
                [
                    sellerOrder.r,
                    sellerOrder.s,
                    sellerOrder.r,
                    sellerOrder.s,
                    "0x0000000000000000000000000000000000000000000000000000000000000000",
                ],
            ],
        });

        await walletClient.switchChain({
            id: network === "mainnet" ? polygon.id : polygonAmoy.id,
        });
        const transaction = await walletClient.waitForTransactionReceipt({
            hash: await walletClient.writeContract(request),
        });
        console.log(transaction, "transaction");
        return transaction;
    };
    const getCalldata = (address, abi, method, ...params) => {
        const contract = new window.web3.eth.Contract(abi, address);
        const calldata = contract.methods[method](...params).encodeABI();
        return calldata;
    };
    const handleBuy = async () => {
        setIsLoader(true)
        try {
            if (!address) {
                setIsLoader(false);
                errorMsg("Please connect your wallet to make blockchain transaction");
                return;
            }
            const salt = window.web3.utils.hexToNumberString(
                window.web3.utils.randomHex(32)
            );

            const order = {
                exchange: props.data.exchange, // min_order.exchange
                maker: address,
                taker: props.data.maker, // Order taker address //
                makerRelayerFee: props.data.makerRelayerFee, // Maker relayer fee of the order, unused for taker order
                takerRelayerFee: props.data.takerRelayerFee, // Taker relayer fee of the order, or maximum taker fee for a taker order
                makerProtocolFee: props.data.makerProtocolFee, // Maker protocol fee of the order, unused for taker order
                takerProtocolFee: props.data.takerProtocolFee, // Taker protocol fee of the order, or maximum taker fee for a taker order
                feeRecipient: zeroAddress, // Order fee recipient or zero address for taker order
                feeMethod: props.data.feeMethod, // Fee method (protocol token or split fee)
                side: 0, // Side (buy/sell) // 0 bydefault
                saleKind: props.data.saleKind, // Kind of sale
                target: props.data.target, // Target
                howToCall: props.data.howToCall, // HowToCall
                calldata: getCalldata(
                    contracts.AssetSharedContract,
                    AssetContractShared,
                    "safeTransferFrom",
                    zeroAddress,
                    address, // connectted wallet address
                    props.onChainId, // need token onChainId
                    1,
                    props.uri
                        ? window.ethers.toQuantity(
                            window.ethers.toUtf8Bytes(props.uri)
                        )
                        : []
                ), // Calldata
                maximumFill: 1,
                replacementPattern: getBuyerReplacementPattern(), // Calldata replacement pattern, or an empty byte array for no replacement
                staticTarget: props.data.staticTarget, // Static call target, zero-address for no static call
                staticExtradata: props.data.staticExtraData, // Static call extra data
                paymentToken: props.data.paymentToken, // Token used to pay for the order, or the zero-address as a sentinel value for Ether
                basePrice: parseEther(props.data.basePrice), // Base price of the order (in paymentTokens)
                extra: props.data.extra, // m bid increment for English auctions, starting/ending price difference
                listingTime: Math.round(Date.now() / 1000) - 19800, // Listing timestamp
                expirationTime: 0, // Expiration timestamp - 0 for no expiry
                salt: salt, // Order salt, used to prevent duplicate hashes
            };
            console.log(order, "order");
            const ethers = window.ethers;

            const { v, r, s } = ethers.Signature.from(props.data.signature);
            console.log(v, r, s, "splidata");

            if (order.paymentToken !== zeroAddress) {
                // const paymentAddress = order.paymentToken;
                const paymentToken = getContract({
                    address: order.paymentToken,
                    abi: ERC20,
                    client,
                });
                const allowance = await paymentToken.read.allowance([
                    address,
                    order.exchange,
                ]);
                console.log(allowance, "allowance");
                // eslint-disable-next-line no-undef
                console.log(BigInt(order.basePrice), "bigInt");
                // eslint-disable-next-line no-undef
                if (allowance < BigInt(order.basePrice)) {
                    const { request } = await walletClient.simulateContract({
                        address: paymentToken.address,
                        abi: paymentToken.abi,
                        functionName: "approve",
                        args: [order.exchange, order.basePrice],
                    });
                    await walletClient.switchChain({
                        id: network === "mainnet" ? polygon.id : polygonAmoy.id,
                    });
                    const transaction = await walletClient.waitForTransactionReceipt({
                        hash: await walletClient.writeContract(request),
                    });
                    console.debug("Approval tx:", transaction);
                }
            }
            await matchOrder({ ...props.data, v, r, s }, order);
            successMsg("Sucessfully Mint");
            setIsLoader(false);
        } catch (error) {
            console.log(error);
            setIsLoader(false);
        }
    }
    return (
        <>
            {isLoader && <MainLoader />}
            <button className="buy-and-cancel" onClick={handleBuy} disabled={props.disabled}>Mint {props.data.fill}</button>
        </>
    )
}

export default MintBtn