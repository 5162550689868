import React from "react";
// 
// import { asyncgetMyCourse } from "../../actions/courseAction";

const Courses = (props) => {
  const { mycourses } = props;
  return (
    <>
      {mycourses != undefined && mycourses.length > 0 ? (
        <div
          className="col-md-6 px-3 px-lg-0 first-course"
          style={{
            backgroundImage: `url(${mycourses[0].image})`,
          }}
        >
          <div className="course-page">
            <div className="main-content">
              <div className="row">
                <div className="col-xl-11 col-lg-10 col-md-10 col-sm-12 col-12">
                  <div className="page-inner-content">
                    {mycourses[0].tag_title && (
                      <h5>{mycourses[0].tag_title} </h5>
                    )}
                    {mycourses[0].title && <h6>{mycourses[0].title}</h6>}
                    <p>{mycourses[0].sort_description}</p>
                    <div className="course-persname">
                      <img src={mycourses[0].author_image} alt="" />
                      <h6>{mycourses[0].author_name}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : mycourses.length == 0 ? (
        <div className="add_yourcourse">
          {" "}
          <h1>Please add your course</h1>{" "}
        </div>
      ) : (
        " "
      )}
    </>
  );
};

export default Courses;
