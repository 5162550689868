import React, { useEffect, useState } from "react";
// import "./assets/vendor/css/bootstrap.min.css";
// import "./assets/vendor/css/new.css";
// import "./assets/vendor/css/style.css";
// import "./assets/vendor/css/responsive.css";
// import "./assets/vendor/css/newresponsive.css";

// import "bootstrap/dist/css/bootstrap.min.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { GetRoutes } from "./actions/customFn";
import { Routes, useLocation, useNavigate } from "react-router-dom";
import RouteArr from "./hooks/GetRoutes";
import LoadingOverlay from "react-loading-overlay";
import LoadingBar from "./components/Loading/LoadingBar";
import { setIslogin } from "./redux/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { getcountryList } from "./actions/CountryAction";
import { Toaster } from "react-hot-toast";
import { getPageSettingData, getUserDetail } from "./actions/authActions";
import { getAllFriendList } from "./actions/friendAction";
import { getAllPhotosList } from "./actions/uploadPhoto";
import { getGroupList } from "./actions/chatAction";
function App() {
  const dispatch = useDispatch();
  const { isUserLogin } = useSelector((state) => state.userAuth);
  const { settingData } = useSelector((state) => state.userAuth);

  // Top To Scroll
  const routePath = useLocation();
  const navigate = useNavigate();
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    onTop();
  }, [routePath]);

  // console.log("groups_list",groups_list)
  const location = useLocation();

  useEffect(() => {
    if (isUserLogin) {
      dispatch(getAllFriendList(""));
      dispatch(getAllPhotosList());
      dispatch(getGroupList());
      dispatch(getUserDetail({ dispatch, navigate }));
    }
  }, [dispatch, isUserLogin]);
  // Loader State

  useEffect(() => {
    dispatch(getPageSettingData({ dispatch, navigate }));
  }, [dispatch]);

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(function () {
      setIsLoading(false);
    }, 1200);
  }, []);

  useEffect(() => {
    dispatch(setIslogin());
    dispatch(getcountryList());
  }, [dispatch]);

  useEffect(() => {
    if (settingData != undefined) {
      if (settingData.favicon != undefined && settingData.favicon) {
        document.getElementById("favicon").href = settingData.favicon;
      }
      if (settingData.title != undefined) {
        document.getElementById("webstarbay_title").innerHTML =
          settingData.title;
      }
    }
  }, [settingData]);

  return (
    <div className="App">
      <LoadingOverlay active={isLoading} spinner={<LoadingBar />}>
        <Toaster position="top-right" reverseOrder={true} />
        <Routes>{GetRoutes(RouteArr)}</Routes>
      </LoadingOverlay>
    </div>
  );
}

export default App;
