import React, { useEffect, useRef, useState } from "react";
import { errorMsg, handleScrollError, images } from "../../actions/customFn";
import { TextField } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/fontawesome-free-solid";

import "quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import { useNavigate } from "react-router-dom";
import { asyncUploadPhoto, getAllPhotosList } from "../../actions/uploadPhoto";
import { useDispatch } from "react-redux";
import SmallLoader from "react-js-loader";
// import validator from "validator";

const UploadPhotoModal = (props) => {
  const dispatch = useDispatch();
  const { setShow, setIsLoader, setUploadLoad } = props;

  const inputRef = useRef(null);

  const [buttonloader, setButtonLoader] = useState(false);

  const [editorType, setEditorType] = useState("");
  const [formErrors, setFormError] = useState({});
  const errors = {};
  // const navigate = useNavigate();

  const [formData, setFormData] = useState({
    image: "",
  });

  const handleClick = () => {
    inputRef.current.click();
  };

  const handleChange = (e) => {
    if (e.target != undefined) {
      const { name, value } = e.target;
      if (name == "image") {
        setFormData((formData) => ({ ...formData, [name]: e.target.files[0] }));
      }
    } else {
      if (editorType != "") {
        setFormData((formData) => ({ ...formData, [editorType]: e }));
      }
    }
  };

  const validateFormData = async () => {
    try {

      if (formData.image == "" || formData.image == undefined) {
        errors.image = "Please choose an image";
      }
      setFormError(errors);
    } catch (error) {
      console.log(error);
    }
  };

  const handleUploadPhoto = async (e) => {
    try {
      e.preventDefault();
      validateFormData();
      if (Object.keys(errors).length === 0) {
        setButtonLoader(true);
        await asyncUploadPhoto(formData, setShow, setUploadLoad, setFormData, setpreview, setButtonLoader);
        dispatch(getAllPhotosList());
      } else {
        handleScrollError(errors);
      }
    } catch (error) {
      errorMsg("something went wrong!");
    }
  };

  const [preview, setpreview] = useState("/images/upload-post1.png");

  const fileChangedHandler = (event) => {
    setpreview(URL.createObjectURL(event.target.files[0]));
  };
  return (
    <Modal show={props.show} className="upload_post_popup" onHide={props.close}>
      <FontAwesomeIcon onClick={props.close} icon={faTimes} />

      <Modal.Header>
        <h4>Photo Upload</h4>
      </Modal.Header>

      <div className="uploadpost_cont new-all-inputs">
        <div className="post_image_upload">
          <img src={preview != '' ? preview : "/images/upload-post1.png"} alt="" />
          <h6>Upload Image</h6>

          <div className="file_upload_product">
            <input
              style={{ display: "none" }}
              id="fileInput"
              name="image"
              type="file"
              accept="image/*"
              inputProps={{ accept: "image/*" }}
              onChange={(e) => {
                handleChange(e);
                fileChangedHandler(e);
              }}
              placeholder="browser"
              // multiple
              ref={inputRef}
            />

            <button onClick={handleClick}>Browse files</button>
          </div>
          {formErrors.image && (
            <div className="image_error">
              <p> {formErrors.image} </p>
            </div>
          )}
        </div>

        <div className="upload_btn_last">
          {
            buttonloader == true ? <button
              type="button"
            >
              <SmallLoader type="spinner-default" bgColor={'#fff'} color={'#fff'} size={40} />
            </button> :
              <button
                onClick={(e) => {
                  handleUploadPhoto(e);
                }}
              >
                Upload
              </button>
          }
        </div>
      </div>
    </Modal>
  );
};

export default UploadPhotoModal;
