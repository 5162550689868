import React from "react";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/fontawesome-free-solid";
import moment from "moment";

const ViewNftHolders = (props) => {

  return (
    <div className="new-modal-Campaigns ">
      <Modal
        show={props.show}
        centered
        size="lg"
        className="main_popup_inner donate_popup_main addcampaigns_popup add-campaigns-modal"
        onHide={props.close}
      >
        <FontAwesomeIcon onClick={props.close} icon={faTimes} />

        <Modal.Header className="mb-0 border-0">
          <Modal.Title className="popup_title_name">
            Holders List
          </Modal.Title>
        </Modal.Header>
        <div class="table-responsive">
          <table className="table text-white">
            <thead>
              <tr>
                <th scope="col">Address</th>
                <th scope="col">Balance</th>
              </tr>
            </thead>
            <tbody class="table-group-divider">
              {props.data?.length > 0 ? props.data.map((item, index) => (
                <tr key={index}>
                  <td>{`${item.address.slice(1, 3)}...${item.address.slice(-4)}`}</td>
                  <td>{item.balance}</td>
                </tr>
              )) : <tr>

                <td colSpan={6} className="text-center">No data</td>
              </tr>}
            </tbody>
          </table>
        </div>

        <div className="popup_donate_btn">
          <button
            onClick={props.close}
          >
            Close
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default ViewNftHolders;
