import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
const FooterBig = ({ additionalClass }) => {
  const navigate = useNavigate();
  const { groups_list } = useSelector((state) => state.chatData);
  const [groupList, setGroupList] = useState([]);
  // const directpage = (path) => {
  //   navigate(`/${path}`);
  // };

  useEffect(() => {
    if (groups_list.length > 0) {
      setGroupList(groups_list);
    }
  }, [groups_list]);

  return (
    <div className='bottom_footer_new_div'>
      <div className='container-fluid'>
        <div className={`bottom_footer_main_div ${additionalClass}`}>
          <div className='page_main_bottom_footer'>
            <div className='page_main_bottom_footer_inner'>
              <div className='footer_inner_btn'>
                <NavLink to='/challenges'>Challenges</NavLink>
                <NavLink to='#'>Creative Corner</NavLink>
                <NavLink to='#'>Global Village</NavLink>
                <NavLink to='#'>The Spa</NavLink>
                <NavLink to='#'>Eco Hub</NavLink>
                <NavLink to='#'>TalntX</NavLink>
              </div>
              {/* {groupList.length > 0
                ? groupList.slice(0, 5).map((value, index) => {
                    return (
                      <div key={index} className="footer_inner_btn">
                        <NavLink to="/chat">{value.name}</NavLink>
                      </div>
                    );
                  })
                : ""} */}

              {/* <div className="footer_inner_btn">
              <NavLink to=""> Global Village</NavLink>
            </div>
            <div className="footer_inner_btn">
              <NavLink to="">The Spa</NavLink>
            </div>
            <div className="footer_inner_btn">
              <NavLink to="">Eco Hub</NavLink>
            </div>
            <div className="footer_inner_btn">
              <NavLink to="">TalntX</NavLink>
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
FooterBig.propTypes = {
  additionalClass: PropTypes.string, // Prop type for additionalClass
};
export default FooterBig;
