import React, { useState } from "react";
import { errorMsg, handleScrollError } from "../../actions/customFn";
import Modal from "react-bootstrap/Modal";

import "quill/dist/quill.snow.css";
import SmallLoader from "react-js-loader";
// import validator from "validator";

const ConfirmPopup = (props) => {
  const { setShow } = props;

  const errors = {};
  // const navigate = useNavigate();

  const [confirmLoader, setConfirmLoder] = useState(false);

  const handleUploadPost = async (e) => {
    setConfirmLoder(true);
    try {
      e.preventDefault();
      if (Object.keys(errors).length === 0) {
        setShow(false);
        localStorage.setItem("isMint", true);
        setConfirmLoder(false);
      } else {
        handleScrollError(errors);
        setConfirmLoder(false);
      }
    } catch (error) {
      errorMsg("something went wrong!");
      setConfirmLoder(false);
    }
  };
  const handleRemove = () => {
    localStorage.removeItem("post_id");
    setShow(false);
  };

  return (
    <Modal
      show={props.show}
      className="upload_post_popup post-upload-modal"
      onHide={props.close}
    >
      <Modal.Header>
        <h4>Confirmation Popup</h4>
      </Modal.Header>

      <div className="uploadpost_cont new-all-inputs">
        <p>Do you want to create this post as a mintable on blockchain</p>

        <div className="confirm-btn-sec">
          <button className="confirm_no_btn" onClick={handleRemove}>
            No
          </button>
          {confirmLoader === true ? (
            <button type="button" className="confirm_yes_btn">
              <SmallLoader
                type="spinner-default"
                bgColor={"#fff"}
                color={"#fff"}
                size={40}
              />
            </button>
          ) : (
            <button
              className="confirm_yes_btn"
              onClick={(e) => {
                handleUploadPost(e);
              }}
            >
              Yes{" "}
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmPopup;
