import React, { useState } from "react";
import { FormControl, FormHelperText, MenuItem, Select, TextField } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/fontawesome-free-solid";
import { errorMsg, icons } from "../../actions/customFn";
import SmallLoader from "react-js-loader";
import moment from "moment";
import Flatpickr from "react-flatpickr";
import { contracts, network, paymentTokens, zeroAddress } from "../../utils/env";
import { AssetContractShared, ERC20, Exchange } from "../../contracts";
import { getContract, publicActions } from "viem";
import { useAccount, useClient, useWalletClient } from "wagmi";
import { polygon, polygonAmoy } from "viem/chains";
import { asyncPostMint } from "../../actions/uploadBlogs";
// import {
//   Accordion,
//   AccordionDetails,
//   AccordionSummary,
// } from "@mui/material";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import VerifiedIcon from '@mui/icons-material/Verified';

const NftSellModal = (props) => {
  const { setShow } = props;

  // const [proxyVerify, setProxyVerify] = (null);
  // const [allowanceVerify, setAllowanceVerify] = (null);
  // const [signatureVerify, setSignatureVerify] = (null);
  const [selectedValue, setSelectedValue] = useState(paymentTokens[0].value);
  const [formData, setFormData] = useState({
    amount: "",
    quantity: "",
    paymentToken: "",
    date: moment().format(),
  });
  const { address } = useAccount();
  const client = useClient({
    chainId: process.env.REACT_APP_NETWORK === "mainnet" ? polygon.id : 80002,
  });
  const salt = window.web3.utils.hexToNumberString(
    window.web3.utils.randomHex(32)
  );
  let { data: _walletClient, isError, isLoading } = useWalletClient({
    chainId: network === "mainnet" ? polygon.id : polygonAmoy.id,
    account: address,
  });
  const walletClient = _walletClient
    ? _walletClient.extend(publicActions)
    : null;

  const [formErrors, setFormError] = useState({});

  const [buttonloader, setButtonLoader] = useState(false);

  const handleChange = (e) => {
    if (e.target !== undefined) {
      const { name, value } = e.target;
      setFormData((formData) => ({ ...formData, [name]: value }));
    }
  };
  const handleSelect = (e) => {
    if (e.target !== undefined) {
      const { name, value } = e.target;
      setFormData((formData) => ({ ...formData, [name]: value }));
      setSelectedValue(value);
    }
  };

  const validateFormData = () => {
    const errors = {};
    try {
      if (formData.amount === "") {
        errors.amount = "Require";
      }
      if (formData.amount < "0") {
        errors.amount = "Positive amount";
      }
      if (formData.quantity === "") {
        errors.quantity = "Require";
      }
      if (formData.quantity < "0") {
        errors.quantity = "Positive quantity";
      }
      if (formData.date === "") {
        errors.date = "Require";
      }
      setFormError(errors);
      return Object.keys(errors).length === 0;
    } catch (error) {
      console.log(error);
      return Object.keys(errors).length === 0;
    }
  };
  const getCalldata = (address, abi, method, ...params) => {
    const contract = new window.web3.eth.Contract(abi, address);
    const calldata = contract.methods[method](...params).encodeABI();
    return calldata;
  };

  const getSellerReplacementPattern = () => {
    return (
      window.web3.utils.padLeft("0x0", 8) +
      window.web3.utils.toTwosComplement("0x0").slice(2) +
      window.web3.utils.toTwosComplement("-1").slice(2) +
      window.web3.utils.toTwosComplement("0x0").slice(2) +
      window.web3.utils.toTwosComplement("-1").slice(2) +
      window.web3.utils.toTwosComplement("0x0").slice(2) +
      window.web3.utils.toTwosComplement("0x0").slice(2) +
      window.web3.utils.toTwosComplement("0x0").slice(2) +
      window.web3.utils.toTwosComplement("0x0").slice(2)
    );
  };
  const exchangeToken = getContract({
    address: contracts.Exchange,
    abi: Exchange,
    client,
  });
  const getOrderSignature = async (order) => {
    const orderHash = await exchangeToken.read.hashOrder([
      [
        order.exchange,
        order.maker,
        order.taker,
        order.feeRecipient,
        order.target,
        order.staticTarget,
        order.paymentToken,
      ],
      [
        order.makerRelayerFee,
        order.takerRelayerFee,
        order.makerProtocolFee,
        order.takerProtocolFee,
        order.basePrice,
        order.extra,
        order.maximumFill,
        order.listingTime,
        order.expirationTime,
        order.salt,
      ],
      order.feeMethod,
      order.side,
      order.saleKind,
      order.howToCall,
      order.calldata,
      order.replacementPattern,
      order.staticExtradata,
    ]);
    const signature = await walletClient.signMessage({
      account: address,
      // Hex data representation of message.
      message: { raw: orderHash },
    });
    // const signature = await signMessage({ message: orderHash });
    console.log(signature, "signature", orderHash);

    return { signature, orderHash };
  };

  const handleSaleNft = async () => {
    const isFormValid = validateFormData();
    if (isFormValid) {
      try {
        setButtonLoader(true);
        // setProxyVerify(false);
        const order = {
          exchange: contracts.Exchange, // Exchange address
          maker: props.data.creator, // Order maker address
          taker: zeroAddress, // Order taker address
          makerRelayerFee: props?.data?.orders[0]?.makerRelayerFee, // Maker relayer fee of the order, unused for taker order
          takerRelayerFee: props?.data?.orders[0]?.takerRelayerFee, // Taker relayer fee of the order, or maximum taker fee for a taker order
          makerProtocolFee: props?.data?.orders[0]?.makerProtocolFee, // Maker protocol fee of the order, unused for taker order
          takerProtocolFee: props?.data?.orders[0]?.takerProtocolFee, // Taker protocol fee of the order, or maximum taker fee for a taker order
          feeRecipient: contracts.AdminAddress, // Order fee recipient or zero address for taker order
          feeMethod: 1, // Fee method (protocol token or split fee)
          side: 1, // Side (buy/sell)
          saleKind: 0, // Kind of sale
          target: contracts.AssetSharedContract, // Target
          howToCall: 0, // HowToCall
          calldata: getCalldata(
            contracts.AssetSharedContract,
            AssetContractShared,
            "safeTransferFrom",
            props.data.creator,
            zeroAddress,
            props.data.onChainId,
            formData.quantity, // Math.pow(2, 40) - 1
            props.data.uri
              ? window.ethers.toQuantity(
                window.ethers.toUtf8Bytes(props.data.uri)
              )
              : []
          ),
          replacementPattern: getSellerReplacementPattern(), // Calldata replacement pattern, or an empty byte array for no replacement
          staticTarget: zeroAddress, // Static call target, zero-address for no static call
          staticExtradata: "0x", // Static call extra data
          paymentToken: selectedValue, // Token used to pay for the order, or the zero-address as a sentinel value for Ether
          basePrice: window.web3.utils.toWei(formData.amount), // 5 Base price of the order (in paymentTokens)
          extra: 0, // Auction extra parameter - minimum bid increment for English auctions, starting/ending price difference
          listingTime: Math.round(Date.now() / 1000) - 200000, // Listing timestamp
          expirationTime: moment(formData.date).unix(), // 0 Expiration timestamp - 0 for no expiry
          salt: salt,
          maximumFill: formData.quantity,
        };
        if (order.paymentToken !== zeroAddress) {
          // setProxyVerify(true);
          // setAllowanceVerify(false)
          const paymentToken = getContract({
            address: order.paymentToken,
            abi: ERC20,
            client,
          });
          const allowance = await paymentToken.read.allowance([
            address,
            order.exchange,
          ]);
          console.log(allowance, "allowance");
          // setAllowanceVerify(true)
          // setSignatureVerify(false)
          // eslint-disable-next-line no-undef
          console.log(BigInt(order.basePrice), "bigInt");
          // eslint-disable-next-line no-undef
          if (allowance < BigInt(order.basePrice)) {
            const { request } = await walletClient.simulateContract({
              address: paymentToken.address,
              abi: paymentToken.abi,
              functionName: "approve",
              args: [order.exchange, order.basePrice],
            });
            await walletClient.switchChain({
              id: network === "mainnet" ? polygon.id : polygonAmoy.id,
            });
            const transaction = await walletClient.waitForTransactionReceipt({
              hash: await walletClient.writeContract(request),
            });
            console.debug("Approval tx:", transaction);
            const { signature, orderHash } = await getOrderSignature(order);
            console.log(signature, "signature", orderHash);
            if (signature.hasOwnProperty("code")) {
              setButtonLoader(false);
              errorMsg(signature.message);
              return;
            } else {
              // setSignatureVerify(true)
              let data = {
                tokenId: props.data.orders[0].tokenId,
                listingTime: order.listingTime,
                paymentToken: selectedValue,
                hash: orderHash,
                salt: order.salt,
                signature: signature,
                type: "sale",
                basePrice: Number(formData.amount),
                expirationTime: order.expirationTime,
                maximumFill: order.maximumFill,
              };
              try {
                asyncPostMint(data, setShow);
                setButtonLoader(false);
                setFormData({
                  amount: "",
                  quantity: "",
                  date: moment().format(),
                })
              } catch (error) {
                errorMsg("something went wrong!");
                setButtonLoader(false);
              }
            }
          } else {
            console.log('Order Object', JSON.stringify(order));
            const { signature, orderHash } = await getOrderSignature(order);
            console.log(signature, "signature", orderHash);
            if (signature.hasOwnProperty("code")) {
              setButtonLoader(false);
              errorMsg(signature.message);
              return;
            } else {
              let data = {
                tokenId: props.data.orders[0].tokenId,
                listingTime: order.listingTime,
                paymentToken: selectedValue,
                hash: orderHash,
                salt: order.salt,
                signature: signature,
                type: "sale",
                basePrice: Number(formData.amount),
                expirationTime: order.expirationTime,
                maximumFill: order.maximumFill,
              };
              try {
                asyncPostMint(data, setShow);
                setButtonLoader(false);
                setFormData({
                  amount: "",
                  quantity: "",
                  date: moment().format(),
                })
              } catch (error) {
                errorMsg("something went wrong!");
                setButtonLoader(false);
              }
            }
          }
        } else {
          // setProxyVerify(true);
          // setSignatureVerify(false);
          const { signature, orderHash } = await getOrderSignature(order);
          console.log(signature, "signature", orderHash);

          if (signature.hasOwnProperty("code")) {
            setButtonLoader(false);
            errorMsg(signature.message);
            return;
          } else {
            // setSignatureVerify(true);
            let data = {
              tokenId: props.data.orders[0].tokenId,
              listingTime: order.listingTime,
              paymentToken: selectedValue,
              hash: orderHash,
              salt: order.salt,
              signature: signature,
              type: "sale",
              basePrice: Number(formData.amount),
              expirationTime: order.expirationTime,
              maximumFill: order.maximumFill,
            };
            try {
              asyncPostMint(data, setShow);
              setButtonLoader(false);
              setFormData({
                amount: "",
                quantity: "",
                date: moment().format(),
              })
            } catch (error) {
              errorMsg("something went wrong!");
              setButtonLoader(false);
            }
          }
        }
      } catch (error) {
        console.log(error);
        setButtonLoader(false);
      }
    }
  };

  return (
    <div className="new-modal-Campaigns ">
      <Modal
        show={props.show}
        centered
        size="lg"
        className="main_popup_inner donate_popup_main addcampaigns_popup add-campaigns-modal"
        onHide={props.close}
      >
        {buttonloader === true ? '' : <FontAwesomeIcon onClick={props.close} icon={faTimes} />}
        <Modal.Header>
          <Modal.Title className="popup_title_name">
            {buttonloader === true ? 'Process' : 'Sale NFT'}
          </Modal.Title>
        </Modal.Header>
        <div className="row main_popup_inner1 new-all-inputs">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
            <FormHelperText sx={{ color: "#fff", marginBottom: "5px" }}>
              Price
            </FormHelperText>
            <TextField
              sx={{
                width: "100%",
              }}
              error={formErrors.amount}
              name="amount"
              className="input_username new-edit-main-all"
              id="outlined-basic"
              placeholder="Sale price"
              type="number"
              onChange={(e) => {
                handleChange(e);
              }}
              helperText={formErrors.amount}
            />
          </div>
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
            <FormHelperText sx={{ color: "#fff", marginBottom: "5px" }}>
              Quantity
            </FormHelperText>
            <TextField
              sx={{
                width: "100%",
              }}
              error={formErrors.quantity}
              name="quantity"
              className="input_username new-edit-main-all"
              id="outlined-basic"
              type="number"
              placeholder="Sale quantity"
              onChange={(e) => handleChange(e)}
              helperText={formErrors.quantity}
            />
          </div>
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
            <FormHelperText sx={{ color: "#fff", marginBottom: "5px" }}>
              Payment Token
            </FormHelperText>
            <FormControl fullWidth>
              <Select
                className="input_username new-edit-main-all"
                id="demo-simple-select-helper-label"
                name="paymentToken"
                value={selectedValue}
                onChange={handleSelect}
              >
                {paymentTokens &&
                  paymentTokens.length > 0 &&
                  paymentTokens.map((value, index) => {
                    return (
                      <MenuItem key={index} value={value.value}>
                        {value.label}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </div>
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
            <FormHelperText sx={{ color: "#fff", marginBottom: "5px" }}>
              Expiration Time
            </FormHelperText>
            <Flatpickr
              name="dob"
              placeholder="dob"
              options={{
                minDate: formData.date,
              }}
              defaultValue={formData.date}
              className="input_username new-edit-main-all flatpickrDate"
              onChange={([date]) => {
                setFormData((formData) => ({
                  ...formData,
                  date: moment(date).format(),
                }));
              }}
            />
          </div>
        </div>
        <div className="popup_donate_btn">
          {buttonloader === true ? (
            <button type="button" className="d-flex justify-content-center">
              <SmallLoader
                type="spinner-default"
                bgColor={"#fff"}
                color={"#fff"}
                size={40}
              />
            </button>
          ) : (
            <button
              onClick={() => {
                handleSaleNft();
              }}
            >
              Sale NFT
            </button>
          )}
        </div>
        {/* {buttonloader === false ?
          <>
            <div className="row main_popup_inner1 new-all-inputs">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                <FormHelperText sx={{ color: "#fff", marginBottom: "5px" }}>
                  Amount
                </FormHelperText>
                <TextField
                  sx={{
                    width: "100%",
                  }}
                  error={formErrors.amount}
                  name="amount"
                  className="input_username new-edit-main-all"
                  id="outlined-basic"
                  placeholder="Enter amount"
                  type="number"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  helperText={formErrors.amount}
                />
              </div>
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                <FormHelperText sx={{ color: "#fff", marginBottom: "5px" }}>
                  Quantity
                </FormHelperText>
                <TextField
                  sx={{
                    width: "100%",
                  }}
                  error={formErrors.quantity}
                  name="quantity"
                  className="input_username new-edit-main-all"
                  id="outlined-basic"
                  type="number"
                  placeholder="Enter quantity"
                  onChange={(e) => handleChange(e)}
                  helperText={formErrors.quantity}
                />
              </div>
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                <FormHelperText sx={{ color: "#fff", marginBottom: "5px" }}>
                  Expiration Time
                </FormHelperText>
                <Flatpickr
                  name="dob"
                  placeholder="dob"
                  options={{
                    minDate: formData.date,
                  }}
                  defaultValue={formData.date}
                  className="input_username new-edit-main-all flatpickrDate"
                  onChange={([date]) => {
                    setFormData((formData) => ({
                      ...formData,
                      date: moment(date).format(),
                    }));
                  }}
                />
              </div>
            </div>
            <div className="popup_donate_btn">
              {buttonloader === true ? (
                <button type="button" className="d-flex justify-content-center">
                  <SmallLoader
                    type="spinner-default"
                    bgColor={"#fff"}
                    color={"#fff"}
                    size={40}
                  />
                </button>
              ) : (
                <button
                  onClick={() => {
                    handleSaleNft();
                  }}
                >
                  Sale NFT
                </button>
              )}
            </div>
          </>
          : <>
            <Accordion
              defaultExpanded={true}
              TransitionProps={{ unmountOnExit: true }}
              className="custom-accordion"
            >
              <AccordionSummary
                expandIcon={<><ExpandMoreIcon /><VerifiedIcon sx={{ color: '#ff00f2' }} /></>}
                aria-controls="activity-content"
                id="activity-header"
                className="accordion-header"
              >
                <p className="text-left">Proxy Address</p>
              </AccordionSummary>
              <AccordionDetails>
                <h1 className="process-text">Processing  <SmallLoader
                  type="spinner-default"
                  bgColor={"#000"}
                  color={"#fff"}
                  size={40}
                /></h1>
              </AccordionDetails>
            </Accordion>
            <Accordion
              defaultExpanded={true}
              TransitionProps={{ unmountOnExit: true }}
              className="custom-accordion"
            >
              <AccordionSummary
                expandIcon={<><ExpandMoreIcon /><VerifiedIcon sx={{ color: '#ff00f2' }} /></>}
                aria-controls="activity-content"
                id="activity-header"
                className="accordion-header"
              >
                <p className="text-left">Token Allowance</p>
              </AccordionSummary>
              <AccordionDetails>
                <h1 className="process-text">Processing  <SmallLoader
                  type="spinner-default"
                  bgColor={"#000"}
                  color={"#fff"}
                  size={40}
                /></h1>
              </AccordionDetails>
            </Accordion>
            <Accordion
              defaultExpanded={false}
              TransitionProps={{ unmountOnExit: true }}
              className="custom-accordion"
            >
              <AccordionSummary
                expandIcon={<><ExpandMoreIcon /><VerifiedIcon sx={{ color: '#ff00f2' }} /></>}
                aria-controls="activity-content"
                id="activity-header"
                className="accordion-header"
              >
                <p className="text-left">Signature the order</p>
              </AccordionSummary>
              <AccordionDetails>
                <h1 className="process-text">Processing  <SmallLoader
                  type="spinner-default"
                  bgColor={"#000"}
                  color={"#fff"}
                  size={40}
                /></h1>
              </AccordionDetails>
            </Accordion>
            <div className="new-accordion">
              <div className="na-head">
                <p>Proxy Address</p>
                <VerifiedIcon sx={{ color: '#ff00f2' }} />
              </div>
              <div className="na-body">
                <h1 className="process-text">Processing  <SmallLoader
                  type="spinner-default"
                  bgColor={"#000"}
                  color={"#fff"}
                  size={40}
                /></h1>
              </div>
            </div>
            <div className="new-accordion">
              <div className="na-head">
                <p>Token Allowance</p>
                <VerifiedIcon sx={{ color: '#ff00f2' }} />
              </div>
              <div className="na-body">
                <h1 className="process-text">Processing  <SmallLoader
                  type="spinner-default"
                  bgColor={"#000"}
                  color={"#fff"}
                  size={40}
                /></h1>
              </div>
            </div>
            <div className="new-accordion">
              <div className="na-head">
                <p>Signature the order</p>
                <VerifiedIcon sx={{ color: '#ff00f2' }} />
              </div>
              <div className="na-body">
                <h1 className="process-text">Processing  <SmallLoader
                  type="spinner-default"
                  bgColor={"#000"}
                  color={"#fff"}
                  size={40}
                /></h1>
              </div>
            </div>
          </>} */}
      </Modal>
    </div>
  );
};

export default NftSellModal;
