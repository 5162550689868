import React, { useRef, useState, useEffect } from 'react';
import 'react-h5-audio-player/lib/styles.css';
import AudioPlayer from 'react-h5-audio-player';
import './AudioPlayerCardTimeline.css'; // Importing new, scoped CSS
import { asyncPostPlay } from '../../actions/uploadBlogs';

const CustomAudioPlayer = ({ src, backgroundImage, id }) => {
    const audioPlayerRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);

    const handlePlay = () => {
        const currentPlayer = audioPlayerRef.current.audio.current;

        // Pause all other players
        const allPlayers = document.querySelectorAll('audio');
        allPlayers.forEach(player => {
            if (player !== currentPlayer) {
                player.pause();
            }
        });

        setIsPlaying(true);

        if (id) {
            asyncPostPlay(id);
        }
    };

    const handlePause = () => {
        setIsPlaying(false);
    };

    useEffect(() => {
        const handleGlobalPlay = event => {
            const currentPlayer = audioPlayerRef.current.audio.current;
            if (event.target !== currentPlayer) {
                currentPlayer.pause();
            }
        };

        document.addEventListener('play', handleGlobalPlay, true);

        return () => {
            document.removeEventListener('play', handleGlobalPlay, true);
        };
    }, []);

    return (
        <div key={id} className="custom-audio-player-container" style={{ backgroundImage: `url(${backgroundImage})` }}>
            <AudioPlayer
                ref={audioPlayerRef}
                src={src}
                key={id}
                onPlay={handlePlay}
                onPause={handlePause}
                onEnded={handlePause}
                customAdditionalControls={[]}
                customVolumeControls={[]}
                showJumpControls={false}
                layout="horizontal-reverse"
                className="custom-audio-player"
            />
        </div>
    );
};

export default CustomAudioPlayer;
