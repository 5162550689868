import React, { useRef, useState } from "react";
import { FormHelperText, TextField } from "@mui/material";

import "flatpickr/dist/flatpickr.css";

import MyAccountTapModal from "../Modal/MyAccountTapModal";
import "quill/dist/quill.snow.css";
// import ReactQuill from "react-quill";
// import DatePicker from "react-date-picker";
// import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";

import "flatpickr/dist/themes/material_green.css";
import moment from "moment";
// import validator from "validator";

import Flatpickr from "react-flatpickr";
import {
  asyncUpdateProfile,
  asyncAddUpdateProfile,
} from "../../actions/authActions";
import { errorMsg, handleScrollError, images } from "../../actions/customFn";
import { useDispatch, useSelector } from "react-redux";
import { getCityList, getStateList } from "../../actions/CountryAction";
import { InputLabel, MenuItem, Select, FormControl } from "@mui/material";
import { useEffect } from "react";
import { motion } from "framer-motion";
import Loader from "../../hooks/Loader";
// import SmallLoader from "react-js-loader";
// import { Textarea } from "@mui/joy";
import { useAccount, useWalletClient, useClient } from "wagmi";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { getAuthData } from "../../utils/apiHandlers";
// import { tokenDetails } from "../../utils/data";
import { polygon, polygonAmoy } from "wagmi/chains";
import { publicActions, getContract } from "viem";
import { StarbayProxyRegistry } from "../../contracts";
import { contracts, zeroAddress } from "../../utils/env";
// import { switchChain } from "@wagmi/core";

// var modules = {
//   toolbar: [
//     [{ size: ["small", false, "large", "huge" }],
//     ["bold", "italic", "underline", "strike", "blockquote",
//     [{ list: "ordered" }, { list: "bullet" }],
//     ["link", "image",
//     [
//       { list: "ordered" },
//       { list: "bullet" },
//       { indent: "-1" },
//       { indent: "+1" },
//       { align: [] },
//     ],
//     [
//       {
//         color: [
//           "#000000",
//           "#e60000",
//           "#ff9900",
//           "#ffff00",
//           "#008a00",
//           "#0066cc",
//           "#9933ff",
//           "#ffffff",
//           "#facccc",
//           "#ffebcc",
//           "#ffffcc",
//           "#cce8cc",
//           "#cce0f5",
//           "#ebd6ff",
//           "#bbbbbb",
//           "#f06666",
//           "#ffc266",
//           "#ffff66",
//           "#66b966",
//           "#66a3e0",
//           "#c285ff",
//           "#888888",
//           "#a10000",
//           "#b26b00",
//           "#b2b200",
//           "#006100",
//           "#0047b2",
//           "#6b24b2",
//           "#444444",
//           "#5c0000",
//           "#663d00",
//           "#666600",
//           "#003700",
//           "#002966",
//           "#3d1466",
//           "custom-color",
//         ],
//       },
//     ],
//   ],
// };

// var formats = [
//   "header",
//   "height",
//   "bold",
//   "italic",
//   "underline",
//   "strike",
//   "blockquote",
//   "list",
//   "color",
//   "bullet",
//   "indent",
//   "link",
//   "image",
//   "align",
//   "size",
// ];

const MyAccountTap = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const dispatch = useDispatch();
  const { address } = useAccount();
  const { open } = useWeb3Modal();
  const [loadPage, setLoadPage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonloader, setButtonLoader] = useState(false);

  const [editorType, setEditorType] = useState("");

  const { country_list } = useSelector((state) => state.countryData);
  const { userInfo } = useSelector((state) => state.userAuth);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [walletAddress, setWalletAddress] = useState("");
  const [formErrors, setFormError] = useState({});
  const errors = {};

  const [file, setFile] = useState();
  const [aiImage, setAiImage] = useState();

  const inputFile = useRef(null);
  const inputimage = useRef(null);
  const network = process.env.REACT_APP_NETWORK;
  const client = useClient({
    chainId: network === "mainnet" ? polygon.id : polygonAmoy.id,
  });
  let { data: _walletClient, isError, isLoading } = useWalletClient({
    chainId: network === "mainnet" ? polygon.id : polygonAmoy.id,
    account: address,
  });
  const walletClient = _walletClient
    ? _walletClient.extend(publicActions)
    : null;
  const [form, setForm] = useState({
    tokenAddress: "",
  });
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    firstname: "",
    lastname: "",
    address: "",
    phone: "",
    country: "",
    state: "",
    city: "",
    street: "",
    dob: "",
    short_info: "",
    bio_info: "",
    image: "",
    myai_image: "",
  });

  useEffect(() => {
    if (userInfo !== undefined) {
      setFormData({
        username: userInfo.username,
        email: userInfo.email,
        firstname: userInfo.first_name,
        lastname: userInfo.last_name,
        address: userInfo.address,
        phone: userInfo.phone_number,
        country: userInfo.country_id,
        state: userInfo.state_id,
        city: userInfo.city_id,
        street: userInfo.street_address,
        dob: userInfo.dob,
        short_info: userInfo.short_info,
        bio_info: userInfo.biografical_info,
        image: userInfo.image,
        myai_image: userInfo.myai_image,
      });
    }
  }, [userInfo]);

  const handleChange = (e) => {
    if (e.target !== undefined) {
      const { name, value } = e.target;
      if (name === "image" || name === "myai_image") {
        setFormData((formData) => ({ ...formData, [name]: e.target.files[0] }));
      } else {
        setFormData((formData) => ({ ...formData, [name]: value }));
      }
    } else {
      if (editorType !== "") {
        setFormData((formData) => ({ ...formData, [editorType]: e }));
      }
    }
  };
  const handleChangeNew = (e) => {
    const { name, value } = e.target;
    setForm((form) => ({ ...form, [name]: value }));
  };
  const validateForm = async () => {
    try {
      if (form.tokenAddress === "" || form.tokenAddress === null) {
        errors.tokenAddress = "Enter your token address";
      }
      setFormError(errors);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddUpdateToken = async () => {
    setLoading(true);
    try {
      validateForm();

      const registry = getContract({
        address: contracts.Registry,
        abi: StarbayProxyRegistry,
        client: walletClient,
      });
      // const currentChainId = await walletClient.getChainId();
      // const targetChainId = network === "mainnet" ? polygon.id : polygonAmoy.id;

      // if (currentChainId !== targetChainId) {
      //   await walletClient.switchChain({ id: targetChainId });
      // }
      const proxyAddress = await registry.read.proxies([address]);
      console.log(proxyAddress, "proxyAddress");
      if (proxyAddress === zeroAddress) {
        console.log(walletClient, "walletClient");

        await walletClient.switchChain({
          id: network === "mainnet" ? polygon.id : polygonAmoy.id,
        });
        const { request } = await walletClient.simulateContract({
          address: contracts.Registry,
          abi: StarbayProxyRegistry,
          functionName: "registerProxy",
          arge: [],
          account: address && address,
        });

        console.log(request, "12request");
        // Write the contract transaction

        const transactionHash = await walletClient.writeContract(request);
        const transactionReceipt = await walletClient.waitForTransactionReceipt(
          {
            hash: transactionHash,
          }
        );

        console.debug("Tx:", transactionReceipt);
      }

      if (Object.keys(errors).length === 0) {
        asyncAddUpdateProfile(form, dispatch, setButtonLoader);
        setLoading(false);
        getWalletAddress();
      } else {
        handleScrollError(errors);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
      errorMsg(
        "Something went wrong. Please check the console for more details."
      );
    }
  };
  const getWalletAddress = async () => {
    try {
      const response = await getAuthData("/users/me/wallet");
      if (response?.status === 201 || response?.status === 200) {
        setWalletAddress(response.data);
      }
    } catch (e) {
      console.error(e);
      return null;
    }
  };
  useEffect(() => {
    getWalletAddress();
  }, []);

  useEffect(() => {
    if (walletAddress !== "" || address !== undefined) {
      setForm({ tokenAddress: walletAddress?.address || address });
    }
  }, [walletAddress, address]);
  const validateFormData = async () => {
    try {
      // const phoneRegex = /^[0-9]{10}$/;
      const phoneRegex = /^\d{7,20}$/;
      const alphabetRegex = /^[A-Za-z]+$/;
      if (formData.username == "" || formData.username == null) {
        errors.username = "Enter your username properly ";
      }

      if (formData.firstname == "" || formData.firstname == null) {
        errors.firstname = "Enter your firstname properly";
      } else if (!alphabetRegex.test(formData.firstname)) {
        errors.firstname = "Enter First name with only alphabets";
      }

      if (formData.lastname == "" || formData.lastname == null) {
        errors.lastname = "Enter your lastname properly";
      }
      if (formData.address == "" || formData.address == null) {
        errors.address = "Enter your address properly";
      }
      if (formData.phone == "" || formData.phone == null) {
        errors.phone = "Enter your phone number ";
      } else if (!phoneRegex.test(formData.phone)) {
        errors.phone = "Phone number must contains 7 to 20 digits";
      }

      if (formData.country == "" || formData.country == null) {
        errors.country = "Enter your country properly";
      }
      if (formData.state == "" || formData.state == null) {
        errors.state = "Enter your state properly";
      }
      if (formData.city == "" || formData.city == null) {
        errors.city = "Enter your city properly";
      }
      if (formData.street == "" || formData.street == null) {
        errors.street = "Enter your street address ";
      }
      if (formData.dob == "" || formData.dob == null) {
        errors.dob = "Enter your dob properly";
      }
      // if (formData.short_info == "" || formData.short_info == null) {
      //   errors.short_info = "Enter short info properly";
      // }
      // if (formData.bio_info == "" || formData.bio_info == null) {
      //   errors.bio_info = "Enter bio info properly";
      // }

      if (formData.image == "" || formData.image == undefined) {
        errors.image = "Please choose your profile image";
      }

      if (formData.myai_image == "" || formData.myai_image == undefined) {
        errors.myai_image = "Please choose your game image";
      }

      setFormError(errors);
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateProfile = async (e) => {
    try {
      e.preventDefault();
      validateFormData();
      if (Object.keys(errors).length === 0) {
        // setButtonLoader(true);
        asyncUpdateProfile(formData, dispatch, setButtonLoader);
      } else {
        handleScrollError(errors);
      }
    } catch (error) {
      errorMsg("something went wrong!");
    }
  };

  // ---------------
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  useEffect(() => {
    if (formData.country != undefined && formData.country != "") {
      getStateList(setStateList, formData.country);
    }
  }, [formData.country]);

  useEffect(() => {
    if (formData.state != undefined && formData.state != "") {
      getCityList(setCityList, formData.state);
    }
  }, [formData.state]);
  // const handleProcedureContentChange = (content) => {};

  // const [value, onChange] = useState(new Date());

  function showImage(e) {
    if (e.target.files.length > 0) {
      setFile(URL.createObjectURL(e.target.files[0]));
    }
  }
  function showMyAiImage(e) {
    if (e.target.files.length > 0) {
      setAiImage(URL.createObjectURL(e.target.files[0]));
    }
  }

  //   const handleChangebio_info = (e) => {
  //     setFormData((formData) => ({ ...formData, ["bio_info"]: e }));
  //   };

  //   const handleChangeshort_info = (e) => {
  //     setFormData((formData) => ({ ...formData, ["short_info"]: e }));
  //   };
  return (
    <motion.div
      initial={{ opacity: 0.2 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 1 }}
      transition={{ duration: 2 }}
    >
      {loadPage == true ? (
        <Loader />
      ) : (
        <>
          <div className="my_accounttap_main account-details-form">
            <div className="my_accounttap_top_section p-2">
              <h1>My Account Details</h1>
              <span>
                <button
                  className="my_accounttap_chang_password"
                  variant="primary"
                  onClick={handleShow}
                >
                  Change Password
                </button>

                <button
                  className="my_accountiap_update"
                  onClick={(e) => handleUpdateProfile(e)}
                >
                  Update
                </button>
              </span>
            </div>
            <hr className="friends_under_line" />

            {/* {console.log("formData.image", file)} */}
            <div className="container ">
              <div className="row m-0">
                <div className="col-6 accounttab_update_img">
                  <div className="profile_user_image ">
                    <div className="user_image">
                      {formData.image != undefined && formData.image != "" ? (
                        <img
                          src={
                            file == "" || file == undefined
                              ? formData.image
                              : file
                          }
                          // src={  URL.createObjectURL(formData.image)}

                          alt=""
                        />
                      ) : (
                        <img src={"/images/user_profile.png"} alt="" />
                      )}
                    </div>

                    <div className="profile_btn_change">
                      <button
                        className="chnange_profile_btn"
                        // onClick={() => inputFile.current.click()}
                        onClick={(e) => inputFile.current.click(e)}
                      >
                        {" "}
                        <img src={"/images/change_profile.png"} alt="" />{" "}
                      </button>
                    </div>

                    <input
                      type="file"
                      name="image"
                      id="fileInput"
                      inputProps={{ accept: "image/*" }}
                      className="d-none"
                      // onChange={editProfileData}
                      onChange={(e) => {
                        handleChange(e);
                        showImage(e);
                      }}
                      ref={inputFile}
                    />
                  </div>
                  {formErrors.image && (
                    <div className="image_error">
                      <p> {formErrors.image} </p>
                    </div>
                  )}
                  <h6>My Profile Image</h6>
                </div>
                {/* ------------- ai image --------------- */}
                <div className="col-6 accounttab_update_img">
                  <div className="profile_user_image ">
                    <div className="user_image">
                      {formData.myai_image != undefined &&
                        formData.myai_image != "" ? (
                        <img
                          src={
                            aiImage == "" || aiImage == undefined
                              ? formData.myai_image
                              : aiImage
                          }
                          // src={  URL.createObjectURL(formData.image)}

                          alt=""
                        />
                      ) : (
                        <img src={"/images/user_profile.png"} alt="" />
                      )}
                    </div>

                    <div className="profile_btn_change">
                      <button
                        className="chnange_profile_btn"
                        // onClick={() => inputFile.current.click()}
                        onClick={(e) => inputimage.current.click(e)}
                      >
                        {" "}
                        <img src={"/images/change_profile.png"} alt="" />{" "}
                      </button>
                    </div>

                    <input
                      type="file"
                      name="myai_image"
                      id="fileInput"
                      inputProps={{ accept: "image/*" }}
                      className="d-none"
                      // onChange={editProfileData}
                      onChange={(e) => {
                        handleChange(e);
                        showMyAiImage(e);
                      }}
                      ref={inputimage}
                    />
                  </div>
                  {formErrors.myai_image && (
                    <div className="image_error">
                      <p> {formErrors.myai_image} </p>
                    </div>
                  )}
                  <h6>My Game Image</h6>
                </div>
              </div>
            </div>

            <div className="my_accounttap_inptfields new-all-inputs mx-2">
              <h1>Account Details</h1>
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                  <FormHelperText sx={{ color: "#fff", marginBottom: "5px" }}>
                    Username <span className="text-danger">*</span>
                  </FormHelperText>
                  <TextField
                    sx={{
                      width: "100%",
                    }}
                    error={formErrors.username}
                    name="username"
                    className="input_username new-edit-main-all"
                    id="outlined-basic"
                    value={formData.username}
                    placeholder="Enter user name"
                    onChange={handleChange}
                    helperText={formErrors.username}
                  />
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                  <FormHelperText sx={{ color: "#fff", marginBottom: "5px" }}>
                    Email Address <span className="text-danger">*</span>
                  </FormHelperText>
                  <TextField
                    sx={{
                      width: "100%",
                    }}
                    error={formErrors.email}
                    value={formData.email}
                    name="email"
                    className="input_username new-edit-main-all"
                    id="outlined-basic"
                    aria-readonly
                    placeholder="Enter email address"
                    onChange={handleChange}
                    helperText={formErrors.email}
                  />
                </div>
              </div>
            </div>
            <div className="my_accounttap_inptfields new-all-inputs mx-2">
              <h1>Profile Details</h1>
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-2">
                  {/* <div className="pic_dates  register_datepicker">
              <h6>My Birthday</h6>
              <DatePicker name="dob" 
               onChange={([date]) => {
                setFormData((formData) => ({
                  ...formData,
                  dob: moment(date).format("yyyy-MM-DD"),
                }));
              }}
            
               />
         
              <span className="date_time">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="22"
                  viewBox="0 0 24 22"
                  fill="none"
                >
                  <g clipPath="url(#clip0_830_1362)">
                    <path
                      d="M16.5 8.9375C16.5 8.75516 16.579 8.5803 16.7197 8.45136C16.8603 8.32243 17.0511 8.25 17.25 8.25H18.75C18.9489 8.25 19.1397 8.32243 19.2803 8.45136C19.421 8.5803 19.5 8.75516 19.5 8.9375V10.3125C19.5 10.4948 19.421 10.6697 19.2803 10.7986C19.1397 10.9276 18.9489 11 18.75 11H17.25C17.0511 11 16.8603 10.9276 16.7197 10.7986C16.579 10.6697 16.5 10.4948 16.5 10.3125V8.9375ZM12 8.9375C12 8.75516 12.079 8.5803 12.2197 8.45136C12.3603 8.32243 12.5511 8.25 12.75 8.25H14.25C14.4489 8.25 14.6397 8.32243 14.7803 8.45136C14.921 8.5803 15 8.75516 15 8.9375V10.3125C15 10.4948 14.921 10.6697 14.7803 10.7986C14.6397 10.9276 14.4489 11 14.25 11H12.75C12.5511 11 12.3603 10.9276 12.2197 10.7986C12.079 10.6697 12 10.4948 12 10.3125V8.9375ZM4.5 13.0625C4.5 12.8802 4.57902 12.7053 4.71967 12.5764C4.86032 12.4474 5.05109 12.375 5.25 12.375H6.75C6.94891 12.375 7.13968 12.4474 7.28033 12.5764C7.42098 12.7053 7.5 12.8802 7.5 13.0625V14.4375C7.5 14.6198 7.42098 14.7947 7.28033 14.9236C7.13968 15.0526 6.94891 15.125 6.75 15.125H5.25C5.05109 15.125 4.86032 15.0526 4.71967 14.9236C4.57902 14.7947 4.5 14.6198 4.5 14.4375V13.0625ZM9 13.0625C9 12.8802 9.07902 12.7053 9.21967 12.5764C9.36032 12.4474 9.55109 12.375 9.75 12.375H11.25C11.4489 12.375 11.6397 12.4474 11.7803 12.5764C11.921 12.7053 12 12.8802 12 13.0625V14.4375C12 14.6198 11.921 14.7947 11.7803 14.9236C11.6397 15.0526 11.4489 15.125 11.25 15.125H9.75C9.55109 15.125 9.36032 15.0526 9.21967 14.9236C9.07902 14.7947 9 14.6198 9 14.4375V13.0625Z"
                      fill="white"
                    />
                    <path
                      d="M5.25 0C5.44891 0 5.63968 0.0724328 5.78033 0.201364C5.92098 0.330295 6 0.505164 6 0.6875V1.375H18V0.6875C18 0.505164 18.079 0.330295 18.2197 0.201364C18.3603 0.0724328 18.5511 0 18.75 0C18.9489 0 19.1397 0.0724328 19.2803 0.201364C19.421 0.330295 19.5 0.505164 19.5 0.6875V1.375H21C21.7956 1.375 22.5587 1.66473 23.1213 2.18046C23.6839 2.69618 24 3.39565 24 4.125V19.25C24 19.9793 23.6839 20.6788 23.1213 21.1945C22.5587 21.7103 21.7956 22 21 22H3C2.20435 22 1.44129 21.7103 0.87868 21.1945C0.316071 20.6788 0 19.9793 0 19.25V4.125C0 3.39565 0.316071 2.69618 0.87868 2.18046C1.44129 1.66473 2.20435 1.375 3 1.375H4.5V0.6875C4.5 0.505164 4.57902 0.330295 4.71967 0.201364C4.86032 0.0724328 5.05109 0 5.25 0ZM1.5 5.5V19.25C1.5 19.6147 1.65804 19.9644 1.93934 20.2223C2.22064 20.4801 2.60218 20.625 3 20.625H21C21.3978 20.625 21.7794 20.4801 22.0607 20.2223C22.342 19.9644 22.5 19.6147 22.5 19.25V5.5H1.5Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_830_1362">
                      <rect width="24" height="22" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </span>
            </div> */}
                  <FormHelperText sx={{ color: "#fff", marginBottom: "5px" }}>
                    My Birthday
                  </FormHelperText>
                  <div className="pic_dates account_datepicker">
                    {/* <h6>My Birthday</h6> */}
                    {/* <DatePicker name="dob" /> */}
                    <Flatpickr
                      name="dob"
                      placeholder="dob"
                      // {
                      //   minDate: {userInfo.dob}
                      // }
                      defaultValue={userInfo.dob}
                      className="input_username  "
                      onChange={([date]) => {
                        setFormData((formData) => ({
                          ...formData,
                          dob: moment(date).format("yyyy-MM-DD"),
                        }));
                      }}
                    />
                    <span className="date_time">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="22"
                        viewBox="0 0 24 22"
                        fill="none"
                      >
                        <g clipPath="url(#clip0_830_1362)">
                          <path
                            d="M16.5 8.9375C16.5 8.75516 16.579 8.5803 16.7197 8.45136C16.8603 8.32243 17.0511 8.25 17.25 8.25H18.75C18.9489 8.25 19.1397 8.32243 19.2803 8.45136C19.421 8.5803 19.5 8.75516 19.5 8.9375V10.3125C19.5 10.4948 19.421 10.6697 19.2803 10.7986C19.1397 10.9276 18.9489 11 18.75 11H17.25C17.0511 11 16.8603 10.9276 16.7197 10.7986C16.579 10.6697 16.5 10.4948 16.5 10.3125V8.9375ZM12 8.9375C12 8.75516 12.079 8.5803 12.2197 8.45136C12.3603 8.32243 12.5511 8.25 12.75 8.25H14.25C14.4489 8.25 14.6397 8.32243 14.7803 8.45136C14.921 8.5803 15 8.75516 15 8.9375V10.3125C15 10.4948 14.921 10.6697 14.7803 10.7986C14.6397 10.9276 14.4489 11 14.25 11H12.75C12.5511 11 12.3603 10.9276 12.2197 10.7986C12.079 10.6697 12 10.4948 12 10.3125V8.9375ZM4.5 13.0625C4.5 12.8802 4.57902 12.7053 4.71967 12.5764C4.86032 12.4474 5.05109 12.375 5.25 12.375H6.75C6.94891 12.375 7.13968 12.4474 7.28033 12.5764C7.42098 12.7053 7.5 12.8802 7.5 13.0625V14.4375C7.5 14.6198 7.42098 14.7947 7.28033 14.9236C7.13968 15.0526 6.94891 15.125 6.75 15.125H5.25C5.05109 15.125 4.86032 15.0526 4.71967 14.9236C4.57902 14.7947 4.5 14.6198 4.5 14.4375V13.0625ZM9 13.0625C9 12.8802 9.07902 12.7053 9.21967 12.5764C9.36032 12.4474 9.55109 12.375 9.75 12.375H11.25C11.4489 12.375 11.6397 12.4474 11.7803 12.5764C11.921 12.7053 12 12.8802 12 13.0625V14.4375C12 14.6198 11.921 14.7947 11.7803 14.9236C11.6397 15.0526 11.4489 15.125 11.25 15.125H9.75C9.55109 15.125 9.36032 15.0526 9.21967 14.9236C9.07902 14.7947 9 14.6198 9 14.4375V13.0625Z"
                            fill="white"
                          />
                          <path
                            d="M5.25 0C5.44891 0 5.63968 0.0724328 5.78033 0.201364C5.92098 0.330295 6 0.505164 6 0.6875V1.375H18V0.6875C18 0.505164 18.079 0.330295 18.2197 0.201364C18.3603 0.0724328 18.5511 0 18.75 0C18.9489 0 19.1397 0.0724328 19.2803 0.201364C19.421 0.330295 19.5 0.505164 19.5 0.6875V1.375H21C21.7956 1.375 22.5587 1.66473 23.1213 2.18046C23.6839 2.69618 24 3.39565 24 4.125V19.25C24 19.9793 23.6839 20.6788 23.1213 21.1945C22.5587 21.7103 21.7956 22 21 22H3C2.20435 22 1.44129 21.7103 0.87868 21.1945C0.316071 20.6788 0 19.9793 0 19.25V4.125C0 3.39565 0.316071 2.69618 0.87868 2.18046C1.44129 1.66473 2.20435 1.375 3 1.375H4.5V0.6875C4.5 0.505164 4.57902 0.330295 4.71967 0.201364C4.86032 0.0724328 5.05109 0 5.25 0ZM1.5 5.5V19.25C1.5 19.6147 1.65804 19.9644 1.93934 20.2223C2.22064 20.4801 2.60218 20.625 3 20.625H21C21.3978 20.625 21.7794 20.4801 22.0607 20.2223C22.342 19.9644 22.5 19.6147 22.5 19.25V5.5H1.5Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_830_1362">
                            <rect width="24" height="22" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>

                    {formErrors.dob && (
                      <div className="invalid-msg">{formErrors.dob}</div>
                    )}
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12  mb-2">
                  <FormHelperText sx={{ color: "#fff", marginBottom: "5px" }}>
                    First Name <span className="text-danger">*</span>
                  </FormHelperText>
                  <TextField
                    sx={{
                      width: "100%",
                    }}
                    error={formErrors.firstname}
                    name="firstname"
                    className="input_username new-edit-main-all"
                    id="outlined-basic"
                    value={formData.firstname}
                    placeholder="Enter your first name"
                    onChange={handleChange}
                    helperText={formErrors.firstname}
                  />
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12  mb-2">
                  <FormHelperText sx={{ color: "#fff", marginBottom: "5px" }}>
                    Last Name <span className="text-danger">*</span>
                  </FormHelperText>
                  <TextField
                    sx={{
                      width: "100%",
                    }}
                    error={formErrors.lastname}
                    name="lastname"
                    className="input_username new-edit-main-all"
                    id="outlined-basic"
                    value={formData.lastname}
                    placeholder="Enter your last name"
                    onChange={handleChange}
                    helperText={formErrors.lastname}
                  />
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12  mb-2">
                  <FormHelperText sx={{ color: "#fff", marginBottom: "5px" }}>
                    My Address
                  </FormHelperText>
                  <TextField
                    sx={{
                      width: "100%",
                    }}
                    error={formErrors.address}
                    name="address"
                    className="input_username new-edit-main-all"
                    id="outlined-basic"
                    value={formData.address}
                    placeholder="Enter address"
                    onChange={handleChange}
                    helperText={formErrors.address}
                  />
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-2">
                  <FormHelperText sx={{ color: "#fff", marginBottom: "5px" }}>
                    My Phone Number
                  </FormHelperText>
                  <TextField
                    sx={{
                      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                        display: "none",
                      },
                      "& input[type=number]": {
                        MozAppearance: "textfield",
                      },
                      width: "100%",
                    }}
                    error={formErrors.phone}
                    name="phone"
                    type="number"
                    className="input_username new-edit-main-all"
                    id="outlined-basic"
                    value={formData.phone}
                    placeholder="Enter phone number"
                    onChange={handleChange}
                    helperText={formErrors.phone}
                  />
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12  mb-2">
                  {/* <TextField
              sx={{
                width: "100%",
              }}
              error={formErrors.country}
              name="country"
              className="input_username new-edit-main-all"
              id="outlined-basic"
              label="Country (required)"
              placeholder="Enter your country"
              onChange={handleChange}
              helperText={formErrors.country}
            /> */}
                  <FormHelperText sx={{ color: "#fff", marginBottom: "5px" }}>
                    Country <span className="text-danger">*</span>
                  </FormHelperText>
                  <FormControl fullWidth>
                    <Select
                      className="input_username new-edit-main-all"
                      id="demo-simple-select-helper-label"
                      name="country"
                      defaultValue={userInfo.country_id}
                      onChange={handleChange}
                      MenuProps={MenuProps}
                      error={formErrors.country}
                      helperText={formErrors.country}
                    >
                      {country_list &&
                        country_list.length > 0 &&
                        country_list.map((value, index) => {
                          return (
                            <MenuItem key={index} value={value.id}>
                              {value.label}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-2">
                  {/* <TextField
              sx={{
                width: "100%",
              }}
              error={formErrors.mystate}
              name="mystate"
              className="input_username new-edit-main-all"
              id="outlined-basic"
              label="State (required)"
              placeholder="Enter your state"
              onChange={handleChange}
              helperText={formErrors.mystate}
            /> */}
                  <FormHelperText sx={{ color: "#fff", marginBottom: "5px" }}>
                    State <span className="text-danger">*</span>
                  </FormHelperText>
                  <FormControl fullWidth>
                    <Select
                      sx={{
                        color: "#fff",
                      }}
                      error={formErrors.state}
                      helperText={formErrors.state}
                      className="input_username new-edit-main-all"
                      id="demo-simple-select-helper-label"
                      name="state"
                      defaultValue={userInfo.state_id}
                      onChange={handleChange}
                      MenuProps={MenuProps}
                    >
                      {stateList && stateList.length > 0 ? (
                        stateList.map((value, index) => {
                          return (
                            <MenuItem key={index} value={value.id}>
                              {value.label}
                            </MenuItem>
                          );
                        })
                      ) : (
                        <MenuItem value="">Select State</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-2">
                  <FormHelperText sx={{ color: "#fff", marginBottom: "5px" }}>
                    City <span className="text-danger">*</span>
                  </FormHelperText>
                  <FormControl fullWidth>
                    <Select
                      sx={{
                        color: "#fff",
                      }}
                      error={formErrors.city}
                      helperText={formErrors.city}
                      className="input_username new-edit-main-all"
                      id="demo-simple-select-helper-label"
                      defaultValue={userInfo.city_id}
                      name="city"
                      onChange={handleChange}
                      MenuProps={MenuProps}
                    >
                      {cityList && cityList.length > 0 ? (
                        cityList.map((value, index) => {
                          return (
                            <MenuItem key={index} value={value.id}>
                              {value.label}
                            </MenuItem>
                          );
                        })
                      ) : (
                        <MenuItem value="">Select City</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-2">
                  <FormHelperText sx={{ color: "#fff", marginBottom: "5px" }}>
                    Street Address
                  </FormHelperText>
                  <TextField
                    sx={{
                      width: "100%",
                    }}
                    error={formErrors.street}
                    name="street"
                    value={formData.street}
                    className="input_username new-edit-main-all"
                    id="outlined-basic"
                    placeholder="Enter your street address"
                    onChange={handleChange}
                    helperText={formErrors.street}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-3">
                  <FormHelperText sx={{ color: "#fff", marginBottom: "5px" }}>
                    Short info
                  </FormHelperText>
                  <TextField
                    sx={{
                      width: "100%",
                    }}
                    error={formErrors.short_info}
                    name="short_info"
                    className="input_username new-edit-main-all"
                    id="outlined-basic"
                    placeholder="Info"
                    onChange={handleChange}
                    multiline
                    rows={4}
                    helperText={formErrors.short_info}
                  />
                  {/* <div className="account_txteditor">
                    <div>
                      <div
                        style={{
                          display: "grid",
                          color: "#fff",
                          justifyContent: "center",
                        }}
                      >
                        <ReactQuill
                          theme="snow"
                          modules={modules}
                          formats={formats}
                          placeholder=" "
                          onFocus={(e) => {
                            setEditorType("short_info");
                          }}
                          value={formData.short_info}
                          onChange={handleChangeshort_info}
                          style={{ height: "80px", color: "#fff" }}
                        ></ReactQuill>
                      </div>
                    </div>
                  </div>
                  {formErrors.short_info && (
                    <div className="invalid-msg">{formErrors.short_info}</div>
                  )} */}
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-3">
                  <FormHelperText sx={{ color: "#fff", marginBottom: "5px" }}>
                    Biographical Info
                  </FormHelperText>
                  <TextField
                    sx={{
                      width: "100%",
                    }}
                    error={formErrors.bio_info}
                    name="bio_info"
                    className="input_username new-edit-main-all"
                    id="outlined-basic"
                    placeholder="Info"
                    onChange={handleChange}
                    multiline
                    rows={4}
                    helperText={formErrors.bio_info}
                  />
                  {/* <div className="account_txteditor">
                    <div>
                      <div
                        style={{
                          display: "grid",
                          color: "#fff",
                          justifyContent: "center",
                        }}
                      >
                        <ReactQuill
                          theme="snow"
                          modules={modules}
                          formats={formats}
                          placeholder=" "
                          onFocus={(e) => {
                            setEditorType("bio_info");
                          }}
                          value={formData.bio_info}
                          // onChange={handleProcedureContentChange}
                          onChange={handleChangebio_info}
                          style={{ height: "80px", color: "#fff" }}
                        ></ReactQuill>
                      </div>
                    </div>
                  </div>
                  {formErrors.bio_info && (
                    <div className="invalid-msg">{formErrors.bio_info}</div>
                  )} */}
                </div>
              </div>
            </div>
            <div className="my_accounttap_inptfields new-all-inputs mx-2 hidden">
              <h1>Add Wallet Address</h1>
              <div className="row">
                {address !== undefined ? (
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="row">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12  mb-2">
                        <FormHelperText
                          sx={{ color: "#fff", marginBottom: "5px" }}
                        >
                          Wallet Address <span className="text-danger">*</span>
                        </FormHelperText>
                      </div>
                      <div className="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-9  mb-2">
                        <TextField
                          sx={{
                            width: "100%",
                          }}
                          error={formErrors.tokenAddress}
                          name="tokenAddress"
                          className="input_username new-edit-main-all"
                          id="outlined-basic"
                          placeholder="Enter your token address"
                          onChange={handleChangeNew}
                          helperText={formErrors.tokenAddress}
                          value={form.tokenAddress}
                          disabled
                        />
                      </div>
                      <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3 mb-2">
                        <button
                          type="button"
                          className={`token_btn ${walletAddress !== "" || loading === true
                            ? "disabled"
                            : ""
                            }`}
                          disabled={
                            walletAddress !== "" || loading === true
                              ? true
                              : false
                          }
                          onClick={handleAddUpdateToken}
                        >
                          {walletAddress !== ""
                            ? "Created"
                            : loading
                              ? "Wait..."
                              : "Submit"}
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12  mb-2">
                    <button
                      type="button"
                      className="token_btn"
                      onClick={() => open()}
                    >
                      Connect Wallet
                    </button>
                  </div>
                )}
              </div>
            </div>
            <MyAccountTapModal show={show} close={handleClose} />
          </div>
        </>
      )}
    </motion.div>
  );
};

export default MyAccountTap;
