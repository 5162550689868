import React from "react";

import Loader from "../../hooks/Loader";
import { NavLink } from "react-router-dom";

const ChallengesDay = (props) => {
  const { challenges } = props;

  return (
    <>
      <div className="challengesday_section">
        {challenges != undefined && (
          <>
            {props.isload == true ? (
              <Loader />
            ) : (
              <>
                <div className="challenges_heading">
                  <h2>Challenges</h2>
                </div>
                <hr />
                <div className="challenges_scroll">
                  {challenges.length > 0 ? (
                    <div className="row">
                      {" "}
                      {challenges.map(function (value, index) {
                        return (
                          <div className="col-md-6" key={index}>
                            <div className="challenges_main_box">
                              <div className="challenge_day">
                                <h5>Day {index + 1}</h5>
                              </div>
                              <div className="challenges_main">
                                <div className="challenges_box">
                                  <h5>{value.description}</h5>
                                  <p>{value.point} Point</p>
                                </div>
                                <div className="challenges_img">
                                  <img
                                    src={"images/challenge_img1.png"}
                                    alt=""
                                  />
                                  <div className="go_now_button mt-3">
                                    <NavLink>Let’s Grow</NavLink>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}{" "}
                    </div>
                  ) : (
                    <h2 className="text-center"> No Challneges Available </h2>
                  )}
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};
export default ChallengesDay;
