import React, { useState } from "react";
import { FooterBig, FooterMobile, Header, RecordNotfound } from "../components";

import { errorMsg, images } from "../actions/customFn";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import { useEffect } from "react";
import {
  getAddToCartList,
  getUpdateCart,
  removeFromCartList,
} from "../actions/cartAction";
import Loader from "../hooks/Loader";
import { reactIcons } from "../utils/icons";
import { freshlyData, sponseredData } from "../utils/data";
import OwlCarousel from "react-owl-carousel";
import nextId from "react-id-generator";
import { isVideo } from "../utils/formatter";
import { asyncAllProductList } from "../actions/productAction";
const AddToCart = () => {
  const navigate = useNavigate();

  const directpage = (path) => {
    navigate(`/${path}`);
  };
  const [reloadCart, setReloadCart] = useState(false);
  // Api Code And Function
  const [CartList, setCartList] = useState([]);
  const [CartSubTotals, setCartSubTotals] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { state } = useLocation();
  const [isUpdate, setIsUpdate] = useState(0);
  const [inStock, setInStock] = useState();
  const [productList, setProductList] = useState([]);
  const [productTotal, setProductTotal] = useState(0);

  useEffect(() => {
    asyncAllProductList(
      setProductList,
      setIsLoader,
      0,
      0,
      setIsLoader,
      false,
      "",
      "",
      setProductTotal
    );
  }, []);

  useEffect(() => {
    setIsLoader(true);
    getAddToCartList(setCartList, setCartSubTotals, setIsLoader, setInStock);
  }, [isUpdate, reloadCart]);

  const removeElement = (cartid, product_id, index) => {
    // const newCart = CartList.filter((_, i) => i !== index);
    // setCartList(newCart);
    removeFromCartList(cartid, product_id, setReloadCart, setIsUpdate).then(
      (res) => {
        setReloadCart(!reloadCart);
      }
    );
  };

  const handleIncreament = (index) => {
    if (CartList[index] !== undefined) {
      let newState = { ...CartList[index] };
      if (newState["quantity"] !== undefined) {
        newState["quantity"] = CartList[index].quantity + 1;
      }
      let newState2 = [...CartList];
      newState2[index] = newState;
      setCartList(newState2);
      handleUpdateCart(newState2);
    }
  };

  const handledecrement = (index) => {
    if (CartList[index] !== undefined) {
      let newState = { ...CartList[index] };
      if (newState["quantity"] !== undefined && newState["quantity"] > 1) {
        newState["quantity"] = CartList[index].quantity - 1;
      }
      let newState2 = [...CartList];
      newState2[index] = newState;
      setCartList(newState2);
      handleUpdateCart(newState2);
    }
  };

  // Update Cart Api
  // const handleUpdateCart = (CartList) => {
  //   setIsLoading(true);
  //   try {
  //     if (CartList.length > 0) {
  //       getUpdateCart(CartList, setIsUpdate);
  //       setIsLoading(false);
  //     } else {
  //       errorMsg("Your Cart is Empty!");
  //       setIsLoading(false);
  //     }
  //   } catch (error) {
  //     errorMsg("something went wrong!");
  //     setIsLoading(false);
  //   }
  // };
  const handleUpdateCart = async (CartList) => {
    setIsLoading(true);
    try {
      if (CartList.length > 0) {
        await getUpdateCart(CartList, setIsUpdate); // Wait for the API call to complete
        setIsLoading(false);
      } else {
        errorMsg("Your Cart is Empty!");
        setIsLoading(false);
      }
    } catch (error) {
      errorMsg("something went wrong!");
      setIsLoading(false);
    }
  };
  console.log(isLoading, "isLoading");
  const experience = {
    loop: true,
    margin: 0,
    nav: true,
    dots: false,
    autoplay: false,
    autoplaySpeed: 3000,
    // navText: ["<div dangerouslysetinnerhtml=" + __html + " ></div>"],
    responsive: {
      0: {
        items: 1,
      },

      400: {
        items: 2,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 4,
      },
      1200: {
        items: 5,
      },
    },
  };
  console.log(CartList, "CartList");
  return (
    <motion.div
      initial={{ opacity: 0.2 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 1 }}
      transition={{ duration: 2 }}
    >
      <div className="all_page_bg add-to-cart-page">
        <div className="container-fluid">
          <Header />

          {isLoader || isLoading ? (
            <Loader />
          ) : (
            <div className="page-all-data">
              <div className="add-cart-main">
                <div className="list_Breadcrumb">
                  <ul>
                    <li>
                      <NavLink className="top_nav_link" to="/">
                        {" "}
                        Home <span>/</span>
                      </NavLink>
                    </li>
                    <li>
                      {" "}
                      <NavLink className="top_nav_link" to="/product-listing">
                        Shop <span>/</span>
                      </NavLink>
                    </li>
                    {/* <li>
                    {" "}
                    <NavLink className="top_nav_link" to="/product-details">
                      Product Details <span>/</span>
                    </NavLink>
                  </li> */}
                    <li>
                      {" "}
                      <NavLink to="/add-to-cart">
                        Add to Cart <span>/</span>
                      </NavLink>
                    </li>
                  </ul>
                </div>
                <div className="row">
                  <div className="col-md-9">
                    {CartList.map((value, index) => {
                      return (
                        <div className="main-card" key={index}>
                          <div className="mc-left">
                            <div>
                              <img src={value.image} alt="tour" />
                            </div>
                            <div>
                              <h2>{value.name}</h2>
                              <span>{value.category}</span>
                            </div>
                          </div>
                          <div className="mc-right">
                            <div className="increment_dec_box">
                              {value.is_stock == true ? (
                                <span>
                                  <button
                                    className="btn_descrement"
                                    onClick={() => {
                                      handledecrement(index);
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="15"
                                      height="3"
                                      viewBox="0 0 15 3"
                                      fill="none"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M15 3H0V0H15V3Z"
                                        fill="#454AA6"
                                      />
                                    </svg>
                                  </button>
                                  <span className="count_btn">
                                    {value.quantity}
                                  </span>
                                  <button
                                    onClick={() => {
                                      handleIncreament(index);
                                    }}
                                    className="btn_increment"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="15"
                                      height="15"
                                      viewBox="0 0 15 15"
                                      fill="none"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M15 9H0V6H15V9Z"
                                        fill="#454AA6"
                                      />
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M6 15L6 1.31134e-07L9 0L9 15L6 15Z"
                                        fill="#454AA6"
                                      />
                                    </svg>
                                  </button>
                                </span>
                              ) : (
                                <p className="not_avai">
                                  {value.is_stock_messsage}
                                </p>
                              )}
                            </div>
                            <p>$ {value.price}</p>
                          </div>
                          <button
                            className="delete-icon"
                            onClick={() =>
                              removeElement(value.id, value.product_id, index)
                            }
                          >
                            {reactIcons.deleteNew}
                          </button>
                        </div>
                      );
                    })}
                  </div>
                  <div className="col-md-3">
                    <div className="price-card">
                      {CartList.length > 0 && (
                        <div>
                          {CartSubTotals !== undefined &&
                            CartSubTotals.length > 0 &&
                            CartSubTotals.map((value, index) => {
                              console.log(value, "value");
                              return (
                                <>
                                  <p
                                    className={`subtotal ${
                                      value.code === "Total" ? "last-col" : ""
                                    }`}
                                  >
                                    {value.code} <span>$ {value.value}</span>
                                  </p>
                                </>
                              );
                            })}
                        </div>
                      )}
                      {CartList !== undefined && CartList.length !== 0 ? (
                        <div className="update_button">
                          {/* <button onClick={() => handleUpdateCart()}>
                            Update Cart
                          </button> */}
                          <button>Continue shopping</button>
                          <button
                            onClick={() => {
                              inStock === true
                                ? directpage("check-out")
                                : errorMsg("Quantity Not Available");
                            }}
                            className="checkout_btn"
                          >
                            Go to Checkout
                          </button>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  {productList && productList.length > 0 && (
                    <div className="col-md-12 related-sec">
                      {/* ********* Sponser data Start  ******** */}
                      <div className="freshly_minted_section py-15">
                        <div className="many-courses">
                          <div className="many-courses_heading">
                            <h5>{"Related sponsored items"} </h5>
                            <button onClick={() => navigate("/nft")}>
                              View All
                            </button>
                          </div>

                          {productList && productList.length > 0 && (
                            <OwlCarousel
                              // key={`carousel_tranding_courses_${Date.now()}`}
                              key={nextId()}
                              {...experience}
                              className="owl-theme"
                              nav
                              loop
                              margin={10}
                            >
                              {productList?.map((value, index) => {
                                return (
                                  <NavLink
                                    to={`/product-details/${value.id}`}
                                    key={index}
                                    className="course-card"
                                  >
                                    <div className="cc-img-sec">
                                      <div className="overlay-bg"></div>
                                      {value.product_image != undefined &&
                                      value.product_image.length > 0 ? (
                                        <img
                                          src={
                                            value.product_image[0].image != ""
                                              ? value.product_image[0].image
                                              : "/images/product_listing3.png"
                                          }
                                          alt=""
                                        />
                                      ) : (
                                        <img
                                          src={"/images/product_listing3.png"}
                                          alt=""
                                        />
                                      )}
                                    </div>
                                    <div className="cc-text-sec">
                                      <div className="mb-2">
                                        <div className="d-flex align-items-center mb-2">
                                          <h6 className="duration-text mb-0">
                                            {value.category}
                                          </h6>
                                        </div>
                                        <h5 className="title">{value.name}</h5>
                                      </div>
                                      <div className="d-flex align-items-center gap-1 justify-content-between w-100 cc-profile">
                                        <div className="d-flex w-50">
                                          <p className="text-muted">
                                            Price: <span>${value.price}</span>
                                          </p>
                                        </div>
                                        {/* <div className="d-flex align-items-center justify-content-end gap-1 flex-fill w-50 text-right">
                                          <p className="text-muted">
                                            Max: <span>$5481</span>
                                          </p>
                                        </div> */}
                                      </div>
                                    </div>
                                  </NavLink>
                                );
                              })}
                            </OwlCarousel>
                          )}
                        </div>
                      </div>
                      {/* ********* Sponser data End  ******** */}
                    </div>
                  )}
                </div>
                <div className="table_add_to_cart d-none">
                  <div className="table_add table-responsive table_add_to_cart-scroll ">
                    <table className="table  table-borderless">
                      <thead>
                        <tr className="add_cart_row">
                          <th>Product</th>
                          <th className="price_add_cart">Price</th>
                          <th className="qty_add_cart">Qty</th>
                          <th className="total_add_cart">Total</th>
                          <th></th>
                        </tr>
                      </thead>

                      {CartList !== undefined && CartList.length === 0 ? (
                        <tr>
                          <td colspan={5}>
                            <div className="text-light">
                              <div
                                className="text-center mt-4 col-12"
                                style={{ color: "#fff" }}
                              >
                                <h2> Cart is Empty </h2>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ) : CartList.length > 0 ? (
                        CartList.map((value, index) => {
                          return (
                            <tr className="add_cart_boxs_main" key={index}>
                              <td className="add_cart_img">
                                <img src={value.image} alt="" />
                                <span className="add_cart_span">
                                  <p>{value.name}</p>
                                </span>
                              </td>
                              <td className="addtocart_totals">
                                <p>
                                  <span> $ </span>
                                  {value.price}
                                </p>
                              </td>
                              <td className="increment_dec_box">
                                {value.is_stock == true ? (
                                  <span>
                                    <button
                                      className="btn_descrement"
                                      onClick={() => {
                                        handledecrement(index);
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="15"
                                        height="3"
                                        viewBox="0 0 15 3"
                                        fill="none"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M15 3H0V0H15V3Z"
                                          fill="#454AA6"
                                        />
                                      </svg>
                                    </button>
                                    <span className="count_btn">
                                      {value.quantity}
                                    </span>
                                    <button
                                      onClick={() => {
                                        handleIncreament(index);
                                      }}
                                      className="btn_increment"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="15"
                                        height="15"
                                        viewBox="0 0 15 15"
                                        fill="none"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M15 9H0V6H15V9Z"
                                          fill="#454AA6"
                                        />
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M6 15L6 1.31134e-07L9 0L9 15L6 15Z"
                                          fill="#454AA6"
                                        />
                                      </svg>
                                    </button>
                                  </span>
                                ) : (
                                  <p className="not_avai">
                                    {value.is_stock_messsage}
                                  </p>
                                )}
                              </td>
                              <td className="addtocart_totals">
                                <p>
                                  <span> $ </span>
                                  {value.total}
                                </p>
                              </td>
                              <td>
                                <button
                                  onClick={() =>
                                    removeElement(
                                      value.id,
                                      value.product_id,
                                      index
                                    )
                                  }
                                  className="cross_button"
                                >
                                  <img
                                    src={"/images/add_cart_close.png"}
                                    alt=""
                                  />
                                </button>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colspan={5}>
                            <div className="text-light">
                              <RecordNotfound />
                            </div>
                          </td>
                        </tr>
                      )}
                      <tfoot>
                        <tr className="table_footer">
                          {CartList.length > 0 && (
                            <td colspan="5" className="footer_cart">
                              {CartSubTotals !== undefined &&
                                CartSubTotals.length > 0 &&
                                CartSubTotals.map((value, index) => {
                                  return (
                                    <>
                                      <p className="subtotal">
                                        {value.code} <span>$ </span>{" "}
                                        {value.value}
                                      </p>
                                    </>
                                  );
                                })}
                            </td>
                          )}
                        </tr>
                      </tfoot>
                    </table>
                  </div>

                  {/* {CartList.length > 0 && ( */}
                  {/* {console.log("CartList=",CartList)} */}
                  {CartList !== undefined && CartList.length !== 0 ? (
                    <div className="update_button">
                      {/* <button onClick={() => handleUpdateCart()}>Update</button> */}
                      <button
                        onClick={() => {
                          inStock === true
                            ? directpage("check-out")
                            : errorMsg("Quantity Not Available");
                        }}
                        className="checkout_btn"
                      >
                        Checkout
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                  {/* )} */}
                </div>
              </div>

              <FooterBig />
              <div className="mobile-footer-new">
                <FooterMobile />
              </div>
            </div>
          )}
        </div>
      </div>
    </motion.div>
  );
};
export default AddToCart;
