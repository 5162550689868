import React, { useState, useEffect, useCallback } from "react";
import { FooterBig, FooterMobile, Header } from "../components";

import { errorMsg, images } from "../actions/customFn";

import { NavLink } from "react-router-dom";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import NftFilter from "../components/Nft/NftFilter";
import NftFilterMobile from "../components/Nft/NftFilterMobile";
import { motion } from "framer-motion";
import NftProduct from "../components/Nft/NftProduct";
import { asyncAllProductList } from "../actions/productAction";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {
  TextField,
  IconButton,
  Autocomplete,
  FormControl,
  InputLabel,
} from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import { reactIcons } from "../utils/icons";
import Loader from "../hooks/Loader";
import NftProductNew from "../components/Nft/NftProductNew";
import { getAuthData } from "../utils/apiHandlers";
import { useAccount } from "wagmi";

const Nft = () => {
  const [isChecked, setIsChecked] = useState(false);
  const { address } = useAccount();
  const [isDataLoad, setIsDataLoading] = useState(true);
  const [productList, setProductList] = useState([]);
  const [nftList, setNftList] = useState(null);
  const [productTotal, setProductTotal] = useState(0);
  const [isLoader, setIsLoader] = useState(false);
  const [page_count, setPagecount] = useState(0);
  const [selectedTab, setSelectedTab] = useState("products");
  const [currentPage, setCurrentPage] = useState(0);
  const [skip, setSkip] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [walletAddress, setWalletAddress] = useState("");
  const take = 400;
  const [nftLength, setNftLength] = useState("");
  const [nftHasMore, setNftHasMore] = useState(true);

  const [searchNft, setSearchNft] = useState("");
  const [searchdata, setSearchdata] = useState({
    search_name: "",
    sort_price: "",
  });
  const [searchProduct, setSearchProduct] = useState({});

  const handleCheckboxChange = () => {
    setIsLoader(true);
    if (!address) {
      setIsLoader(false);
      errorMsg("Please connect your wallet to check your NFT");
      return;
    }
    setIsChecked(!isChecked);
    setIsLoader(false);
  };
  useEffect(() => {
    if (isChecked) {
      setWalletAddress(address);
    } else {
      setWalletAddress("");
    }
  }, [isChecked, address]);

  useEffect(() => {
    setTimeout(function() {
      setIsDataLoading(false);
    }, 3000);
  }, [isDataLoad]);

  useEffect(() => {
    if (currentPage === 0) {
      setIsLoader(true);
    }
    asyncAllProductList(
      setProductList,
      setIsDataLoading,
      setPagecount,
      currentPage,
      setIsLoader,
      setHasMore,
      "",
      searchProduct,
      setProductTotal
    );
  }, [currentPage, searchProduct]);
  const handleChange = (e) => {
    if (e.target !== undefined) {
      let { name, value } = e.target;
      setSearchdata((searchdata) => ({ ...searchdata, [name]: value }));
    }
  };
  const refresh = () => {
    setCurrentPage(0);
  };

  const handleLoadMore = () => {
    setTimeout(() => {
      setCurrentPage(currentPage + 1);
    }, 1000);
  };

  const handleFind = () => {
    if (searchdata.search_name !== "" || searchdata.sort_price !== "") {
      setSearchProduct(searchdata);
      setCurrentPage(0);
    }
  };

  const handleClear = () => {
    // if (searchdata.search_name != "" || searchdata.sort_price != "") {
    setSearchdata({ search_name: "", sort_price: "" });
    getNft();
    setSearchProduct({});
    setCurrentPage(0);
    // }
  };
  const handleTab = (tab) => {
    setIsDataLoading(true);
    setSelectedTab(tab);
  };
  const getNft = useCallback(async () => {
    try {
      const response = await getAuthData(
        `/tokens?skip=${skip}&take=${take}${
          walletAddress !== "" ? `&wallet=${walletAddress}` : ""
        }${searchNft !== "" ? `&search=${searchNft}` : ""}`
      );
      if (response?.status === 201 || response?.status === 200) {
        const newNfts = response.data.data;
        if (skip === 0) {
          setNftList(newNfts);
        } else {
          setNftList((prevNftList) => [...prevNftList, ...newNfts]);
        }
        setNftLength(response.data.count);
        setNftHasMore(response.data.count !== response.data.data.length);
      } else {
        setNftHasMore(false);
      }
    } catch (e) {
      console.error(e);
      setNftHasMore(false);
      return null;
    }
  }, [skip, take, walletAddress, searchNft]);

  useEffect(() => {
    getNft();
  }, [getNft]);

  const handleLoadNft = () => {
    setTimeout(() => {
      setSkip(skip + 1);
    }, 1500);
  };

  const nftRefresh = () => {
    setSkip(0);
    getNft();
  };
  console.log(skip, "skip");
  console.log(nftList, "nftList");
  console.log(nftLength, "nftLength");
  console.log(nftHasMore, "nftHasMore");
  return (
    <motion.div
      initial={{ opacity: 0.2 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 1 }}
      transition={{ duration: 2 }}
    >
      <div className="all_page_bg nft-main new-inner-pages nft-page">
        <div className="container-fluid">
          <Header />
          <div className="page-all-data list_margins">
            <div>
              <div className="paga-data-dis">
                {/* <FilterAll /> */}
                <NftFilter />
                <div className="page_data_mid_new">
                  <div className="page_data_mid_inner_input make_smallsearch">
                    {selectedTab === "products" ? (
                      <div className="nft_searc_left">
                        <div className="input_search_new  new-all-inputs searchby_namecourse">
                          <TextField
                            sx={{
                              width: "100%",
                            }}
                            name="search_name"
                            className="input_username new-edit-main-all"
                            id="outlined-basic"
                            value={searchdata.search_name}
                            placeholder="product name"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            val
                          />
                          <button onClick={() => handleFind()}>
                            {reactIcons.search}
                          </button>
                        </div>
                        <div className="prd_Sort_by">
                          <span className="new_sort_title">Sort by </span>
                          <div className="sort_select  new-all-inputs">
                            <FormControl fullWidth>
                              <Select
                                value={searchdata.sort_price}
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                name="sort_price"
                                inputProps={{ "aria-label": "Without label" }}
                                labelId="demo-simple-select-label"
                                className="new_sort_list new-edit-main-all"
                                id="demo-simple-select"
                                defaultValue="Low to high"
                              >
                                <MenuItem value="name_az">Low to high</MenuItem>
                                <MenuItem value="name_za">High to low</MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="nft_searc_left">
                        <div className="input_search_new  new-all-inputs searchby_namecourse">
                          <TextField
                            sx={{
                              width: "100%",
                            }}
                            name="search_name"
                            className="input_username new-edit-main-all"
                            id="outlined-basic"
                            value={searchNft}
                            placeholder="Nft name"
                            onChange={(e) => setSearchNft(e.target.value)}
                          />
                          <button onClick={() => handleFind()}>
                            {reactIcons.search}
                          </button>
                        </div>
                      </div>
                    )}

                    <div className="nft_searc_right">
                      <button
                        className="btn_findcoursenow mx-1"
                        onClick={() => handleFind()}
                      >
                        Find
                      </button>

                      <div className="clearbtn_searchdiv">
                        <button
                          type="button"
                          class="upload_btn"
                          onClick={() => {
                            handleClear();
                          }}
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="nft_page_card_section">
                    <Tabs>
                      <div className="nft_page_card_tab">
                        <div className="nft_page_left">
                          <TabList>
                            <Tab
                              defaultactivekey="Items"
                              transition="true"
                              id="noanim-tab-example"
                              className="tab_new_inner"
                              onClick={() => handleTab("products")}
                            >
                              {" "}
                              <NavLink className="nft_card_nft_nav">
                                Products
                              </NavLink>{" "}
                            </Tab>
                            <Tab
                              defaultactivekey="nft"
                              transition="true"
                              id="noanim-tab-example"
                              className="tab_new_inner"
                              onClick={() => handleTab("nft")}
                            >
                              {" "}
                              <NavLink className="nft_card_nft_nav">
                                NFT
                              </NavLink>{" "}
                            </Tab>
                          </TabList>
                        </div>
                        <div className="nft_page_right">
                          {/* {selectedTab !== 'products' && <h2>My NFT</h2>} */}
                          {selectedTab !== "products" && (
                            <div className="all-input-filter nft-checkbox">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="checkterm"
                                id={"myNft"}
                                checked={isChecked}
                                onChange={handleCheckboxChange}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={"myNft"}
                              ></label>
                              <p>My NFT</p>
                            </div>
                          )}
                          <div className="nft_page_right_update">
                            <button
                              onClick={() => {
                                handleClear();
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="28"
                                height="28"
                                viewBox="0 0 28 28"
                                fill="none"
                              >
                                <path
                                  d="M14 21.2188C10.0299 21.2188 6.78125 17.97 6.78125 14C6.78125 10.0296 10.0299 6.78125 14 6.78125C15.9852 6.78125 17.7898 7.62344 19.0535 8.94693L15.2031 12.7969H23.625V4.375L20.7978 7.20245C19.0535 5.4577 16.6472 4.375 14 4.375C8.70641 4.375 4.375 8.70641 4.375 14C4.375 19.2936 8.64577 23.625 14 23.625C18.4321 23.625 22.1094 20.6645 23.2641 16.625H20.7184C19.6932 19.3354 17.0468 21.2188 14 21.2188Z"
                                  fill="#FF0098
"
                                />
                                <rect
                                  x="0.5"
                                  y="0.5"
                                  width="27"
                                  height="27"
                                  rx="5.5"
                                  stroke="#FF0098
"
                                />
                              </svg>
                            </button>

                            <h2>Updated 26s ago</h2>
                          </div>

                          <div className="nft_page_right_update_right">
                            <h3>
                              {selectedTab === "products"
                                ? productTotal
                                : nftLength}{" "}
                              Items
                            </h3>
                          </div>
                        </div>
                      </div>

                      <TabPanel eventkey="castle">
                        <NftProduct
                          isLoader={isLoader}
                          productList={productList}
                          handleLoadMore={handleLoadMore}
                          hasMore={hasMore}
                          refresh={refresh}
                        />
                      </TabPanel>
                      <TabPanel eventkey="nft">
                        <NftProductNew
                          isLoader={isLoader}
                          product_list={nftList}
                          handleLoadMore={handleLoadNft}
                          hasMore={nftHasMore}
                          refresh={nftRefresh}
                          dataLength={nftLength}
                        />
                      </TabPanel>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
            <div className="filter_mobile">
              <NftFilterMobile />
            </div>
            <div className="mobile-footer-new">
              <FooterMobile />
            </div>
          </div>

          <FooterBig />
        </div>
      </div>
    </motion.div>
  );
};

export default Nft;
