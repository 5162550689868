import React, { useState } from "react";

import "flatpickr/dist/flatpickr.css";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import {
  asyncDeleteMyProduct,
  asyncgetMyProduct,
} from "../../actions/productAction";

import Swal from "sweetalert2";
import ReactPaginate from "react-paginate";
// import { motion } from "framer-motion";
import Loader from "../../hooks/Loader";
import RecordNotfound from "../Home/RecordNotfound";
import {
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  TextField,
  FormHelperText,
} from "@mui/material";

const MyProduct = (props) => {
  const {
    myProductList,
    isLoader,
    setIsLoader,
    page_count,
    setCurrentPage,
    currentPage,
    reRender,
    setTogglePage,
    togglepage,
    productcategory,
    setSearchProduct,
  } = props;

  // ---------------
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const deleteProduct = (prod_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do You want to Delete Product !",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#7247e4",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((res) => {
      if (res.isConfirmed === true) {
        asyncDeleteMyProduct(prod_id, reRender);
        setIsLoader(true);
        setTogglePage(!togglepage);
        setTimeout(() => {
          setIsLoader(false);
        }, 1000);
      }
      if (res.isDismissed === true) {
      }
    });
  };

  const navigate = useNavigate();
  const directpage = (path) => {
    navigate(`/${path}`);
  };

  const paginate = ({ selected }) => {
    setCurrentPage(selected);
  };

  const [searchForm, setSearchForm] = useState({
    productname: "",
    product_category: "",
  });

  const handleChange = (e) => {
    if (e.target != undefined) {
      let { name, value } = e.target;
      setSearchForm((searchForm) => ({ ...searchForm, [name]: value }));
    }
  };

  const findProduct = () => {
    if (searchForm.productname != "" || searchForm.product_category != "") {
      setSearchProduct(searchForm);
    }
  };

  const clearSearch = () => {
    if (searchForm.productname != "" || searchForm.product_category != "") {
      setCurrentPage(0);
      setSearchForm({
        productname: "",
        product_category: "",
      });
      setSearchProduct({ productname: "", product_category: "" });
    }
  };
  // console.log("valueData=", searchForm);

  // console.log("page_count==", page_count);

  return (
    <div className="my-product-main">
      {/* <h1>Product</h1> */}

      <div>
        <div className="product-input">
          <div className="product-input-left new-all-inputs">
            <div>
              <TextField
                sx={{
                  width: "100%",
                }}
                name="productname"
                className="input_username new-edit-main-all"
                id="outlined-basic"
                value={searchForm.productname}
                placeholder=" product name"
                onChange={(e) => {
                  handleChange(e);
                }}
              />
            </div>
            <div>
              <Select
                className="input_username new-edit-main-all"
                id="demo-simple-select-helper-label"
                name="product_category"
                value={searchForm.product_category}
                onChange={(e) => {
                  handleChange(e);
                }}
                MenuProps={MenuProps}
                displayEmpty
              >
                <MenuItem value="">Category</MenuItem>
                {productcategory &&
                  productcategory.length > 0 &&
                  productcategory.map((value, index) => {
                    return (
                      <MenuItem key={index} value={value.id}>
                        {value.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </div>
          </div>
          <div className="btn-my-product">
            <div className="clearfind_btn">
              <button
                className="btn-find-now mr-2"
                onClick={() => findProduct()}
              >
                Find
              </button>
              {/* <button className="btn-find-now" onClick={() => clearSearch()}>
                Clear
              </button> */}
            </div>

            <button
              onClick={() => directpage("add-product-form")}
              className="product-add-product-btn"
            >
              Add Product
            </button>
          </div>
        </div>

        <div className="product-table table-responsive">
          <table class="table ">
            <tr className="product-list-table ">
              <th className="product-list-border-left">Product Image</th>
              <th>Product Name</th>
              <th>Category</th>
              <th>Price</th>
              <th className="product-list-border-right">Action</th>
            </tr>
            {isLoader == true ? (
              <tr>
                <td colSpan={5}>
                  <Loader />
                </td>
              </tr>
            ) : (
              <>
                {myProductList != undefined && myProductList.length > 0 ? (
                  <>
                    {myProductList.map((value, index) => {
                      return (
                        <tr key={index}>
                          <td className="product-list-table-img">
                            {value.product_image[0] != undefined &&
                              value.product_image.length > 0 ? (
                              <NavLink to={`/product-details/${value.id}`}>
                                <img
                                  src={value.product_image[0].image}
                                  alt=""
                                />
                              </NavLink>
                            ) : (
                              ""
                              // <img src={"/images/course-math3.png"} alt="" />
                            )}
                          </td>
                          <td>{value.name}</td>
                          <td>{value.category_name}</td>
                          <td>
                            <span className="dolar_color">$</span> {value.price}
                          </td>
                          <td className="product-list-btn-all">
                            <button
                              className="product-list-table-btn"
                              onClick={() =>
                                directpage(`add-product-form/${value.id}`)
                              }
                            >
                              <img src={"/images/My_Product_edit.png"} alt="" />
                            </button>
                            <button
                              className="product-list-table-btn"
                              onClick={() => deleteProduct(value.id)}
                            >
                              <img
                                src={"/images/My_Product1-delate.png"}
                                alt=""
                              />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </>
                ) : (
                  <tr>
                    <td colSpan={5}>
                      <RecordNotfound />
                    </td>
                  </tr>
                )}
              </>
            )}
          </table>
        </div>
      </div>

      {page_count > 1 && (
        <div className="paginate_all">
          <ReactPaginate
            pageCount={page_count}
            forcePage={currentPage}
            onPageChange={paginate}
            containerClassName={"navigationButtons"}
            previousLinkClassName={"previousButton"}
            nextLinkClassName={"nextButton"}
            disabledClassName={"navigationDisabled"}
            activeClassName={"active"}
            pageRange={5}
            marginPagesDisplayed={2}
            nextLabel={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="7.172"
                height="14"
                viewBox="0 0 7.172 14"
              >
                <path
                  id="fi-rr-angle-small-down"
                  d="M13.707,6.879a1,1,0,0,1-1.414,0L7.707,2.293a1.021,1.021,0,0,0-1.414,0L1.707,6.879A1,1,0,1,1,.293,5.465L4.878.879a3,3,0,0,1,4.243,0l4.586,4.586a1,1,0,0,1,0,1.414Z"
                  transform="translate(7.172) rotate(90)"
                  fill="#454AA6"
                />
              </svg>
            }
            previousLabel={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="7.172"
                height="14"
                viewBox="0 0 7.172 14"
              >
                <path
                  id="fi-rr-angle-small-down"
                  d="M18.707,8.207a1,1,0,0,0-1.414,0l-4.586,4.586a1.021,1.021,0,0,1-1.414,0L6.707,8.207A1,1,0,1,0,5.293,9.621l4.585,4.586a3,3,0,0,0,4.243,0l4.586-4.586a1,1,0,0,0,0-1.414Z"
                  transform="translate(15.086 -5) rotate(90)"
                  fill="#454AA6"
                />
              </svg>
            }
          />
        </div>
      )}
    </div>
  );
};

export default MyProduct;
