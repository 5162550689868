import {
  AccountActivation,
  Login,
  CreateCourse,
  Register,
  ResetPassword,
  Nft,
  Course,
  TimeLine,
  CoursesDetail,
  Donate,
  EarnRewards,
  Challenges,
  OrderHistory,
  BayPage,
  CampaignsDetail,
  PostList,
  ProductListing,
  ProductDetails,
  AddProductForm,
  AddToCart,
  Checkout,
  MyStore,
  VerifyOtp,
  ChapterDetails,
  ForgetPassword,
  LegalandCompliance,
  TheBayPage,
  AlbumDetails
} from "../pages";

import Chat from "../pages/__Chat.tsx";
import AllPosts from "../pages/AllPosts.js";
import BoughtCourse from "../pages/BoughtCourse.js";
import CategoriesDetails from "../pages/CategoriesDetails.js";
import ChatPage from "../pages/ChatPage";
import FriendUserProfile from "../pages/FriendUserProfile.js";
import NftProductDetails from "../pages/NftProductDetails.js";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import UploadMycourse from "../pages/UploadMycourse";
import WishList from "../pages/WishList";

// import Nft from "../pages/Nft";
const RouteArr = [
  {
    private: true,
    name: "BayPage",
    path: "/",
    key: "baypage",
    route: "/",
    page: <TheBayPage />,
  },
  {
    private: false,
    name: "Login",
    path: "/login",
    key: "login",
    route: "/login",

    page: <Login />,
  },
  {
    private: false,
    name: "Register",
    path: "/register",
    key: "register",
    route: "/register",
    page: <Register />,
  },
  {
    private: false,
    name: "ForgetPassword",
    path: "/forget-password",
    key: "reset-password",
    route: "/forget-password",
    page: <ForgetPassword />,
  },

  {
    private: false,
    name: "VerifyOtp",
    path: "/verifyotp",
    key: "verifyotp",
    route: "/verifyotp",
    page: <VerifyOtp />,
  },

  {
    private: false,
    name: "ResetPassword",
    path: "/reset-Password",
    key: "reset-Password",
    route: "/reset-Password",
    page: <ResetPassword />,
  },

  {
    private: false,
    name: "AccountActivation",
    path: "/account-activation",
    key: "account-activation",
    route: "/account-activation",
    page: <AccountActivation />,
  },

  {
    private: true,
    name: "CreateCourse",
    path: "/create-course",
    key: "create-course",
    route: "/create-course",
    page: <CreateCourse />,
  },
  {
    private: true,
    name: "Courses",
    path: "/courses",
    key: "courses",
    route: "/courses",
    page: <Course />,
  },

  {
    private: true,
    name: "Courses",
    path: "/courses",
    key: "courses",
    route: "/courses-category/:id",
    page: <Course />,
  },
  {
    private: true,
    name: "Nft",
    path: "/nft",
    key: "nft",
    route: "/nft",
    page: <Nft />,
  },

  {
    private: true,
    name: "Profile",
    path: "/profile/:option?",
    key: "profile",
    route: "/profile/:option?",
    page: <TimeLine />,
  },

  {
    private: true,
    name: "Donate",
    path: "/donate",
    key: "donate",
    route: "/donate",
    page: <Donate />,
  },
  {
    private: true,
    name: "CoursesDetail",
    path: "/courses-detail/:id",
    key: "courses-detail",
    route: "/courses-detail/:id",
    page: <CoursesDetail />,
  },
  {
    private: true,
    name: " Challenges",
    path: "/challenges",
    key: "challenges",
    route: "/challenges",
    page: <Challenges />,
  },
  {
    private: true,
    name: " EarnRewards",
    path: "/earn-rewards",
    key: " earn-rewards",
    route: "/earn-rewards",
    page: <EarnRewards />,
  },
  {
    private: true,
    name: "OrderHistory",
    path: "/orderhistory",
    key: "orderhistory",
    route: "/orderhistory",
    page: <OrderHistory />,
  },
  {
    private: true,
    name: "CampaignDetail",
    path: "/campaign-detail/:id",
    key: "campaign-detail",
    route: "/campaign-detail/:id",
    page: <CampaignsDetail />,
  },
  {
    private: true,
    name: "PostList",
    path: "/post-list",
    key: "post-list",
    route: "/post-list",
    page: <PostList />,
  },

  {
    private: true,
    name: "ProductListing",
    path: "/product-listing",
    key: "product-listing",
    route: "/product-listing",
    page: <ProductListing />,
  },

  {
    private: true,
    name: "ProductDetails",
    path: "/product-details/:id",
    key: "product-details",
    route: "/product-details/:id",
    page: <ProductDetails />,
  },
  {
    private: true,
    name: "NFT",
    path: "/nft-details/:id",
    key: "nft-product",
    route: "/nft-details/:id",
    page: <NftProductDetails />,
  },

  {
    private: true,
    name: "Checkout",
    path: "/check-out",
    key: "check-out",
    route: "/check-out",
    page: <Checkout />,
  },
  {
    private: true,
    name: "MyStore",
    path: "/mystore",
    key: "mystore",
    route: "/mystore",
    page: <MyStore />,
  },
  {
    private: true,
    name: "AddToCart",
    path: "/add-to-cart",
    key: "add-to-cart",
    route: "/add-to-cart",
    page: <AddToCart />,
  },
  {
    private: true,
    name: "AddProductForm",
    path: "/add-product-form",
    key: "add-product-form",
    route: "/add-product-form",
    page: <AddProductForm />,
  },
  // {
  //   private: true,
  //   name: "MyCoursedetail",
  //   path: "/mycouse-detail",
  //   key: "mycouse-detail",
  //   route: "/mycouse-detail",
  //   page: <MyCoursedetail />,
  // },
  {
    private: true,
    name: "AddProductForm",
    path: "/add-product-form/:id",
    key: "add-product-form",
    route: "/add-product-form/:id",
    page: <AddProductForm />,
  },
  {
    private: true,
    name: "UploadCourse",
    path: "/upload-course",
    key: "upload-course",
    route: "/upload-course",
    page: <UploadMycourse />,
  },
  {
    private: true,
    name: "UploadCourse",
    path: "/upload-course/:id",
    key: "upload-course",
    route: "/upload-course/:id",
    page: <UploadMycourse />,
  },

  {
    name: "ChapterDetails",
    path: "/chapter-details/:id",
    key: "chapter-details",
    route: "/chapter-details/:id",
    page: <ChapterDetails />,
  },
  {
    private: true,
    name: "Chat2",
    path: "/chat2",
    key: "chat2",
    route: "/chat2",
    page: <Chat />,
  },
  {
    private: true,
    name: "Chat",
    path: "/chat",
    key: "chat",
    route: "/chat",
    page: <ChatPage />,
  },
  {
    private: true,
    name: "privacy-policy",
    path: "/privacy-policy",
    key: "privacy-policy",
    route: "/privacy-policy",
    page: <PrivacyPolicy />,
  },
  {
    private: true,
    name: "Wishlist",
    path: "/wishlist",
    key: "wishlist",
    route: "/wishlist",
    page: <WishList />,
  },
  {
    private: true,
    name: "friend_user_profile",
    path: "/friend_user_profile/:id",
    key: "friend_user_profile",
    route: "/friend_user_profile/:id",
    page: <FriendUserProfile />,
  },
  {
    private: true,
    name: "buy_successfull",
    path: "/buy_successfull/:ids",
    key: "buy_successfull",
    route: "/buy_successfull/:ids",
    page: <BoughtCourse />,
  },
  {
    private: true,
    name: "legal-compliance",
    path: "/legal-compliance",
    key: "legal-compliance",
    route: "/legal-compliance",
    page: <LegalandCompliance />,
  },
  {
    private: false,
    name: "the-bay-page",
    path: "/TheBay-Page",
    key: "the-bay-page",
    route: "/the-bay-page",
    page: <TheBayPage />,
  },
  {
    private: true,
    name: "album-details",
    path: "/album-details/:id",
    key: "album-details",
    route: "/album-details/:id",
    page: <AlbumDetails />,
  },
  {
    private: true,
    name: "categories-details",
    path: "/categories/:genre",
    key: "album-details",
    route: "/categories/:genre",
    page: <CategoriesDetails />,
  },
  {
    private: true,
    name: "all-posts",
    path: "/all-posts/:type",
    key: "album-details",
    route: "/all-posts/:type",
    page: <AllPosts />,
  },
  
];

export default RouteArr;
