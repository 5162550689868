import React, { useEffect, useState } from "react";
import { images } from "../actions/customFn";
import { NavLink, useParams } from "react-router-dom";

import {
  AboutMe,
  Courses,
  FriendsList,
  HalfScroreBoard,
  Header,
  PhotoSection,
  CourseList,
  AboutUs,
  Friends,
  Notification,
  TimelinePost,
  Setting,
  AllPhotos,
  LiveVideo,
  Groups,
  FriendsTab,
  MyAccountTap,
  NewPhoto,
  FooterBig,
  FooterMobile,
  MyPost,
  TimelineAllpost,
  ConfirmPopup,
  PostMintPopup,
  Albums,
} from "../components";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { motion } from "framer-motion";
import QuestChest from "../components/timeline/QuestChest";
import { getAuthData } from "../utils/apiHandlers";
import { useAccount } from "wagmi";
import Royalty from "../components/timeline/Royalty";

const TimeLine = () => {
  const { address } = useAccount();
  const [value, setValue] = React.useState(0);
  const [myPostisRender, setMyPostIsRender] = useState(false);
  const { option } = useParams();
  const [confirmPop, setConfirmPop] = useState(false);
  const [mintPop, setMintPop] = useState(false);
  const [walletAddress, setWalletAddress] = useState(null);
  const postId = localStorage.getItem("post_id");
  const isMint = localStorage.getItem("isMint");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //All Posts
  const [allposts, setAllposts] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    if (option !== undefined) {
      if (option === "friends") {
        setValue(3);
      }
    }
  }, [option]);
  const getWalletDetails = async () => {
    try {
      const response = await getAuthData("/users/me/wallet");
      if (response?.status === 201 || response?.status === 200) {
        setWalletAddress(response.data);
      }
    } catch (e) {
      console.error(e);
      return null;
    }
  };
  useEffect(() => {
    getWalletDetails();
  }, []);
  useEffect(() => {
    if ((walletAddress !== null || address) && postId) {
      setConfirmPop(true);
    }
  }, [walletAddress, address, postId]);
  useEffect(() => {
    if (isMint) {
      setMintPop(true);
    }
  }, [isMint]);
  return (
    <>
      <motion.div
        initial={{ opacity: 0.2 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 1 }}
        transition={{ duration: 2 }}
      >
        <div className="all_page_bg">
          <div className="container-fluid">
            <Header />
            <div className="page-all-data timeline-main">
              <div>
                <div className="row">
                  <div className="col-md-3">
                    <HalfScroreBoard />
                    <AboutMe />
                    <Friends handleChange={handleChange} />
                    <NavLink to={"/chat"}>
                      <div className="friend_chat_button">
                        <button> Chat </button>
                      </div>
                    </NavLink>
                  </div>

                  <div className="col-md-6 px-3 px-lg-0">
                    <div className="mid-data">
                      <div className="timeline_tab">
                        <Box
                          sx={{
                            maxWidth: {},
                            // sm: 780
                            bgcolor: "background.paper",
                          }}
                        >
                          <Tabs
                            value={value}
                            onChange={handleChange}
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="scrollable auto tabs example"
                          >
                            <Tab label="Timeline" />
                            <Tab label="My Post" />
                            <Tab label="BIO" />
                            <Tab label="Friends" />
                            <Tab label="Photos" />
                            <Tab label="Account Details" />
                            <Tab label="Notification" />
                            <Tab label="Setting" />
                            <Tab label="Albums" />
                            <Tab label="Royalty" />
                          </Tabs>
                        </Box>
                      </div>
                      <div className="tab_showdata">
                        {/* <div className="tab_showscroll" id="scrollableDiv"> */}
                        {/* {value === 0 && <TimelinePost />} */}
                        {value === 0 && (
                          <TimelineAllpost
                            allposts={allposts}
                            setAllposts={setAllposts}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                          />
                        )}

                        {value === 1 && (
                          <MyPost
                            setIsRender={setMyPostIsRender}
                            isRender={myPostisRender}
                          />
                        )}
                        {value === 2 && <AboutUs />}
                        {value === 3 && <FriendsTab />}
                        {value === 4 && <AllPhotos />}
                        {value === 5 && <MyAccountTap />}
                        {value === 6 && <Notification />}
                        {value === 7 && <Setting />}
                        {value === 8 && <Albums showEdit={true} />}
                        {value === 9 && <Royalty />}
                        {/* </div> */}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <QuestChest />
                    <LiveVideo />
                    <Groups />
                  </div>
                </div>
              </div>
              <div className="mobile-footer-new">
                <FooterMobile />
              </div>
            </div>
            <FooterBig />
          </div>
        </div>
      </motion.div>
      <ConfirmPopup
        show={confirmPop}
        setShow={setConfirmPop}
        // close={handleClose}
      />
      <PostMintPopup
        show={mintPop}
        setShow={setMintPop}
        // close={handleClose}
      />
    </>
  );
};

export default TimeLine;
