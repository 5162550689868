import React from "react";
import { Triangle } from "react-loader-spinner";

function MainLoader() {
  return (
    // <div className="lodaer_triangle">
    <div className="main_loader">
      <Triangle
        height="100"
        width="100"
        color="#454aa6"
        ariaLabel="triangle-loading"
        wrapperClassName="loader_class"
        visible={true}
      />
    </div>
    // </div>
  );
}

export default MainLoader;
