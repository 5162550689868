export const network = process.env.REACT_APP_NETWORK;
export const paymentTokens =
  network === "mainnet"
    ? [
        { label: "Matic", value: "0x0000000000000000000000000000000000000000" },
        { label: "USDT", value: "0" },
      ]
    : [
        { label: "Matic", value: "0x0000000000000000000000000000000000000000" },
        { label: "USDT", value: "0x94A47ad349d610143989aB82529a47Ed48d27530" },
      ];

export const contracts =
  network === "mainnet"
    ? {
        Registry: "0xe8B0d92DC24633665C4b95c3343b649E0aa3FFeB",
        TokenTransferProxy: "0xE06389fD2d0097D614ab3E6C6FF0121F92D7ecA1",
        TestERC20: "0x94A47ad349d610143989aB82529a47Ed48d27530",
        Exchange: "0x2C1Ac3060039B8E19eFC5325F6E2da2f1aea31AC",
        AssetSharedContract: "0x16290F6d81050c9149c5d9D760CD1bA016bA64c5",
        AdminAddress: "0x1d65af2A5C7993EcC4303F28960697dc766C3b12",
      }
    : {
        Registry: "0xe8B0d92DC24633665C4b95c3343b649E0aa3FFeB",
        TokenTransferProxy: "0xE06389fD2d0097D614ab3E6C6FF0121F92D7ecA1",
        TestERC20: "0x94A47ad349d610143989aB82529a47Ed48d27530",
        Exchange: "0x2C1Ac3060039B8E19eFC5325F6E2da2f1aea31AC",
        AssetSharedContract: "0x16290F6d81050c9149c5d9D760CD1bA016bA64c5",
        AdminAddress: "0x1d65af2A5C7993EcC4303F28960697dc766C3b12",
      };

export const zeroAddress = "0x0000000000000000000000000000000000000000";
